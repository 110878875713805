import React from "react";
import { useState, useEffect } from "react";
import Alerts from "../alerts/alert";
import Alert from "@material-ui/lab/Alert";
import { AlertTitle } from "@mui/material";
import Footer from "../footer/footer";
import {
  validateEmailMain,
  validatePasswordMain,
} from "../validations/basicRegFormValidations";
import { Formik, Form, Field } from "formik";
import { Button } from "react-bootstrap";
import { Auth } from "@aws-amplify/auth";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

function ForgotPassword() {
  const [status, setStatus] = useState(0);
  const [alertType, setAlertType] = useState();
  const [message, setMessage] = useState();
  const [stage, setstage] = useState(1);
  const [email, setemail] = useState("");
  const [code, setcode] = useState();
  const [password, setpassword] = useState();
  const [showPass, setshowPass] = useState(false);
  const [showCPass, setshowCPass] = useState(false);
  const [btnLoad, setbtnLoad] = useState(false);

  const validateEmail = (value) => {
    let error = validateEmailMain(value, setemail);
    return error;
  };

  const validateCode = (value) => {
    if (!value) return "Please enter code";
    else if (value.length !== 6)
      return "code should be 6 characters";
    else setcode(value);
    return;
  };
  const validatePassword = (value) => {
    let error = validatePasswordMain(value, setpassword);
    return error;
  };
  const validateConfirmPassword = (value) => {
    if (!value) return "please enter value";
    else if (value !== password) return "passwords don't match";
  };

  const toggleShowPass = () => {
    setshowPass(!showPass);
  };
  const toggleShowCPass = () => {
    setshowCPass(!showCPass);
  };

  useEffect(() => {
    //console.log("Show Pass", showPass);
  }, [showPass]);

  const getEmailVerificationCode = () => {
    setbtnLoad(true);
    Auth.forgotPassword(email)
      .then((data) => {
        setbtnLoad(false);
        setstage(2);
        setStatus(1);
        setAlertType("success");
        setMessage(
          "Verification code sent to Email: " +
            data.CodeDeliveryDetails.Destination
        );
      })
      .catch((err) => {
        setbtnLoad(false);
        setStatus(1);
        setAlertType("warning");
        setMessage("Error: " + err.message);
      });
  };

  const confirmPasswordChange = () => {
    setbtnLoad(true);
    Auth.forgotPasswordSubmit(email, code, password)
      .then((data) => {
        if (data === "SUCCESS") {
          setstage(3);
          setStatus(1);
          setAlertType("success");
          setMessage("Password Changed Please Relogin");
        }
        setbtnLoad(false);
        Auth.signOut();
      })
      .catch((err) => {
        setStatus(1);
        setAlertType("warning");
        setMessage("Password Change Error " + err.message);
        setbtnLoad(false);
      });
  };

  return (
    <div>
      <div className="container-fluid px-1 px-md-5 px-lg-1 px-xl-5 py-5 mx-auto">
        <div className="card card0 border-0">
          {status === 1 && (
            <Alerts
              setStatus={setStatus}
              alertType={alertType}
              message={message}
            ></Alerts>
          )}
          <div className="row d-flex">
            <div className="col-lg-6">
              <div className="card1">
                {/* <div className="row">
                  {" "}
                  <img
                    src={window.location.origin + "/img/black.png"}
                    className="logo"
                    alt=""
                  />{" "}
                </div> */}
                <div className="row  justify-content-center">
                  {" "}
                  <img
                    src={window.location.origin + "/img/banner.jpg"}
                    className="image"
                    alt=""
                    style={{width: "100%", height:"510px" }}
                  />{" "}
                </div>
              </div>
            </div>
            <div className="col-lg-6" style={{overflow: 'auto', maxHeight: "510px"}}>
              <Formik
                initialValues={{
                  email: "",
                  password: "",
                  confirmPassword: "",
                  code: "",
                }}
                onSubmit={
                  stage === 1 ? getEmailVerificationCode : confirmPasswordChange
                }
              >
                {({ errors, touched }) => (
                  <Form>
                    {stage === 1 && (
                      <div className="card2 card border-0 px-4 py-5">
                        <div className="row px-3">
                          <label className="mb-1">
                            <h6 className="mb-0 text-sm">Email Address</h6>
                          </label>
                          <Field
                            className="mb-2"
                            type="text"
                            name="email"
                            placeholder="Enter registered email address"
                            validate={validateEmail}
                          />
                          {errors.email && touched.email && (
                            <div className="invalid-feedback d-block mb-2 mt-n0">
                              {errors.email}
                            </div>
                          )}

                          <Button
                            className="mt-3"
                            type="submit"
                            disabled={btnLoad}
                          >
                            Send Email Verification Code
                          </Button>
                        </div>
                      </div>
                    )}
                    {stage === 2 && (
                      <div className="card2 card border-0 px-4 py-5">
                        <div className="row px-3">
                          <label className="mb-1">
                            <h6 className="mb-0 text-sm">Email Address</h6>
                          </label>
                          <Field
                            className="mb-2"
                            type="text"
                            name="email"
                            disabled
                            value={email}
                          />
                        </div>
                        <div className="row px-3">
                          <label className="mb-1">
                            <h6 className="mb-0 text-sm">Verification code</h6>
                          </label>
                          <Field
                            className="mb-2"
                            type="text"
                            name="code"
                            placeholder="Enter code received in email address"
                            validate={validateCode}
                          />
                          {errors.code && touched.code && (
                            <div className="invalid-feedback d-block mb-2 mt-n0">
                              {errors.code}
                            </div>
                          )}
                        </div>
                        <div className="row px-3">
                          <label className="mb-1">
                            <h6 className="mb-0 text-sm">Password </h6>
                          </label>
                          <div style={{ padding: 0, position: "relative" }}>
                            <div>
                              <Field
                                className="mb-3"
                                type={showPass ? "text" : "password"}
                                name="password"
                                placeholder="Enter new password"
                                validate={validatePassword}
                              />
                            </div>
                            <div
                              style={{
                                position: "absolute",
                                bottom: "25px",
                                right: "8px",
                              }}
                            >
                              {showPass && (
                                <VisibilityIcon
                                  onClick={() => toggleShowPass()}
                                />
                              )}
                              {!showPass && (
                                <VisibilityOffIcon
                                  onClick={() => toggleShowPass()}
                                />
                              )}
                            </div>
                          </div>
                          {errors.password && touched.password && (
                            <div className="invalid-feedback d-block mb-2 mt-n0">
                              {errors.password}
                            </div>
                          )}
                        </div>
                        <div className="row px-3">
                          <label className="mb-1">
                            <h6 className="mb-0 text-sm">Confirm Password </h6>
                          </label>
                          <div style={{ padding: 0, position: "relative" }}>
                            <div>
                              <Field
                                className="mb-2"
                                type={showCPass ? "text" : "password"}
                                name="confirmPassword"
                                placeholder="Re Enter new password "
                                validate={validateConfirmPassword}
                              />
                            </div>
                            <div
                              style={{
                                position: "absolute",
                                bottom: "25px",
                                right: "8px",
                              }}
                            >
                              {showCPass && (
                                <VisibilityIcon
                                  onClick={() => toggleShowCPass()}
                                />
                              )}
                              {!showCPass && (
                                <VisibilityOffIcon
                                  onClick={() => toggleShowCPass()}
                                />
                              )}
                            </div>
                          </div>
                          {errors.confirmPassword &&
                            touched.confirmPassword && (
                              <div className="invalid-feedback d-block mb-2 mt-n0">
                                {errors.confirmPassword}
                              </div>
                            )}
                        </div>
                        <div className="row px-3">
                          <Button
                            className="mt-3"
                            type="submit"
                            disabled={btnLoad}
                          >
                            Confirm Password Change
                          </Button>
                        </div>
                      </div>
                    )}
                    {stage === 3 && (
                      <div className="row">
                        <center>
                          <div style={{ width: "50%", marginTop: "20%" }}>
                            <Alert severity="success">
                              <AlertTitle>Success</AlertTitle>
                              User Password — <strong>Updated!</strong>
                            </Alert>
                            <br />
                            <button
                              onClick={() => {
                               window.location.href='/';
                              }}
                              className="btn btn-primary"
                              color="primary"
                            >
                              Go to Login
                            </button>
                          </div>
                        </center>
                      </div>
                    )}
                  </Form>
                )}
              </Formik>
            </div>
          </div>

          <Footer />
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
