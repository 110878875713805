import React from "react";
import { useState, useEffect } from "react";
import Alerts from "../alerts/alert";
import Alert from "@material-ui/lab/Alert";
import { AlertTitle } from "@mui/material";
import Footer from "../footer/footer";
import CryptoJS from "crypto-js";
import QRCode from "qrcode.react";

import {
  validateEmailMain,
  validatePasswordMain,
} from "../validations/basicRegFormValidations";
import { Formik, Form, Field } from "formik";
import { Button } from "react-bootstrap";
import { Auth } from "@aws-amplify/auth";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import ApiClient from "../../apis/apiClient";

function RecoverMFA() {
  const [status, setStatus] = useState(0);
  const [alertType, setalertType] = useState("success");
  const [message, setmessage] = useState("");
  const [showAns, setshowAns] = useState(false);
  const [showRC, setshowRC] = useState(false);
  const [btnLoad, setbtnLoad] = useState(false);
  const [btnLoadQR, setbtnLoadQR] = useState(false);
  const [email, setemail] = useState("");
  const [showStepTwo, setshowStepTwo] = useState(false);
  const [showStepThree, setshowStepThree] = useState(false);
  const [seqQuestion, setseqQuestion] = useState();
  const [seqAnswer, setseqAnswer] = useState();
  const [recoveryCode, setrecoveryCode] = useState();
  const [MFACode, setMFACode] = useState();
  const [mfaCode, setmfaCode] = useState();
  const toggleShowAns = () => setshowAns(!showAns);
  const toggleShowRC = () => setshowRC(!showRC);

  const validateEmail = (value) => {
    let error;
    if (!value) {
      error = "Please enter your email address";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
      setemail(value);
      error = "Invalid email address";
    } else {
      setemail(value);
    }
    return error;
  };

  const validateseqanswer = (value) => {
    let error;
    if (!value) {
      error = "Please enter security answer";
    } else if (value.length < 3) {
      error = "Answer should be atleast 3 characters";
      setseqAnswer(value);
    } else {
      setseqAnswer(value);
    }
    return error;
  };

  const validateRecoveryCode = (value) => {
    let error;
    if (!value) {
      error = "Please enter recovery code";
    } else if (value.length < 8) {
      error = "Recovery code should atleast be 8 characters";
      setrecoveryCode(value);
    } else {
      setrecoveryCode(value);
    }
    return error;
  };

  const getMFACredentials = async () => {
    if (validateEmail(email) !== undefined) return;
    setbtnLoad(true);
    const result = await ApiClient("post", "/getMFACredentials", {
      email: email,
    });
    if (result.data.status === 200) {
      setStatus(0);
      setshowStepTwo(true);
      setseqQuestion(result.data.result.mfa_security_question);
      setmfaCode(result.data.result.mfa_code);
    } else if (result.data.status === 400) {
      setStatus(1);
      setalertType("error");
      setmessage(result.data.result + " " + email + " exists in our record");
      setbtnLoad(false);
    }
  };

  const generateQR = () => {
    let paraphrase = recoveryCode.toUpperCase() + "@" + seqAnswer;
    try {
      let mfa_code = CryptoJS.AES.decrypt(mfaCode, paraphrase).toString(
        CryptoJS.enc.Utf8
      );
      console.log(mfa_code);
      if (mfa_code.length === 0 || !mfa_code.startsWith('otpauth://totp/AWSCognito:')) {
        setStatus(1);
        setalertType("error");
        setmessage(
          "Invalid input provided please check recovery code and security answer"
        );
      } else {
        setStatus(0);
        setMFACode(mfa_code);
        setshowStepTwo(false);
        setshowStepThree(true);
      }
    } catch (error) {
      setStatus(1);
      setalertType("error");
      setmessage(
        "Invalid input provided please check recovery code and security answer"
      );
    }
  };

  return (
    <div>
      <div className="container-fluid px-1 px-md-5 px-lg-1 px-xl-5 py-5 mx-auto">
        <div className="card card0 border-0">
          {status === 1 && (
            <Alerts
              setStatus={setStatus}
              alertType={alertType}
              message={message}
            ></Alerts>
          )}
          <div className="row d-flex">
            <div className="col-lg-6">
              <div className="card1">
                {/* <div className="row">
                  {" "}
                  <img
                    src={window.location.origin + "/img/black.png"}
                    className="logo"
                    alt=""
                  />{" "}
                </div> */}
                <div className="row  justify-content-center">
                  {" "}
                  <img
                    src={window.location.origin + "/img/banner.jpg"}
                    className="image"
                    alt=""
                    style={{ width: "100%", height: "510px" }}
                  />{" "}
                </div>
              </div>
            </div>
            <div
              className="col-lg-6"
              style={{ overflow: "auto", maxHeight: "510px" }}
            >
              <Formik
                initialValues={{
                  email: "",
                  answer: "",
                  recoveryCode: "",
                  code: "",
                }}
                onSubmit={
                  () => {
                    generateQR();
                  }
                  //stage === 1 ? getEmailVerificationCode : confirmPasswordChange
                }
              >
                {({ errors, touched }) => (
                  <Form>
                    <div className="card2 card border-0 px-4 py-5">
                      <div className="row px-3">
                        <label className="mb-1">
                          <h6 className="mb-0 text-sm">Email Address</h6>
                        </label>
                        <Field
                          className="mb-2"
                          type="text"
                          name="email"
                          validate={validateEmail}
                          disabled={showStepTwo}
                        />
                        {errors.email && touched.email && (
                          <div className="invalid-feedback d-block mb-2">
                            {errors.email}
                          </div>
                        )}
                        <Button
                          variant="success"
                          className="mb-4"
                          disabled={btnLoad}
                          onClick={() => {
                            getMFACredentials();
                          }}
                        >
                          Get Record
                        </Button>
                      </div>
                      {showStepTwo && (
                        <div>
                          <div className="row px-3">
                            <label className="mb-1">
                              <h6 className="mb-0 text-sm">
                                Security Question
                              </h6>
                            </label>
                            <Field
                              className="mb-2"
                              type="text"
                              name="code"
                              disabled
                              value={seqQuestion}
                            />
                          </div>
                          <div className="row px-3">
                            <label className="mb-1">
                              <h6 className="mb-0 text-sm">Answer </h6>
                            </label>
                            <div style={{ padding: 0, position: "relative" }}>
                              <div>
                                <Field
                                  className="mb-3"
                                  type={showAns ? "text" : "password"}
                                  name="answer"
                                  placeholder="Enter new password"
                                  validate={validateseqanswer}
                                />
                              </div>
                              <div
                                style={{
                                  position: "absolute",
                                  bottom: "25px",
                                  right: "8px",
                                }}
                              >
                                {showAns && (
                                  <VisibilityIcon
                                    onClick={() => toggleShowAns()}
                                  />
                                )}
                                {!showAns && (
                                  <VisibilityOffIcon
                                    onClick={() => toggleShowAns()}
                                  />
                                )}
                              </div>
                            </div>
                            {errors.answer && touched.answer && (
                              <div className="invalid-feedback d-block mb-2 mt-n0">
                                {errors.answer}
                              </div>
                            )}
                          </div>
                          <div className="row px-3">
                            <label className="mb-1">
                              <h6 className="mb-0 text-sm">Recovery Code </h6>
                            </label>
                            <div style={{ padding: 0, position: "relative" }}>
                              <div>
                                <Field
                                  className="mb-2"
                                  type={showRC ? "text" : "password"}
                                  name="recoveryCode"
                                  validate={validateRecoveryCode}
                                />
                              </div>
                              <div
                                style={{
                                  position: "absolute",
                                  bottom: "25px",
                                  right: "8px",
                                }}
                              >
                                {showRC && (
                                  <VisibilityIcon
                                    onClick={() => toggleShowRC()}
                                  />
                                )}
                                {!showRC && (
                                  <VisibilityOffIcon
                                    onClick={() => toggleShowRC()}
                                  />
                                )}
                              </div>
                            </div>
                            {errors.recoveryCode && touched.recoveryCode && (
                              <div className="invalid-feedback d-block mb-2 mt-n0">
                                {errors.recoveryCode}
                              </div>
                            )}
                          </div>
                          <div className="row px-3">
                            <Button
                              className="mt-3"
                              type="submit"
                              disabled={btnLoadQR}
                            >
                              Generate QR
                            </Button>
                          </div>
                        </div>
                      )}
                      {showStepThree && (
                        <>
                          <span>Recovered MFA QR code:</span>
                          <div className="d-flex justify-content-center mb-5">
                            <QRCode value={MFACode} />
                          </div>
                          <p style={{ color: "red" }}>
                            <sup>*</sup>Note: We do not guarantee the
                            correctness of the MFA as we do not store any values
                            to validate the inputs given in the previous step
                          </p>
                          <Button onClick={()=> window.location.href='/'}>Go To Login</Button>
                        </>
                      )}
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>

          <Footer />
        </div>
      </div>
    </div>
  );
}

export default RecoverMFA;
