import { React, useState, useEffect } from "react";
import ApiClient from "../../../apis/apiClient";
import AddStation from "./addStation";
import StationTableData from "./stationTableData";
import LinearProgress from "@material-ui/core/LinearProgress";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { Button, Modal } from "react-bootstrap";
import { TextField } from "@material-ui/core";
// import Backdrop from "@mui/material/Backdrop";
// import CircularProgress from "@mui/material/CircularProgress";
import validateUser from "../../validations/userSessionValidation";
import encryptHash from "../../validations/general";
import Tooltip from "@mui/material/Tooltip";
import Auth from "@aws-amplify/auth";
import Cookies from "js-cookie";
import logout from "../../validations/logoutUser";
import checkPageAccess from "../../others/getPageAccess";

import SweetAlert from "react-bootstrap-sweetalert";
import Loader from "../../loader/loader";

import DangerousIcon from "@mui/icons-material/Dangerous";
import ErrorIcon from "@mui/icons-material/Error";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import InfoIcon from "@mui/icons-material/Info";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Chip from "@material-ui/core/Chip";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  FormGroup,
  FormControlLabel,
  Switch,
  Checkbox,
  Input,
  InputAdornment,
} from "@mui/material";
import {
  validateNameMain,
  validateIdMain,
  validateCallSignMain,
  validateFrequencyMain,
  validateBitRateMain,
  validateRegionMain,
  validateIpMain,
  validateRegionNameMain,
  validatePortMain,
  validateDestinationMain,
  validateEmailMain,
} from "../../validations/generalValidations";
import { Autocomplete } from "@mui/material";

export default function RegionStationSection() {
  const [selectedRow, setselectedRow] = useState(null);
  const [stationArr, setStation] = useState([]);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [tableLoader, setTableLoader] = useState(true);
  const [userAuthToken, setToken] = useState(null);
  const [open, setOpen] = useState(false);
  const [stationnameEdit, setstationnameEdit] = useState(null);
  const [bitRate, setBitRateEdit] = useState(null);
  const [ip, setIpEdit] = useState(null);
  const [portEdit, setportEdit] = useState("8888");
  const [sshportEdit, setsshportEdit] = useState("22");
  const [adminNameEdit, setadminNameEdit] = useState("");
  const [adminEmailEdit, setadminEmailEdit] = useState("");
  const [frequency, setFreqencyEdit] = useState(null);
  const [destinationEdit, setdestinationEdit] = useState("/no/path/set");
  const [regionArr, setRegionArr] = useState([""]);
  const [loading, setloading] = useState(true);

  const [alertMessage, setAlertMessage] = useState("");
  const [alert, setAlert] = useState(false);
  const [severity, setSeverity] = useState("default");
  const [alertTitle, setAlertTitle] = useState("");
  const [confirmAction, setConfirmAction] = useState("");
  const [hrefLink, setHrefLink] = useState("");
  const [loaderType, setLoaderType] = useState("CircularProgress");
  const [percentage, setpercentage] = useState(0);
  const [notifRow, setnotifRow] = useState("");
  const [notificationData, setnotificationData] = useState({});
  const [adminEmail, setadminEmail] = useState("");

  const [newStationEdit, showNewStationEdit] = useState(false);
  const [newStationEditTxt, setNewStationEditTxt] = useState("");

  const [bitRateEdit, showBitRateEdit] = useState(false);
  const [bitRateEditTxt, setBitRateEditTxt] = useState("");

  const [ipEdit, showIpEdit] = useState(false);
  const [ipEditTxt, setIpEditTxt] = useState("");

  const [httpportEdit, showHttpportEdit] = useState(false);
  const [httpportEditTxt, sethttpportEditTxt] = useState("");

  const [sshportEditVal, showSSHportEdit] = useState(false);
  const [sshportEditTxt, setSSHportEditTxt] = useState("");

  const [stationAdminNameEdit, showStationAdminNameEdit] = useState(false);
  const [stationAdminNameEditTxt, setStationAdminNameEditTxt] = useState("");

  const [stationAdminEmailEdit, showStationAdminEmailEdit] = useState(false);
  const [stationAdminEmailEditTxt, setStationAdminEmailEditTxt] = useState("");

  const [frequencyEdit, showFrequencyEdit] = useState(false);
  const [frequencyEditTxt, setFrequencyEditTxt] = useState("");

  const [destinationPathEdit, showDestinationPathEdit] = useState(false);
  const [destinationPathEditTxt, setDestinationPathEditTxt] = useState("");

  const [stationStatusIP, setstationStatusIP] = useState();
  const [stationStatusPort, setstationStatusPort] = useState();
  const [stationStatusResponse, setstationStatusResponse] = useState(
    "Enter Required Fields and  Click Check to see Ping Status"
  );
  const [showStationStatus, setshowStationStatus] = useState(false);
  const [showPortStatus, setshowPortStatus] = useState(false);
  const [showNotifSettings, setshowNotifSettings] = useState(false);
  const [notifSettingsEnabled, setnotifSettingsEnabled] = useState();
  const [notifSettingsMailList, setnotifSettingsMailList] = useState();
  const [notifSettingsExcludeList, setnotifSettingsExcludeList] = useState();
  const [notifSettingsInterval, setnotifSettingsInterval] = useState();
  const [notifSettingsSnooze, setnotifSettingsSnooze] = useState(0);
  const [emailPauseNotifDelete, setemailPauseNotifDelete] = useState("");
  const [showEditNotifInterval, setshowEditNotifInterval] = useState(true);
  const [showAddEmailNotifications, setshowAddEmailNotifications] =
    useState(true);
  const [showDeleteNotifPause, setshowDeleteNotifPause] = useState(false);
  const [deleteNotificationPauseVersion, setdeleteNotificationPauseVersion] =
    useState(1);
  const [showSnoozeError, setShowSnoozeError] = useState(false);
  const [showNewEmailListError, setshowNewEmailListError] = useState(false);
  const [showSnoozeEmailListError, setshowSnoozeEmailListError] =
    useState(false);
  const [showNotifIntervalError, setshowNotifIntervalError] = useState(false);

  const handleHideStationStatusModal = () => {
    setstationStatusResponse(
      "Enter Required Fields and  Click Check to see Ping Status"
    );
    setshowStationStatus(false);
  };
  const handleShowStationStatusModal = () => setshowStationStatus(true);

  const handleCloseDelete = () => setShowConfirmDelete(false);
  const handleShowDelete = () => setShowConfirmDelete(true);
  const access_levels = Cookies.get("t") ? Cookies.get("t").split(",") : [];

  const handleClose = () => {
    setOpen(false);
    showNewStationEdit(false);
    showBitRateEdit(false);
    showIpEdit(false);
    showHttpportEdit(false);
    showSSHportEdit(false);
    showStationAdminNameEdit(false);
    showStationAdminEmailEdit(false);
    showFrequencyEdit(false);
    showDestinationPathEdit(false);
  };
  const handleShow = () => {
    if (!open) {
    }
    setOpen(true);
  };

  useEffect(async () => {
    document.title = "Stations  | Xperi HD Radio IoT";
    let isValid = await checkPageAccess();
    if (!isValid) {
      // if (!alert("access denied for the url"))
      //   window.location.href = "/unauthorized";
      setAlert(true);
      setSeverity("error");
      setAlertMessage("access denied for the url");
      setAlertTitle("Something Went Wrong");
      setConfirmAction("href");
      setHrefLink("/unauthorized");
      return;
    } else {
      //console.log("You have access");
    }
    if (validateUser()) {
      let getauth = async () => {
        return (await Auth.currentSession()).getAccessToken().getJwtToken();
      };
      getauth()
        .then(async (res) => {
          let result = await ApiClient("post", "/admin/regions/stations", "", {
            Authorization: Cookies.get("auth"),
          });
          if (result.data.status === 200) {
            setStation(result.data.result);
            if (access_levels.indexOf(encryptHash("777")) === -1) {
              //setloading(false);
              return;
            }
          } else if (result.data.status === 402) {
            setAlert(true);
            setSeverity("success");
            setAlertMessage("No Records");
            setAlertTitle("Success");
            setConfirmAction("");
            setHrefLink("");
          } else if (result.data.status === 403) {
            setAlert(true);
            setSeverity("error");
            setAlertMessage("Session Expired Please Relogin ");
            setAlertTitle("Relogin");
            setConfirmAction("logout");
            return;
          } else {
            setAlert(true);
            setSeverity("error");
            setAlertMessage("Some Error Occured");
            setAlertTitle("Something Went Wrong");
            setConfirmAction("");
            setHrefLink("");
          }
          let result2 = await ApiClient("get", "/app/regions", "", {
            "Content-Type": "application/json",
            authorization: Cookies.get("auth"),
          });
          if (result2.data.status === 200) {
            let data = result2.data.result.map((v) => {
              return v.name;
            });
            setRegionArr(data);
          } else if (result2.data.status === 403) {
            setAlert(true);
            setSeverity("error");
            setAlertMessage("Session Expired Please Relogin ");
            setAlertTitle("Relogin");
            setConfirmAction("logout");
            return;
          } else {
            setAlert(true);
            setSeverity("error");
            setAlertMessage("Some Error Occured");
            setAlertTitle("Something Went Wrong");
            setConfirmAction("");
            setHrefLink("");
          }
          let result3 = await ApiClient(
            "get",
            "/admin/getStationNotificationSettings",
            "",
            {
              "Content-Type": "application/json",
              authorization: Cookies.get("auth"),
            }
          );
          if (result3.data.status === 200) {
            //console.log(result3.data);
            setnotificationData(result3.data.data);
          } else {
            setAlert(true);
            setSeverity("error");
            setAlertMessage("Some Error Occured");
            setAlertTitle("Something Went Wrong");
            setConfirmAction("logout");
            setHrefLink("");
          }
          setloading(false);
        })
        .catch((err) => {
          console.log("Error in station data ", err);
          logout();
        });
    }
  }, []);

  useEffect(() => {
    ////console.log("Stations Array ", stationArr);
    if (stationArr) {
      setTableLoader(false);
    }
  }, [stationArr]);

  useEffect(() => {
    console.log(notificationData[notifRow]);
    if (notifRow.length !== 0) {
      setnotifSettingsEnabled(true);
      setnotifSettingsMailList(notificationData[notifRow]?.mail_list);
      setnotifSettingsExcludeList(notificationData[notifRow]?.exclude_list);
      setnotifSettingsInterval(
        notificationData[notifRow]?.notification_interval
      );
      setshowNotifSettings(true);
    } else {
      setshowNotifSettings(false);
    }
  }, [notifRow]);

  const updateStationStatus = async (status) => {
    setloading(true);
    let result = await ApiClient(
      "post",
      "/admin/updateStationStatus",
      {
        regionName: stationArr[selectedRow].region,
        stationid: stationArr[selectedRow].id,
        status: status,
      },
      { Authorization: Cookies.get("auth") }
    );
    if (result.data.status === 200) {
      setloading(false);
      ////console.log("Success in updating station ", result.data.data);
    } else if (result.data.status === 400) {
      setloading(false);
      setAlert(true);
      setSeverity("error");
      setAlertMessage("Nothing to update");
      setAlertTitle("Something Went Wrong");
      setConfirmAction("");
      setHrefLink("");
    } else if (result.data.status === 403) {
      setAlert(true);
      setSeverity("error");
      setAlertMessage("Session Expired Please Relogin ");
      setAlertTitle("Relogin");
      setConfirmAction("logout");
    } else if (result.data.status === 422) {
      setloading(false);
      setAlert(true);
      setSeverity("error");
      setAlertMessage("Missing required param");
      setAlertTitle("Something Went Wrong");
      setConfirmAction("");
      setHrefLink("");
    } else {
      setloading(false);
      setAlert(true);
      setSeverity("error");
      setAlertMessage("Cant update station status");
      setAlertTitle("Something Went Wrong");
      setConfirmAction("");
      setHrefLink("");
      ////console.log("Error in updating station", result.data.data);
    }
  };

  const updatePingImmediate = async (id) => {
    setloading(true);
    let result = await ApiClient(
      "post",
      "/admin/pingAndUpdateStationStatus",
      {
        stationid: stationArr[id].id,
      },
      { Authorization: Cookies.get("auth") }
    );
    if (result.data.status === 200) {
      setloading(false);
      setAlert(true);
      setSeverity("Success");
      setAlertMessage("Station Status Updated");
      setAlertTitle("Update Succeded");
      setConfirmAction("load");
      setHrefLink("");
    } else if (result.data.status === 500) {
      setloading(false);
      setAlert(true);
      setSeverity("error");
      setAlertMessage("Server Error");
      setAlertTitle("Something Went Wrong");
      setConfirmAction("");
      setHrefLink("");
    }
  };
  const saveStationEdit = async () => {
    if (
      !(
        newStationEdit ||
        bitRateEdit ||
        ipEdit ||
        httpportEdit ||
        sshportEditVal ||
        stationAdminNameEdit ||
        stationAdminEmailEdit ||
        frequencyEdit ||
        destinationPathEdit
      )
    ) {
      setloading(true);
      let result = await ApiClient(
        "post",
        "/admin/editStation",
        {
          regionName: stationArr[selectedRow].region,
          callSign: stationArr[selectedRow].call_sign,
          stationid: stationArr[selectedRow].id,
          stationname: stationnameEdit,
          bitRate: bitRate,
          ip: ip,
          port: portEdit,
          sshport: sshportEdit,
          adminname: adminNameEdit,
          adminemail: adminEmailEdit,
          frequency: frequency,
          destination: destinationEdit,
        },
        { Authorization: Cookies.get("auth") }
      );
      if (result.data.status === 200) {
        ////console.log("Success in updating station ", result.data.data);
        let stnArr = [...stationArr];
        stnArr[selectedRow].name = stationnameEdit;
        stnArr[selectedRow].bit_rate = bitRate;
        stnArr[selectedRow].ip = ip;
        stnArr[selectedRow].port = portEdit;
        stnArr[selectedRow].sshport = sshportEdit;
        stnArr[selectedRow].admin_name = adminNameEdit;
        stnArr[selectedRow].admin_email = adminEmailEdit;
        stnArr[selectedRow].frequency = frequency;
        stnArr[selectedRow].destination = destinationEdit;
        setStation(stnArr);
        handleClose();
        setAlert(true);
        setSeverity("success");
        setAlertMessage("Station Edited Successfully");
        setAlertTitle("Success");
        setloading(false);
        setConfirmAction("load");
      } else if (result.data.status === 400) {
        setloading(false);
        setAlert(true);
        setSeverity("error");
        setAlertMessage("Bucket policy Error!!! While editing station");
        setAlertTitle("Something Went Wrong");
        setConfirmAction("");
        setHrefLink("");
      } else if (result.data.status === 403) {
        setloading(false);
        setAlert(true);
        setSeverity("error");
        setAlertMessage("Session Expired Please Relogin ");
        setAlertTitle("Relogin");
        setConfirmAction("logout");
      } else if (result.data.status === 422) {
        let errMsg = "";
        for (let indx = 0; indx < result.data.result.length; indx++) {
          errMsg =
            errMsg + " " + (indx + 1) + ". " + result.data.result[indx].msg;
        }
        handleClose();
        setloading(false);
        setAlert(true);
        setSeverity("error");
        setAlertMessage(errMsg);
        setAlertTitle("Something Went Wrong");
        setConfirmAction("");
        setHrefLink("");
      } else {
        handleClose();
        setloading(false);
        setAlert(true);
        setSeverity("error");
        setAlertMessage(result.data.result);
        setAlertTitle("Something Went Wrong");
        // setloading(false);

        ////console.log("Error in updating station", result.data.data);
      }
    }
  };
  const confirmAlert = () => {
    setAlert(false);
    setSeverity("default");
    setAlertTitle("");
    if (confirmAction === "load") {
      window.location.reload();
    } else if (confirmAction === "href") {
      console.log("inside conf href");
      window.location = hrefLink;
    } else if (confirmAction === "logout") {
      logout();
    }
  };

  const triggerIPStatusLambda = async () => {
    setloading(true);
    let result = await ApiClient(
      "post",
      "/admin/stationStatusUpdateLambdaCall",
      {},
      {
        "Content-Type": "application/json",
        Authorization: Cookies.get("auth"),
      }
    );

    if (result.data.status === 200) {
      // if (!alert(result.data.result)) location.reload();
      setloading(false);
      setAlert(true);
      setSeverity("success");
      setAlertMessage(result.data.result);
      setAlertTitle("Success");
      setConfirmAction("load");
    } else {
      setloading(false);
      setAlert(true);
      setSeverity("warning");
      setAlertMessage(result.data.result);
      setAlertTitle("Error");
      setConfirmAction("load");
    }
  };

  const validateEditStationName = (value) => {
    let errorMsg = validateNameMain(
      value,
      setstationnameEdit,
      showNewStationEdit
    );
    setNewStationEditTxt(errorMsg);
  };

  const validateEditBitRate = (value) => {
    let errorMsg = validateBitRateMain(value, setBitRateEdit, showBitRateEdit);
    setBitRateEditTxt(errorMsg);
  };

  const validateEditIp = (value) => {
    let errorMsg = validateIpMain(value, setIpEdit, showIpEdit);
    setIpEditTxt(errorMsg);
  };

  const validateEditPort = (value) => {
    let errorMsg = validatePortMain(value, setportEdit, showHttpportEdit);
    sethttpportEditTxt(errorMsg);
  };

  const validateEditSSHPort = (value) => {
    let errorMsg = validatePortMain(value, setsshportEdit, showSSHportEdit);
    setSSHportEditTxt(errorMsg);
  };

  const validateEditAdminName = (value) => {
    let errorMsg = validateNameMain(
      value,
      setadminNameEdit,
      showStationAdminNameEdit
    );
    setStationAdminNameEditTxt(errorMsg);
  };

  const validateEditAdminEmail = (value) => {
    let errorMsg = validateEmailMain(
      value,
      setadminEmailEdit,
      showStationAdminEmailEdit
    );
    setStationAdminEmailEditTxt(errorMsg);
  };

  const validateEditFrequency = (value) => {
    let errorMsg = validateFrequencyMain(
      value,
      setFreqencyEdit,
      showFrequencyEdit
    );
    setFrequencyEditTxt(errorMsg);
  };
  const validateEditDestination = (value) => {
    let errorMsg = validateDestinationMain(
      value,
      setdestinationEdit,
      showDestinationPathEdit
    );
    setDestinationPathEditTxt(errorMsg);
  };

  const checkLiveStationStatus = async () => {
    let regEx = /^((25[0-5]|(2[0-4]|1\d|[1-9]|)\d)(\.(?!$)|$)){4}$/;
    let resp;
    if (!stationStatusIP.match(regEx)) {
      alert("Invalid Station IP");
      return;
    }
    setloading(true);
    let authToken = await (await Auth.currentSession())
      .getAccessToken()
      .getJwtToken();
    //console.log(authToken);
    try {
      resp = await ApiClient(
        "get",
        "/admin/getIPandPortStatus",
        "env=prod&ip=" + stationStatusIP + "&port=" + stationStatusPort,
        {
          "Content-Type": "application/json",
          authorization: Cookies.get("auth"),
        }
      );

      if (resp.data.status === 200) {
        setstationStatusResponse(resp.data.msg);
        setloading(false);
      } else {
        setstationStatusResponse(resp.data.msg);
        setloading(false);
      }
    } catch (error) {
      setstationStatusResponse(resp.data.msg);
      setloading(false);
    }
    // setTimeout(() => {
    //   setstationStatusResponse("Please try after sometime");
    //   setloading(false);
    // }, 2000);
  };

  const enableNotificationSettings = async () => {
    setloading(true);
    let resp = await ApiClient(
      "post",
      "/admin/enableNotificationSettings",
      {
        call_sign: notifRow,
        admin_email: adminEmail,
      },
      {
        "Content-Type": "application/json",
        authorization: Cookies.get("auth"),
      }
    );

    if (resp.data.status === 200) {
      setloading(false);
      setAlert(true);
      setSeverity("success");
      setAlertMessage("Notification Settings Enabled");
      setAlertTitle("Success");
      setConfirmAction("load");
    } else {
      setloading(false);
      setAlert(true);
      setSeverity("error");
      setAlertMessage("Cant update Notification Settings");
      setAlertTitle("Something Went Wrong");
      setConfirmAction("load");
      setHrefLink("");
    }
  };

  const saveNotifSettingsUpdate = async () => {
    let email_temp = [];
    for (let em of notifSettingsMailList) {
      let exp =
        /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
      //console.log(em,!exp.test(em))
      if (!exp.test(em)) {
        setshowNewEmailListError(true);
        return;
      } else {
        email_temp.push(em);
      }
    }
    setnotifSettingsMailList(email_temp);
    if (!notifSettingsEnabled && (notifSettingsSnooze || 0) <= 0) {
      setShowSnoozeError(true);
      return;
    }
    if (notifSettingsInterval < 5 || notifSettingsInterval > 1440) {
      setshowNotifIntervalError(true);
      return;
    }
    if (
      !notifSettingsEnabled &&
      notifSettingsExcludeList.length <=
        notificationData[notifRow]?.exclude_list.length
    ) {
      setshowSnoozeEmailListError(true);
      return;
    }
    console.log(
      notifRow,
      notifSettingsEnabled,
      notifSettingsMailList,
      notifSettingsExcludeList,
      notifSettingsSnooze,
      notifSettingsInterval,
      notifSettingsExcludeList.length,
      notificationData[notifRow]?.exclude_list.length
    );
    setloading(true);
    let resp = await ApiClient(
      "post",
      "/admin/updateNotificationSettings",
      {
        call_sign: notifRow,
        enabled: notifSettingsEnabled,
        snooze: notifSettingsSnooze,
        email_list: notifSettingsMailList,
        exclude_list: notifSettingsExcludeList,
        interval: notifSettingsInterval,
      },
      {
        "Content-Type": "application/json",
        authorization: Cookies.get("auth"),
      }
    );
    if (resp.data.status === 200) {
      setloading(false);
      setAlert(true);
      setSeverity("success");
      setAlertMessage("Notification Settings Updated");
      setAlertTitle("Success");
      setConfirmAction("load");
    } else {
      setloading(false);
      setAlert(true);
      setSeverity("error");
      setAlertMessage("Cant update Notification Settings");
      setAlertTitle("Something Went Wrong");
      setConfirmAction("load");
      setHrefLink("");
    }
  };
  const confirmDeleteNotificationPause = async () => {
    console.log(emailPauseNotifDelete);
    setshowDeleteNotifPause(false);
    setloading(true);
    if (deleteNotificationPauseVersion === 1) {
      let result = await ApiClient(
        "post",
        "/admin/deleteNotificationPauseSettings",
        { call_sign: notifRow, email: emailPauseNotifDelete },
        {
          Authorization: Cookies.get("auth"),
        }
      );

      if (result.data.status === 200) {
        setloading(false);
        setAlert(true);
        setSeverity("success");
        setAlertMessage("Notification Alert Deleted");
        setAlertTitle("Success");
        setConfirmAction("load");
        setHrefLink("");
      }
    } else {
      let result = await ApiClient(
        "post",
        "/admin/deleteUserEmailNotificationSettings",
        { call_sign: notifRow, email: emailPauseNotifDelete },
        {
          Authorization: Cookies.get("auth"),
        }
      );

      if (result.data.status === 200) {
        setloading(false);
        setAlert(true);
        setSeverity("success");
        setAlertMessage("Notification Alert Deleted");
        setAlertTitle("Success");
        setConfirmAction("load");
        setHrefLink("");
      }
    }
  };
  const deleteNotificationPause = (email, ver) => {
    if (ver === 1) setdeleteNotificationPauseVersion(1);
    else setdeleteNotificationPauseVersion(2);
    setemailPauseNotifDelete(email);
    setshowDeleteNotifPause(true);
  };

  const ver1HTMLNotifPause = () => {
    return (
      <>
        <em>
          <strong>{emailPauseNotifDelete}</strong>
        </em>{" "}
        will start receiving notifications once you delete the pause
      </>
    );
  };

  const ver2HTMLNotifPause = () => {
    return (
      <>
        <em>
          <strong>{emailPauseNotifDelete}</strong>
        </em>{" "}
        will stop receiving notifications alerts for station failure
      </>
    );
  };

  return (
    <div>
      {alert && (
        <SweetAlert type={severity} title={alertTitle} onConfirm={confirmAlert}>
          {alertMessage}
        </SweetAlert>
      )}
      {showDeleteNotifPause && (
        <SweetAlert
          warning
          showCancel
          confirmBtnText="Yes, delete it!"
          confirmBtnBsStyle="danger"
          title="Are you sure?"
          onConfirm={() => confirmDeleteNotificationPause()}
          onCancel={() => setshowDeleteNotifPause(false)}
          focusCancelBtn
        >
          {deleteNotificationPauseVersion === 1
            ? ver1HTMLNotifPause
            : ver2HTMLNotifPause}
        </SweetAlert>
      )}
      {/* <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop> */}
      <Loader
        loading={loading}
        loaderType={loaderType}
        percentage={percentage}
      ></Loader>
      <div
        className="row d-flex justify-content-center"
        style={{ marginTop: "125px", marginRight: "10px", marginLeft: "10px" }}
      >
        <div className="col-md-12">
          <div className="card" style={{ padding: "5px" }}>
            <div className="col-md-12 mt-3">
              <div className="d-flex">
                <h5 className="mr-auto">List of Stations</h5>
                {/* <Button
                      style={{
                        backgroundColor: "#F12A8D",
                        borderColor: "#F12A8D",
                        color: "white",
                      }}
                      onClick={() => triggerIPStatusLambda()}
                      className="ml-2"
                    >
                      Update All Station Status
                    </Button> */}
                <div className="ml-2">
                  <Button
                    style={{
                      backgroundColor: "#4F3493",
                      borderColor: "#4F3493",
                      color: "white",
                    }}
                    onClick={() => handleShowStationStatusModal()}
                  >
                    Check Station Status
                  </Button>
                </div>
              </div>
            </div>
            <br />
            <br />
            {tableLoader === true && (
              <Box display="flex" alignItems="center">
                <Box width="85%" mr={1}>
                  <LinearProgress />
                </Box>
                <Box minWidth={35}>
                  <Typography variant="body2" color="textSecondary">
                    Loading Table...
                  </Typography>
                </Box>
              </Box>
            )}
            {tableLoader === false && (
              <div className="m-3">
                <StationTableData
                  stationArr={stationArr}
                  setselectedRow={setselectedRow}
                  handleShowDelete={handleShowDelete}
                  setBitRateEdit={setBitRateEdit}
                  setstationnameEdit={setstationnameEdit}
                  setIpEdit={setIpEdit}
                  setportEdit={setportEdit}
                  setsshportEdit={setsshportEdit}
                  setadminEmailEdit={setadminEmailEdit}
                  setadminNameEdit={setadminNameEdit}
                  setFreqencyEdit={setFreqencyEdit}
                  setdestinationEdit={setdestinationEdit}
                  handleShow={handleShow}
                  setshowPortStatus={setshowPortStatus}
                  updatePingImmediate={updatePingImmediate}
                  setnotifRow={setnotifRow}
                  setadminEmail={setadminEmail}
                />
              </div>
            )}
            <br />
            <hr />
            {access_levels.indexOf(encryptHash("777")) !== -1 && (
              <div className="m-3">
                <AddStation
                  regionArr={regionArr}
                  setRegionArr={setRegionArr}
                  setAlertMessage={setAlertMessage}
                  setAlert={setAlert}
                  setSeverity={setSeverity}
                  setAlertTitle={setAlertTitle}
                  setConfirmAction={setConfirmAction}
                  setHrefLink={setHrefLink}
                  setloading={setloading}
                  loading={loading}
                />
              </div>
            )}
          </div>
          <br />
          <br />
          <br />
        </div>
      </div>

      {/* Modal for Update Station Status to inactive */}
      <Modal
        show={showConfirmDelete}
        onHide={handleCloseDelete}
        backdrop="static"
        keyboard={false}
        id="oldModal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Activate/Inactivate Station</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedRow !== null && (
            <div>
              <p>
                Are you sure you want to{" "}
                {stationArr[selectedRow].is_active ? "deactivate" : "activate"}{" "}
                Station{" "}
                <em>
                  <b>{stationArr[selectedRow].name}</b>
                </em>{" "}
                in Region{" "}
                <em>
                  <b>{stationArr[selectedRow].region}</b>
                </em>
              </p>
              <Button
                variant="danger"
                onClick={() => {
                  let allStations = [...stationArr];
                  let status = !allStations[selectedRow].is_active;
                  allStations[selectedRow].is_active = status;
                  setStation(allStations);
                  updateStationStatus(status);
                  handleCloseDelete();
                }}
              >
                YES
              </Button>{" "}
              <Button variant="dark" onClick={() => handleCloseDelete()}>
                NO
              </Button>
            </div>
          )}
        </Modal.Body>
      </Modal>
      {/* End Modal for Update Station Status to inactive */}

      {/* Modal for edit staion data */}
      <Modal show={open} onHide={handleClose} animation={false} id="newModal">
        <Modal.Header closeButton>
          <Modal.Title>Edit Station</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {open === true && (
            <div>
              <div className="row">
                <div className="col-md-6">
                  Call Sign:{" "}
                  <em>
                    <b>{stationArr[selectedRow].call_sign}</b>
                  </em>{" "}
                </div>
                <br />
                <div className="col-md-6">
                  In Region:{" "}
                  <em>
                    <b>{stationArr[selectedRow].region}</b>
                  </em>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6" style={{ marginBottom: "15px" }}>
                  Station Name
                  <br />
                  <TextField
                    id="stationname"
                    defaultValue={stationArr[selectedRow].name}
                    variant="filled"
                    inputProps={{
                      style: { width: "100%", color: "#0f1010" },
                    }}
                    onChange={(event) => {
                      setstationnameEdit(event.target.value);
                      validateEditStationName(event.target.value);
                    }}
                    error={newStationEdit ? true : false}
                    helperText={newStationEdit ? newStationEditTxt : ""}
                  />
                </div>
                <div className="col-md-6" style={{ marginBottom: "15px" }}>
                  Bit Rate (Kbps)
                  <br />
                  <TextField
                    id="bitRate"
                    defaultValue={stationArr[selectedRow].bit_rate}
                    variant="filled"
                    type="number"
                    inputProps={{
                      style: { width: "100%", color: "#0f1010" },
                    }}
                    onChange={(event) => {
                      setBitRateEdit(event.target.value);
                      validateEditBitRate(event.target.value);
                    }}
                    error={bitRateEdit ? true : false}
                    helperText={bitRateEdit ? bitRateEditTxt : ""}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6" style={{ marginBottom: "15px" }}>
                  IP
                  <br />
                  <TextField
                    id="ip"
                    defaultValue={stationArr[selectedRow].ip}
                    variant="filled"
                    inputProps={{
                      style: { width: "100%", color: "#0f1010" },
                    }}
                    onChange={(event) => {
                      setIpEdit(event.target.value);
                      validateEditIp(event.target.value);
                    }}
                    error={ipEdit ? true : false}
                    helperText={ipEdit ? ipEditTxt : ""}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6" style={{ marginBottom: "15px" }}>
                  HTTP Port
                  <br />
                  <TextField
                    id="httpport"
                    defaultValue={stationArr[selectedRow].port}
                    variant="filled"
                    inputProps={{
                      style: { width: "100%", color: "#0f1010" },
                    }}
                    onChange={(event) => {
                      setportEdit(event.target.value);
                      validateEditPort(event.target.value);
                    }}
                    error={httpportEdit ? true : false}
                    helperText={httpportEdit ? httpportEditTxt : ""}
                  />
                </div>
                <div className="col-md-6" style={{ marginBottom: "15px" }}>
                  SSH Port
                  <br />
                  <TextField
                    id="adminemail"
                    defaultValue={stationArr[selectedRow].ssh_port}
                    variant="filled"
                    inputProps={{
                      style: { width: "100%", color: "#0f1010" },
                    }}
                    onChange={(event) => {
                      setsshportEdit(event.target.value);
                      validateEditSSHPort(event.target.value);
                    }}
                    error={sshportEditVal ? true : false}
                    helperText={sshportEditVal ? sshportEditTxt : ""}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6" style={{ marginBottom: "15px" }}>
                  Staion Admin name
                  <br />
                  <TextField
                    id="stationadminname"
                    defaultValue={stationArr[selectedRow].admin_name}
                    variant="filled"
                    inputProps={{
                      style: { width: "100%", color: "#0f1010" },
                    }}
                    onChange={(event) => {
                      setadminNameEdit(event.target.value);
                      validateEditAdminName(event.target.value);
                    }}
                    error={stationAdminNameEdit ? true : false}
                    helperText={
                      stationAdminNameEdit ? stationAdminNameEditTxt : ""
                    }
                  />
                </div>
                <div className="col-md-6" style={{ marginBottom: "15px" }}>
                  Station Admin Email
                  <br />
                  <TextField
                    id="adminemail"
                    defaultValue={stationArr[selectedRow].admin_email}
                    variant="filled"
                    inputProps={{
                      style: { width: "100%", color: "#0f1010" },
                    }}
                    onChange={(event) => {
                      setadminEmailEdit(event.target.value);
                      validateEditAdminEmail(event.target.value);
                    }}
                    error={stationAdminEmailEdit ? true : false}
                    helperText={
                      stationAdminEmailEdit ? stationAdminEmailEditTxt : ""
                    }
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6" style={{ marginBottom: "15px" }}>
                  Frequency
                  <br />
                  <TextField
                    id="frequency"
                    defaultValue={stationArr[selectedRow].frequency}
                    variant="filled"
                    inputProps={{
                      style: { width: "100%", color: "#0f1010" },
                    }}
                    type="number"
                    onChange={(event) => {
                      setFreqencyEdit(event.target.value);
                      validateEditFrequency(event.target.value);
                    }}
                    error={frequencyEdit ? true : false}
                    helperText={frequencyEdit ? frequencyEditTxt : ""}
                  />
                </div>
                <div className="col-md-6" style={{ marginBottom: "15px" }}>
                  Destination Path
                  <br />
                  <TextField
                    id="destinationpath"
                    defaultValue={stationArr[selectedRow].destination}
                    variant="filled"
                    inputProps={{
                      style: { width: "100%", color: "#0f1010" },
                    }}
                    onChange={(event) => {
                      setdestinationEdit(event.target.value);
                      validateEditDestination(event.target.value);
                    }}
                    error={destinationPathEdit ? true : false}
                    helperText={
                      destinationPathEdit ? destinationPathEditTxt : ""
                    }
                  />
                </div>
              </div>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-primary text-center"
            style={{ backgroundColor: "#4F3493", borderColor: "#4F3493" }}
            // disabled={disable}
            onClick={() => saveStationEdit()}
          >
            Save
          </button>
        </Modal.Footer>
      </Modal>
      {/* End Modal for edit staion data */}

      {/* Check Station Availability Modal */}
      <Modal
        show={showStationStatus}
        onHide={handleHideStationStatusModal}
        backdrop="static"
        keyboard={false}
        id="oldModal"
        style={{ marginTop: "5%" }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Check Live Station Status </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <table style={{ width: "100%" }}>
              <tbody>
                <tr>
                  <td>
                    Station IP<sup style={{ color: "red" }}>*</sup>
                  </td>
                  <td>
                    <TextField
                      required
                      className="ml-3"
                      /*error={newRegionErr && show_error2 ? true : false}*/
                      id="stationIP"
                      placeholder="Enter Station IP"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) => {
                        setstationStatusIP(e.target.value);
                      }}
                      /*helperText={
          newRegionErr && show_error2
            ? newRegionErrText
            : ""
        }*/
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    Port<sup style={{ color: "red" }}>*</sup>
                  </td>
                  <td>
                    <TextField
                      required
                      className="ml-3"
                      /*error={newRegionErr && show_error2 ? true : false}*/
                      id="stationIP"
                      placeholder="Station Port"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) => {
                        setstationStatusPort(e.target.value);
                      }}
                      /*helperText={
          newRegionErr && show_error2
            ? newRegionErrText
            : ""
        }*/
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="mt-4 alert alert-primary">
              {stationStatusResponse}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="danger"
            onClick={() => {
              checkLiveStationStatus();
            }}
          >
            Check
          </Button>{" "}
        </Modal.Footer>
      </Modal>

      {/* END Check Station Availability Modal */}

      {/* Modal for Station Port Status */}
      <Modal
        show={showPortStatus}
        onHide={() => setshowPortStatus(false)}
        backdrop="static"
        keyboard={false}
        id="oldModal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Configuration Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Call Sign:{" "}
          <em>
            <b>{stationArr[selectedRow]?.call_sign}</b>
          </em>{" "}
          <br />
          Last Status Update:{" "}
          <em>
            <b>
              {new Date(stationArr[selectedRow]?.updatedAt).toLocaleString()}
            </b>
          </em>{" "}
          <br />
          First Station Down Occurrence:{" "}
          <em>
            <b>
              {stationArr[selectedRow]?.firstInactiveAt
                ? new Date(
                    stationArr[selectedRow]?.firstInactiveAt
                  ).toLocaleString()
                : "NA"}
            </b>
          </em>{" "}
          <br />
          <br />
          <Box>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Type</TableCell>
                    <TableCell align="left">Port Number</TableCell>
                    <TableCell align="left">Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>HTTP</TableCell>
                    <TableCell>{stationArr[selectedRow]?.port}</TableCell>
                    <TableCell>
                      {stationArr[selectedRow]?.status ? (
                        <i>
                          <CheckCircleIcon style={{ fill: "green" }} /> Online
                        </i>
                      ) : (
                        <i>
                          <DangerousIcon style={{ fill: "red" }} /> Offline{" "}
                        </i>
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>SSH</TableCell>
                    <TableCell>{stationArr[selectedRow]?.ssh_port}</TableCell>
                    <TableCell>
                      {stationArr[selectedRow]?.status_ssh ? (
                        <i>
                          <CheckCircleIcon style={{ fill: "green" }} /> Online
                        </i>
                      ) : (
                        <i>
                          <DangerousIcon style={{ fill: "red" }} /> Offline{" "}
                        </i>
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Gen4 System</TableCell>
                    <TableCell>{"8022"}</TableCell>
                    <TableCell>
                      {stationArr[selectedRow]?.status_gen4 ? (
                        <i>
                          <CheckCircleIcon style={{ fill: "green" }} /> Online
                        </i>
                      ) : (
                        <i>
                          <DangerousIcon style={{ fill: "red" }} /> Offline{" "}
                        </i>
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>MSAC2 Client</TableCell>
                    <TableCell>{"13002"}</TableCell>
                    <TableCell>
                      {stationArr[selectedRow]?.status_msac2 ? (
                        <i>
                          <CheckCircleIcon style={{ fill: "green" }} /> Online
                        </i>
                      ) : (
                        <i>
                          <DangerousIcon style={{ fill: "red" }} /> Offline{" "}
                        </i>
                      )}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <br />
          <Box>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <b>IP</b> <br />
                    </TableCell>
                    <TableCell align="left"><b>Destination</b></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>{stationArr[selectedRow]?.ip}</TableCell>
                    <TableCell>
                      {stationArr[selectedRow]?.destination}
                    </TableCell>
                  </TableRow>
                </TableBody>
                <TableHead>
                  <TableRow>
                    <TableCell><b>Importer Version</b></TableCell>
                  <TableCell><b>MSAC Version</b></TableCell></TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>{stationArr[selectedRow]?.importer_version}</TableCell>
                  <TableCell>{stationArr[selectedRow]?.station_version}</TableCell></TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <br/>
          </Box>
          {stationArr[selectedRow]?.service_list.length > 0 && (
          <Box>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell><b>Service Name</b></TableCell>
                    <TableCell><b>Schedule Rate Percent</b></TableCell>
                  </TableRow>
                </TableHead>
                {stationArr[selectedRow]?.service_list.map((v) => (
                  <TableRow>
                      <TableCell>{v.serviceName}</TableCell>
                      <TableCell>{v.ScheduleRatePercent}</TableCell>
                  </TableRow>
                )

                )}
              </Table>
            </TableContainer>
            </Box>)}
        </Modal.Body>
      </Modal>
      {/* End Modal for Station Port Status */}

      {/* Modal for Notification Settings Update */}
      <Modal
        show={showNotifSettings}
        onHide={() => {
          setshowNotifSettings(false);
          setshowSnoozeEmailListError(false);
          setshowNewEmailListError(false);
          setShowSnoozeError(false);
        }}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Notification Settings (Call Sign: {notifRow})<br />
            Last Notification Sent:{" "}
            {notificationData[notifRow]?.last_notified_at
              ? new Date(
                  notificationData[notifRow]?.last_notified_at
                ).toUTCString()
              : "NA"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!notificationData[notifRow] && (
            <>
              <p>Notification Settings for this station is not setup yet</p>
              <Button
                style={{ backgroundColor: "#4F3493", borderColor: "#4F3493" }}
                onClick={enableNotificationSettings}
              >
                Enable Notification Settings
              </Button>
            </>
          )}
          {notificationData[notifRow] && (
            <Box>
              <TableContainer component={Paper}>
                <Table>
                  <TableRow>
                    <TableCell>
                      Create / Update Snooze Alerts{" "}
                      <Tooltip title="Check this to enable notification alerts pause for users">
                        <InfoIcon fontSize="small" sx={{ color: "#BDBDBD" }} />
                      </Tooltip>
                    </TableCell>
                    <TableCell colSpan={2}>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={!notifSettingsEnabled}
                              color="secondary"
                              onChange={(e) => {
                                setShowSnoozeError(e.target.checked);
                                setshowSnoozeEmailListError(e.target.checked);
                                setnotifSettingsEnabled(!e.target.checked);
                              }}
                            />
                          }
                          label=""
                        />
                      </FormGroup>
                    </TableCell>
                  </TableRow>
                  {!notifSettingsEnabled && (
                    <TableRow>
                      <TableCell>
                        Emails to Snooze{" "}
                        <Tooltip title="Select emails from list that you want to snooze notifications for">
                          <InfoIcon
                            fontSize="small"
                            sx={{ color: "#BDBDBD" }}
                          />
                        </Tooltip>
                      </TableCell>
                      <TableCell colSpan={2}>
                        <Autocomplete
                          multiple
                          id="tags-filled"
                          clearIcon=""
                          options={notificationData[notifRow]?.mail_list.filter(
                            (v) => {
                              return !notificationData[
                                notifRow
                              ]?.exclude_list.includes(v);
                            }
                          )}
                          onChange={(e, list) => {
                            setshowSnoozeEmailListError(false);
                            setnotifSettingsExcludeList(
                              Array.from(
                                new Set([
                                  ...notificationData[notifRow]?.exclude_list,
                                  ...list,
                                ])
                              )
                            );
                          }}
                          defaultValue={[]}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Add multiple emails here"
                            />
                          )}
                        />
                      </TableCell>
                    </TableRow>
                  )}
                  <div style={{ color: "red", width: "max-content" }}>
                    {showSnoozeEmailListError
                      ? "Please enter new email(s) to snooze"
                      : ""}
                  </div>
                  {!notifSettingsEnabled && (
                    <TableRow>
                      <TableCell>
                        Snooze For (Hours){" "}
                        <Tooltip title="Enter Hours that you want the notifications to be paused for (minimum: 1 hour)">
                          <InfoIcon
                            fontSize="small"
                            sx={{ color: "#BDBDBD" }}
                          />
                        </Tooltip>
                      </TableCell>
                      <TableCell colSpan={2}>
                        <TextField
                          type="number"
                          defaultValue={notifSettingsSnooze}
                          InputProps={{
                            inputProps: {
                              inputMode: "numeric",
                              min: 0,
                              pattern: "[0-9]*",
                            },
                          }}
                          onChange={(e) => {
                            setShowSnoozeError(false);
                            setnotifSettingsSnooze(parseInt(e.target.value));
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  )}
                  <div style={{ color: "red", width: "max-content" }}>
                    {showSnoozeError ? "Please enter value in field" : ""}
                  </div>
                  <TableRow>
                    <TableCell>
                      Notifications Subscribed for{" "}
                      <Tooltip title="List of emails that receive notifications">
                        <InfoIcon fontSize="small" sx={{ color: "#BDBDBD" }} />
                      </Tooltip>
                    </TableCell>
                    <TableCell>
                      {notificationData[notifRow]?.mail_list.map((v) => (
                        <Chip
                          label={v}
                          key={v}
                          className="m-1"
                          onDelete={
                            notificationData[notifRow]?.admin_email !== v
                              ? () => deleteNotificationPause(v, 2)
                              : null
                          }
                          deleteIcon={
                            <DeleteIcon
                              fontSize="small"
                              style={{ cursor: "pointer" }}
                            />
                          }
                          variant="outlined"
                        />
                      ))}
                    </TableCell>
                    <TableCell>
                      {showAddEmailNotifications ? (
                        <PersonAddIcon
                          fontSize="large"
                          onClick={() => setshowAddEmailNotifications(false)}
                          style={{ cursor: "pointer", color: "#FF3F7F" }}
                        />
                      ) : (
                        <CloseIcon
                          fontSize="large"
                          onClick={() => setshowAddEmailNotifications(true)}
                          style={{ cursor: "pointer", color: "#FF3F7F" }}
                        />
                      )}
                    </TableCell>
                  </TableRow>
                  {!showAddEmailNotifications && (
                    <TableRow>
                      <TableCell>
                        New User Emails{" "}
                        <Tooltip title="Users who should receive notification alerts on station downtime">
                          <InfoIcon
                            fontSize="small"
                            sx={{ color: "#BDBDBD" }}
                          />
                        </Tooltip>
                      </TableCell>
                      <TableCell colSpan={2}>
                        <Autocomplete
                          multiple
                          id="tags-filled"
                          clearIcon=""
                          options={[]}
                          autoSelect={true}
                          onChange={(e, list) => {
                            setshowNewEmailListError(false);
                            setnotifSettingsMailList(
                              Array.from(
                                new Set([
                                  ...notificationData[notifRow]?.mail_list,
                                  ...list,
                                ])
                              )
                            );
                          }}
                          defaultValue={[]}
                          freeSolo
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Add multiple emails here"
                            />
                          )}
                        />
                      </TableCell>
                    </TableRow>
                  )}
                  <div style={{ color: "red", width: "max-content" }}>
                    {showNewEmailListError
                      ? "Please Enter valid Email in list"
                      : ""}
                  </div>
                  <TableRow>
                    <TableCell>
                      Notification Interval (minutes){" "}
                      <Tooltip title="Minutes gap between each notification applies for all users(minimum: 5 and maximum: 1440)">
                        <InfoIcon fontSize="small" sx={{ color: "#BDBDBD" }} />
                      </Tooltip>
                    </TableCell>
                    <TableCell>
                      <TextField
                        style={{ width: "100%" }}
                        type="number"
                        defaultValue={notifSettingsInterval}
                        onChange={(e) => {
                          setshowNotifIntervalError(false);
                          setnotifSettingsInterval(e.target.value);
                        }}
                        InputProps={{ inputProps: { min: 5, max: 1440 } }}
                        disabled={showEditNotifInterval}
                      />
                    </TableCell>
                    <TableCell>
                      {showEditNotifInterval ? (
                        <EditIcon
                          fontSize="large"
                          onClick={() => setshowEditNotifInterval(false)}
                          style={{ cursor: "pointer", color: "#FF3F7F" }}
                        />
                      ) : (
                        <CloseIcon
                          fontSize="large"
                          onClick={() => setshowEditNotifInterval(true)}
                          style={{ cursor: "pointer", color: "#FF3F7F" }}
                        />
                      )}
                    </TableCell>
                  </TableRow>
                  <div style={{ color: "red", width: "max-content" }}>
                    {showNotifIntervalError
                      ? "Interval should be between 5 to 1440"
                      : ""}
                  </div>
                </Table>
              </TableContainer>
            </Box>
          )}
          <br />
          <br />
          {notificationData[notifRow] &&
            notificationData[notifRow]?.exclude_list.length !== 0 && (
              <Box>
                <h3>Snooze Settings</h3>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>User</TableCell>
                        <TableCell>Paused At</TableCell>
                        <TableCell>Resume At</TableCell>
                        <TableCell>Cancel</TableCell>
                      </TableRow>
                    </TableHead>
                    {notificationData[notifRow]?.exclude_list_config.map(
                      (v) => (
                        <TableRow>
                          <TableCell>{v.user}</TableCell>
                          <TableCell>
                            {new Date(v.paused_at).toUTCString()}
                          </TableCell>
                          <TableCell>
                            {new Date(v.resume_at).toUTCString()}
                          </TableCell>
                          <TableCell>
                            <DeleteIcon
                              fontSize="medium"
                              style={{ cursor: "pointer", color: "#BDBDBD" }}
                              onClick={() => deleteNotificationPause(v.user, 1)}
                            />
                          </TableCell>
                        </TableRow>
                      )
                    )}
                  </Table>
                </TableContainer>
              </Box>
            )}
        </Modal.Body>
        {notificationData[notifRow] && (
          <Modal.Footer>
            <Button
              onClick={saveNotifSettingsUpdate}
              style={{ backgroundColor: "#4F3493", borderColor: "#4F3493" }}
            >
              Save Changes
            </Button>
          </Modal.Footer>
        )}
      </Modal>

      {/* End Modal for  Notification Settings Update*/}
    </div>
  );
}
