import React from 'react'

const PageNotFound = () => {
    return (
        <div id="wrapper" align="center" style={{width:"100%",position:'relative'}}>
            <img src={window.location.origin + "/img/black.png"} className="logo" alt="" style={{position:'absolute',top:'2%',left:"2%"}}/>
            <img src={window.location.origin + "/img/404.jpg"} alt="" style={{width:'100%',height:'725px'}} />
            <div id="info" style={{position:'absolute',bottom:'15%',right:"5%"}}>
                <button style={{width:'100%'}} className="btn btn-danger btn-lg btn-block" onClick={() => window.location.replace('/')}>Go To Home</button>
            </div>

            <div className="bg-blue py-4" style={{position:'absolute',bottom:'0px',width:'100%',height:'8%'}}>
                  Copyright Xperi © 2021. All rights reserved.
                
              
            </div>
        </div >
    )
}

export default PageNotFound