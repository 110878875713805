import React, { useState } from "react";
import Multiselect from "multiselect-react-dropdown";
import { Button } from "react-bootstrap";
import {Box, CircularProgress} from "@material-ui/core";

function RegionSelect(props) {
  const { selectOptions, setselectValuesList, isAdding, handleClick } = props;
  // eslint-disable-next-line
  const [isMobile, setisMobile] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);

  const onSelect = (selectedList, selectedItem) => {
        setselectValuesList(selectedList);
  };
  const onRemove = () => {};


  return (
    <div>
      <div className="container">
        <div className="row">
          <div
            className={width >= 760 && width < 790 ? "col-md-6" : "col-md-8"}
          >
            <Multiselect
              options={selectOptions} // Options to display in the dropdown
              onSelect={onSelect} // Function will trigger on select event
              onRemove={onRemove} // Function will trigger on remove event
              displayValue="name" // Property name to display in the dropdown options
              placeholder={
                selectOptions.length === 0
                  ? "Fetching Data......"
                  : "Select Region"
              }
            />
          </div>
          <div
            className={width >= 760 && width < 790 ? "col-md-6" : "col-md-4"}
            style={isMobile ? { marginTop: "3px" } : {}}
          >
            <Button
              variant="primary"
              size="lg"
              disabled={selectOptions.length === 0 || isAdding}
              onClick={!isAdding ? handleClick : null}
              style={{ height: "100%", borderColor: "#4F3493", backgroundColor: "#4F3493" }}
            >
              {isAdding ? "Adding…" : "Click to Add"}
            </Button>
            &nbsp;
            {selectOptions.length === 0 && (
              <Box
                position="relative"
                display="inline-flex"
                style={{ top: "10px", left: "14px" }}
              >
                <CircularProgress />
              </Box>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default RegionSelect;
