import { React, useState, useEffect } from "react";
import Alerts from "./components/alerts/alert";
import BaseConfirmRegisterForm from "./components/forms/baseConfirmRegisterForm";
import ApiClient from "./apis/apiClient";
import VerifyEmail from "./components/register/verifyEmail";
import Auth from "@aws-amplify/auth";
import RegisterMFA from "./components/register/registerMFA";
import FinalPage from "./components/register/finalPage";
import Footer from "./components/footer/footer";
import registerCognitoUser from "./components/register/registerCognito";
import Loader from "./components/loader/loader"
import SweetAlert from "react-bootstrap-sweetalert";
import logout from "./components/validations/logoutUser";

export default function Confirm() {
  const [email, setEmail] = useState(null);
  const [company, setCompany] = useState(null);
  const [status, setStatus] = useState(0);
  const [alertType, setAlertType] = useState("success");
  const [confirmRegClicked, setConfirmClicked] = useState(0);
  const [password, setPassword] = useState(null);
  const [confirmRegFailed, setRegFailed] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [name, setName] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [stage, setStage] = useState(0);
  const [message, setMessage] = useState(null);
  const [getMFA, setGetMFA] = useState(false);
  const [MFACode, setMFACode] = useState(null);
  const [doLogin, setDoLogin] = useState(false);

  const [alertMessage, setAlertMessage] = useState("");
  const [alert, setAlert] = useState(false);
  const [severity, setSeverity] = useState("default");
  const [alertTitle, setAlertTitle] = useState("");
  const [confirmAction, setConfirmAction] = useState("");
  const [hrefLink, setHrefLink] = useState("");

  const [loading, setloading] = useState(true);
  const [loaderType, setLoaderType] = useState("CircularProgress");
  const [percentage, setpercentage] = useState(0);

  useEffect(() => {
    getUserData();
  }, []);

  useEffect(() => {
    if (company != null) addNewUserToDB();
  }, [confirmRegClicked]);
  useEffect(() => {
    ////console.log("Inside mfa screen");
  }, [getMFA]);
  const addNewUserToDB = async () => {
    ////console.log(email+"jjjj")
    registerCognitoUser(
      name,
      email,
      phoneNumber,
      password,
      company,
      setStage,
      setStatus,
      setMessage,
      setAlertType,
      setRegFailed,
      Auth
    );
  };
  const getUserData = async () => {
    const token = new URLSearchParams(window.location.search).get("token");
    let result = await ApiClient("get", "/getUserFromToken", "token=" + token);
    if (result.data.status === 200) {
      setloading(false);
      setName(result.data.name);
      setPhoneNumber(result.data.phone);
      setEmail(result.data.email);
      setCompany(result.data.company);
      ////console.log(result.data)
    } else if (result.data.status === 422) {
      setloading(false);
      setAlert(true);
      setSeverity("error");
      setAlertMessage("Missing required param");
      setAlertTitle("Something Went Wrong");
      setConfirmAction("");
      setHrefLink("");
    } else if (result.data.status === 404) {
      setloading(false);
      setAlert(true);
      setSeverity("error");
      setAlertMessage("User fetch failed: Token Invalid");
      setAlertTitle("Something Went Wrong");
      setConfirmAction("");
      setHrefLink("");
    } else {
      setloading(false);
      setAlert(true);
      setSeverity("error");
      setAlertMessage("Some Error Occured");
      setAlertTitle("Something Went Wrong");
      setConfirmAction("");
      setHrefLink("");
    }
  };
  const confirmAlert = () => {
    setAlert(false);
    setSeverity("default");
    setAlertTitle("");
    if (confirmAction === "load") {
      window.location.reload();
    } else if (confirmAction === "href") {
      window.location.href = hrefLink;
    } else if (confirmAction === "logout") {
      logout()
    }
  }
  return (
    <div>
      <div className="container-fluid px-1 px-md-5 px-lg-1 px-xl-5 py-5 mx-auto">
        <div className="card card0 border-0">
          {alert && (
            <SweetAlert type={severity} title={alertTitle} onConfirm={confirmAlert}>
              {alertMessage}
            </SweetAlert>
          )}
          {status === 1 && (
            <Alerts
              setStatus={setStatus}
              alertType={alertType}
              message={message}
            ></Alerts>
          )}
          <Loader loading={loading} loaderType={loaderType} percentage={percentage}></Loader>
          <div className="row d-flex">
            <div className="col-lg-6">
              <div className="card1">
                {/*<div className="row">
                      {" "}
                      <img
                        src={window.location.origin + "/img/black.png"}
                        className="logo"
                        alt=""
                      />{" "}
                    </div>
              */}
                <div className="row justify-content-center">
                  {" "}
                  <img
                    src={window.location.origin + "/img/banner.jpg"}
                    className="image"
                    alt=""
                    style={{ width: "100%", height: "725px" }}
                  />{" "}
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="card2 card border-0 px-4 py-5">
                {stage === 0 && !doLogin && (
                  <BaseConfirmRegisterForm
                    userEmail={email}
                    userCompany={company}
                    userName={name}
                    userPhone={phoneNumber}
                    confirmRegClicked={confirmRegClicked}
                    setConfirmClicked={setConfirmClicked}
                    setPassword={setPassword}
                    confirmRegFailed={confirmRegFailed}
                    setConfirm={setConfirm}
                  />
                )}
                {stage === 1 && !doLogin && !getMFA && (
                  <VerifyEmail
                    email={email}
                    password={password}
                    setGetMFA={setGetMFA}
                    setAlertType={setAlertType}
                    setMFACode={setMFACode}
                    setMessage={setMessage}
                  />
                )}
                {getMFA && (
                  <RegisterMFA
                    MFACode={MFACode}
                    setDoLogin={setDoLogin}
                    email={email}
                    setGetMFA={setGetMFA}
                    setStatus={setStatus}
                    setMessage={setMessage}
                    setAlertType={setAlertType}
                  />
                )}
                {doLogin && <FinalPage />}
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
}
