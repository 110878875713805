import React from "react";
import { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
//import DataTable from "react-data-table-component";
import DataTable from "../DataTable/dataTable";
import { Formik, Form, Field } from "formik";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import DisplaySettingsIcon from "@mui/icons-material/DisplaySettings";
import {
  ListItemText,
  Checkbox,
  FormControlLabel,
  FormLabel,
  Tooltip,
} from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ImportExportIcon from "@material-ui/icons/ImportExport";
import Auth from "@aws-amplify/auth";
import Cookies from "js-cookie";
import logout from "../validations/logoutUser";
import axios from "axios";
import LinearProgress from "@material-ui/core/LinearProgress";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { API_ENDPOINT_URL } from "../../constants/constants";
import checkPageAccess from "../others/getPageAccess";
import ApiClient from "../../apis/apiClient";
import SweetAlert from "react-bootstrap-sweetalert";
import Loader from "../loader/loader";
import encryptHash from "../validations/general";

export default function AdminCompanySection() {
  const [email, setemail] = useState("");
  const [address_error, setaddress_error] = useState(
    "Please Enter your Company Address"
  );
  const [register_error, setregister_error] = useState("Please Enter a Number");
  const [whitelist_error, setwhitelist_error] = useState(
    "Please Enter a Number"
  );
  const [companyname, setcompanyname] = useState("");
  const [companyid, setcompanyid] = useState("");
  const [add_val, setadd_val] = useState("");
  const [add_company, setadd_company] = useState(false);
  const [registerval, setregisterval] = useState(0);
  const [whitelistval, setwhitelistval] = useState(0);
  const [data, setdata] = useState([]);
  const [stationsData, setstationsData] = useState([]);
  const [userAuthToken, setuserAuthToken] = useState(null);
  const [tableLoader, settableLoader] = useState(true);
  const [admin_email, setadmin_email] = useState("");
  const [xperi_fae_email, setxperi_fae_email] = useState("");
  const [stationAddCompanyName, setstationAddCompanyName] = useState("");
  const [stationsToAdd, setstationsToAdd] = useState([]);
  const [allocateRemove, setallocateRemove] = useState("allocate");
  const [showDataserviceModal, setshowDataserviceModal] = useState(false);
  const [idx, setidx] = useState(null);
  const [servicesData, setservicesData] = useState([]);
  const [companydataservicesList, setcompanydataservicesList] = useState([]);
  const [selectedList, setselectedList] = useState({});
  const [loading, setloading] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alert, setAlert] = useState(false);
  const [severity, setSeverity] = useState("default");
  const [alertTitle, setAlertTitle] = useState("");
  const [confirmAction, setConfirmAction] = useState("");
  const [hrefLink, setHrefLink] = useState("");
  const [loaderType, setLoaderType] = useState("CircularProgress");
  const [percentage, setpercentage] = useState(0);
  const [showFramingAlert, setshowFramingAlert] = useState(false);
  const [framingAnswer, setframingAnswer] = useState(false);


  const access_levels = Cookies.get("t") ? Cookies.get("t").split(",") : []; 

  const cols = [
    {
      label: "Company ID",
      name: "companyId",
    },
    {
      label: "Company Name",
      name: "companyName",
    },
    {
      label: "Company Address",
      name: "companyAddress",
    },
    {
      label: "Admin Email",
      name: "adminEmail",
    },
    {
      label: "FAE Email",
      name: "faeEmail",
    },
    {
      label: "Total Registered Users",
      name: "totalRegisteredUsers",
    },
    {
      label: "Total Approved",
      name: "totalApproved",
    },
    {
      label: "Stations",
      name: "stations",
    },
    {
      name: "Framing Enabled",
      options: {
        filter: false,
        display: access_levels.indexOf(encryptHash("777")) !== -1,
        customBodyRender: (value, tableMeta, updateValue) => (
          <center>
            <Tooltip title="Click to Enable Message Framing" arrow>
              <Checkbox
                checked={data[tableMeta.rowIndex].framing}
                color="secondary"
                onClick={(e) => {
                  setidx(tableMeta.rowIndex);
                  setshowFramingAlert(true);
                  setframingAnswer(e.target.checked);
                }}
              />
            </Tooltip>
          </center>
        ),
      },
    },
    {
      name: "Update Dataservice",
      options: {
        filter: false,
        //hint: "Shows the current state of the station (use this to log off the station)",
        customBodyRender: (value, tableMeta, updateValue) => (
          <center>
            <Tooltip title="Click to update Data Services" arrow>
              <DisplaySettingsIcon
                onClick={() => {
                  setshowDataserviceModal(true);
                  setidx(tableMeta.rowIndex);
                }}
              />
            </Tooltip>
          </center>
        ),
      },
    },
  ];

  const handleCompNameChange = (e) => {
    setstationAddCompanyName(e.target.value);
  };
  const handleStationsToAddChange = (e) => {
    setstationsToAdd(e.target.value);
  };

  const updateFraming = async () => {
    setshowFramingAlert(false);
    setloading(true);
    let resp = await ApiClient(
      "post",
      "/admin/updateCompanyFraming",
      {
        company_id: data[idx].companyId,
        do_framing: framingAnswer,
      },
      {
        "Content-Type": "application/json",
        authorization: Cookies.get("auth"),
      }
    );
    if (resp.data.status === 200) {
      setloading(false);
      setAlert(true);
      setSeverity("success");
      setAlertMessage(resp.data.message);
      setAlertTitle("Success");
      setConfirmAction("load");
      setHrefLink("");
      setshowFramingAlert(false);
    } else {
      setAlert(true);
      setSeverity("error");
      setAlertMessage("Some Error Occurred " + resp.data.message);
      setAlertTitle("Something Went Wrong");
      setConfirmAction("");
      setHrefLink("");
      setshowFramingAlert(false);
    }
  };

  const addCompanyStations = async () => {
    setloading(true);
    if (stationAddCompanyName.length === 0 || stationsToAdd.length === 0) {
      alert("Please Select both company and stations ");
      return;
    }
    const resp = await ApiClient(
      "post",
      "/admin/addRemoveStationsToCompany",
      {
        company: stationAddCompanyName,
        stations: stationsToAdd,
        option: allocateRemove,
      },
      {
        Authorization: Cookies.get("auth"),
      }
    );
    if (resp.data.status === 200) {
      setloading(false);
      setAlert(true);
      setSeverity("success");
      setAlertMessage(resp.data.message);
      setAlertTitle("Success");
      setConfirmAction("load");
      setHrefLink("");
    } else if (resp.data.status === 403) {
      setloading(false);
      setAlert(true);
      setSeverity("error");
      setAlertMessage("Session Expired Please Relogin ");
      setAlertTitle("Relogin");
      setConfirmAction("logout");
    } else if (resp.data.status === 422) {
      setloading(false);
      setAlert(true);
      setSeverity("error");
      setAlertMessage("Something Went Wrong ");
      setAlertTitle("Error");
    } else {
      setloading(false);
      setAlert(true);
      setSeverity("error");
      setAlertMessage("Something Went Wrong ");
      setAlertTitle("Error");
    }
  };
  const addCompany = async () => {
    setloading(true);
    const response = await ApiClient(
      "post",
      "/admin/addCompanyData/",
      {
        company_id: companyid,
        company_name: companyname,
        company_address: add_val,
        admin_email: admin_email,
        fae_email: xperi_fae_email,
      },
      {
        "Content-Type": "application/json",
        Authorization: Cookies.get("auth"),
      }
    );
    if (response.data.status == 200) {
      setloading(false);
      setAlert(true);
      setSeverity("success");
      setAlertMessage(response.data.message);
      setAlertTitle("Success");
      setConfirmAction("load");
      setHrefLink("");
    } else if (response.data.status === 403) {
      setloading(false);
      setAlert(true);
      setSeverity("error");
      setAlertMessage("Session Expired Please Relogin ");
      setAlertTitle("Relogin");
      setConfirmAction("logout");
    } else if (response.data.status === 422) {
      setloading(false);
      setAlert(true);
      setSeverity("error");
      setAlertMessage("Something Went Wrong ");
      setAlertTitle("Error");
    } else {
      setloading(false);
      setAlert(true);
      setSeverity("error");
      setAlertMessage(
        "Something Went Wrong " + response.data.message ||
          response.data.result ||
          response.data.response
      );
      setAlertTitle("Error");
    }
  };
  const validateUser = () => {
    let getauth = async () => {
      return (await Auth.currentSession()).getAccessToken().getJwtToken();
    };
    getauth()
      .then((res) => {
        axios
          .get(API_ENDPOINT_URL + "/admin/companies/", {
            headers: {
              Authorization: Cookies.get("auth"),
            },
          })
          .then((res) => {
            if (res.data.status === 200) {
              setdata(res.data.result);
              setservicesData(res.data.services_data);
              settableLoader(false);
            } else {
              //console.log("Error In data fetch",res.data.status,res.data.result)
              setloading(false);
              setAlert(true);
              setSeverity("error");
              setAlertMessage("Session Expired Please Relogin ");
              setAlertTitle("Relogin");
              setConfirmAction("logout");
            }
          });
      })
      .catch((err) => {
        setuserAuthToken(null);
        window.location.replace("/");
      });
  };
  useEffect(async () => {
    document.title = "Companies | Xperi HD Radio IoT";
    if (!Cookies.get("auth")) {
      Auth.signOut().then((res) => {
        //console.log(res);
        validateUser();
      });
      //console.log("Logged out");
    } else {
      setuserAuthToken(Cookies.get("auth"));
      validateUser();
      let isValid = await checkPageAccess();
      if (!isValid) {
        window.location.href = "/unauthorized";
      } else {
        //console.log("You have access");
        let resp = await ApiClient("get", "/app/stations", "", {
          "Content-Type": "application/json",
          authorization: Cookies.get("auth"),
        });

        if (resp.data.status === 200) {
          setstationsData(resp.data.result);
        } else if (resp.data.status === 403) {
          setloading(false);
          setAlert(true);
          setSeverity("error");
          setAlertMessage("Session Expired Please Relogin ");
          setAlertTitle("Relogin");
          setConfirmAction("logout");
        } else {
          alert("No Records");
        }
      }
    }
  }, []);

  useEffect(() => {
    if (data.length > 0) {
      let temp = {};
      for (let x of data) {
        temp[x["companyId"]] = {};
        x.stations.split(",").map((v) => {
          temp[x["companyId"]][v.trim()] = "";
        });
      }
      setcompanydataservicesList(temp);
    }
  }, [data]);

  useEffect(() => {
    if (showDataserviceModal) {
      let x = data[idx]?.stations.split(",");
      let dservices = data[idx]?.dataServices;
      console.log("dserv", dservices);
      let temp = {};
      for (let y of x)
        temp[y.trim()] = dservices ? dservices[y.trim()] || "None" : "None";
      setselectedList({ ...temp });
    }
  }, [showDataserviceModal]);

  const handleChangeDataServicesList = (val, call_sign) => {
    //console.log("called_");
    let temp = Object.assign({}, selectedList);
    temp[call_sign] = val;
    setselectedList(Object.assign({}, temp));
  };

  const validateCompanyname = (value) => {
    let error;
    if (!value || value.length < 2) {
      error = "Please Enter A Company Name";
    } else {
      setcompanyname(value);
    }
    ////console.log(error);
    return error;
  };
  const validateCompanyId = (value) => {
    ////console.log("vvvv"+value)
    let error;

    if (!value || value.length < 2) {
      error = "Please Enter A Company Id";
    } else {
      setcompanyid(value);
    }
    ////console.log(error);
    return error;
  };

  const validateAdminEmail = (value) => {
    let error;
    if (!value) {
      error = "Field is mandatory";
    } else {
      setadmin_email(value);
    }
    return error;
  };
  const validateFaeEmail = (value) => {
    let error;
    if (!value) {
      error = "Field is mandatory";
    } else {
      setxperi_fae_email(value);
    }
    return error;
  };
  const addressValidation = (e) => {
    //console.log(e.target.value)
    ////console.log(add_val)
    var addrs = e.target.value;
    if (!addrs.trim()) {
      setaddress_error("Please enter your company address");
      setadd_company(true);
    } else if (addrs.length < 10) {
      setaddress_error("Invalid Address");
      setadd_company(true);
    } else {
      setaddress_error("");
      setadd_company(false);
      setadd_val(addrs);
    }

    setadd_val(e.target.value);

    ////console.log(error);
  };
  const handleAllocateRemoveChange = (e) => {
    setallocateRemove(e.target.value);
  };

  const updateCompanyDataServiceDB = async () => {
    //console.log(data[idx], companydataservicesList[data[idx].companyId], selectedList);
    setloading(true);
    let resp = await ApiClient(
      "post",
      "/admin/updateCompanyDataService",
      {
        company_id: data[idx].companyId,
        selected_list: selectedList,
      },
      {
        "Content-Type": "application/json",
        authorization: Cookies.get("auth"),
      }
    );
    if (resp.data.status === 200) {
      setloading(false);
      setAlert(true);
      setSeverity("success");
      setAlertMessage(resp.data.message);
      setAlertTitle("Success");
      setConfirmAction("load");
      setHrefLink("");
      setshowDataserviceModal(false);
      setselectedList({});
    } else {
      setAlert(true);
      setSeverity("error");
      setAlertMessage("Some Error Occurred " + resp.data.message);
      setAlertTitle("Something Went Wrong");
      setConfirmAction("");
      setHrefLink("");
    }
  };

  useEffect(() => {
    console.log(companydataservicesList);
  }, [companydataservicesList]);

  const confirmAlert = () => {
    setAlert(false);
    setSeverity("default");
    setAlertTitle("");
    if (confirmAction === "load") {
      window.location.reload();
    } else if (confirmAction === "href") {
      console.log("inside conf href");
      window.location = hrefLink;
    } else if (confirmAction === "logout") {
      //console.log('lg h');
      logout();
    }
  };

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 48 * 4.5 + 2,
        width: 250,
      },
    },
  };

  return (
    <div>
      {alert && (
        <SweetAlert type={severity} title={alertTitle} onConfirm={confirmAlert}>
          {alertMessage}
        </SweetAlert>
      )}
      {showFramingAlert && (
        <SweetAlert
          warning
          showCancel
          confirmBtnText="Yes, Update It!"
          confirmBtnBsStyle="danger"
          title="Are you sure you want to enable / disable framing?"
          onConfirm={() => updateFraming()}
          onCancel={() => setshowFramingAlert(false)}
          focusCancelBtn
        >
          We will apply changes on confirmation.
        </SweetAlert>
      )}
      <Loader
        loading={loading}
        loaderType={loaderType}
        percentage={percentage}
      ></Loader>
      {userAuthToken !== null && (
        <div>
          <div
            className="row d-flex justify-content-center mt-100"
            style={{ marginTop: "100px" }}
          >
            <div className="col-md-10">
              <div className="card" style={{ padding: "10px" }}>
                <h5>List Of Companies</h5>

                {tableLoader === true && (
                  <Box display="flex" alignItems="center">
                    <Box width="100%" mr={1}>
                      <LinearProgress />
                    </Box>
                    <Box minWidth={35}>
                      <Typography variant="body2" color="textSecondary">
                        Loading Table...
                      </Typography>
                    </Box>
                  </Box>
                )}
                {tableLoader === false && (
                  <DataTable
                    tableData={data}
                    tableColumns={cols}
                    tableOptions={{
                      filter: false,
                      filterType: "dropdown",
                      print: false,
                      search: true,
                      selectableRows: "none",
                      download: false,
                      viewColumns: false,
                      responsive: "standard",
                      fixedHeader: false,
                      // onRowsDelete: (event, rowData) => {
                      //   handleClickedDeleteButton(event, rowData);
                      // },
                      //selectableRows:'single',
                    }}
                  />
                )}
                <br />
                <br />
              </div>
            </div>
          </div>
          <div
            className="row d-flex justify-content-center mt-100"
            style={{ marginTop: "25px" }}
          >
            <div className="col-md-10">
              <div className="card" style={{ padding: "10px" }}>
                <div className="container">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-8">
                          <h5>Add Company</h5>
                        </div>
                        <div className="col-md-4" align="right">
                          <Button
                            style={{
                              backgroundColor: "#666666",
                              borderColor: "#666666",
                            }}
                            color="primary"
                            size="medium"
                            fullwidth="false"
                          >
                            Upload as CSV <ImportExportIcon />
                          </Button>
                        </div>
                      </div>
                    </div>
                    <br />
                    <br />
                    <Formik
                      initialValues={{
                        companyid: "",
                        companyname: "",
                        address: "",
                        adminname: "",
                        adminemail: "",
                        faeemail: "",
                      }}
                      onSubmit={addCompany}
                    >
                      {({ errors, touched }) => (
                        <Form>
                          <div className="row d-flex">
                            <div className="col-lg-6">
                              <div className="row px-3">
                                <label className="mb-1">
                                  <h6 className="mb-0 text-sm">Company Id</h6>
                                </label>
                                <Field
                                  className="mb-4"
                                  type="text"
                                  name="companyid"
                                  validate={validateCompanyId}
                                />
                                {errors.companyname && touched.companyname && (
                                  <div className="invalid-feedback d-block">
                                    {errors.companyname}
                                  </div>
                                )}{" "}
                              </div>
                              <div className="row px-3">
                                <label className="mb-1">
                                  <h6 className="mb-0 text-sm">Company Name</h6>
                                </label>
                                <Field
                                  className="mb-4"
                                  type="text"
                                  name="companyname"
                                  validate={validateCompanyname}
                                />
                                {errors.companyname && touched.companyname && (
                                  <div className="invalid-feedback d-block">
                                    {errors.companyname}
                                  </div>
                                )}{" "}
                              </div>
                              <div className="row px-3">
                                <label className="mb-1">
                                  <h6 className="mb-0 text-sm">Admin Email</h6>
                                </label>
                                <Field
                                  className="mb-4"
                                  type="email"
                                  name="adminemail"
                                  validate={validateAdminEmail}
                                />
                                {errors.companyname && touched.companyname && (
                                  <div className="invalid-feedback d-block">
                                    {errors.companyname}
                                  </div>
                                )}{" "}
                              </div>
                              <div className="row px-3">
                                <label className="mb-1">
                                  <h6 className="mb-0 text-sm">
                                    Xperi FAE Email
                                  </h6>
                                </label>
                                <Field
                                  className="mb-4"
                                  type="email"
                                  name="faeemail"
                                  validate={validateFaeEmail}
                                />
                                {errors.faeemail && touched.faeemail && (
                                  <div className="invalid-feedback d-block">
                                    {errors.faeemail}
                                  </div>
                                )}{" "}
                              </div>
                              <div className="row px-3">
                                <label className="mb-1">
                                  <h6 className="mb-0 text-sm">
                                    Company Address
                                  </h6>
                                </label>
                                <textarea
                                  className="form-control"
                                  id="address"
                                  rows="3"
                                  onChange={(e) => {
                                    addressValidation(e);
                                  }}
                                ></textarea>{" "}
                                {address_error !== "" && add_company && (
                                  <div className="invalid-feedback d-block">
                                    {address_error}
                                  </div>
                                )}
                              </div>
                              <br />
                              <div className="mb-3"></div>
                              <div className="row  mb-3 px-3">
                                {" "}
                                <button
                                  style={{
                                    backgroundColor: "#4F3493",
                                    borderColor: "#4F3493",
                                  }}
                                  type="submit"
                                  className="btn btn-primary text-center"
                                  onClick={(add_company) =>
                                    setadd_company(true)
                                  }
                                >
                                  Save
                                </button>{" "}
                              </div>
                            </div>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
              <br />
            </div>
          </div>

          <div
            className="row d-flex justify-content-center"
            style={{ marginTop: "2px" }}
          >
            <div className="col-md-10">
              <div className="card" style={{ padding: "10px" }}>
                <div className="container">
                  <div className="row">
                    <div className="col-md-12">
                      <h5>Allocate / Remove Stations for Company</h5>
                    </div>
                    <div className="row px-3">
                      <div className="col-lg-6 m-2">
                        <FormControl style={{ width: "100%" }}>
                          <InputLabel>Company ID</InputLabel>
                          <Select
                            value={stationAddCompanyName}
                            onChange={handleCompNameChange}
                            input={
                              <OutlinedInput
                                size="small"
                                margin="dense"
                                label="Company Id"
                              />
                            }
                          >
                            {data.map((v) => (
                              <MenuItem
                                className="d-block p-2"
                                key={v.companyId}
                                value={v.companyId}
                              >
                                {v.companyId}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                    <div className="row px-3">
                      <div className="col-lg-6 m-2">
                        <FormControl style={{ width: "100%" }}>
                          <InputLabel>Stations</InputLabel>
                          <Select
                            multiple
                            value={stationsToAdd}
                            renderValue={(selected) => selected.join(", ")}
                            onChange={handleStationsToAddChange}
                            MenuProps={MenuProps}
                            input={
                              <OutlinedInput
                                size="small"
                                margin="dense"
                                label="Stations"
                              />
                            }
                          >
                            {stationsData &&
                              stationsData.map((v) => (
                                <MenuItem
                                  className="d-block p-2"
                                  key={v.call_sign}
                                  value={v.call_sign}
                                >
                                  <Checkbox
                                    checked={
                                      stationsToAdd.indexOf(v.call_sign) > -1
                                    }
                                  />
                                  <ListItemText
                                    primary={v.name + " - " + v.call_sign}
                                  />
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <FormControl
                          sx={{ marginTop: 3, marginLeft: 1, width: 300 }}
                        >
                          <FormLabel id="form-control-allocate-remove-placement">
                            Allocate or Remove
                          </FormLabel>
                          <RadioGroup
                            row
                            aria-labelledby="form-control-allocate-remove-placement"
                            name="allocateremove"
                            defaultValue="allocate"
                            onChange={handleAllocateRemoveChange}
                          >
                            <FormControlLabel
                              value="allocate"
                              control={<Radio />}
                              label="Allocate"
                            />
                            <FormControlLabel
                              value="remove"
                              control={<Radio />}
                              label="Remove"
                            />
                          </RadioGroup>
                        </FormControl>
                      </div>
                    </div>
                    <div className="col-lg-6 mb-3 px-3">
                      <button
                        className="btn btn-primary mt-3 "
                        style={{
                          backgroundColor: "#4F3493",
                          borderColor: "#4F3493",
                          width: "100%",
                        }}
                        onClick={addCompanyStations}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <br />
          <br />
          <br />
        </div>
      )}
      {userAuthToken === null && (
        <div>
          <center>
            <div>
              <span>
                <h1>Session Expired !!!... Company</h1>
              </span>
            </div>
          </center>
        </div>
      )}
      {/* Modal for Notification Settings Update */}
      <Modal
        show={showDataserviceModal}
        onHide={() => {
          setshowDataserviceModal(false);
          setselectedList({});
        }}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Allocate Dataservices to company</Modal.Title>
        </Modal.Header>
        {Object.entries(selectedList).length > 0 && (
          <Modal.Body>
            {data[idx]?.stations.split(",").map((v) => (
              <div className="d-flex justify-content-evenly">
                <div className="p-2">{v.trim()}</div>
                <div className="p-2">
                  <FormControl sx={{ m: 1, minWidth: 120 }}>
                    <Select
                      labelId="demo-simple-select-autowidth-label"
                      id="demo-simple-select-autowidth"
                      value={selectedList[v.trim()]}
                      onChange={(e) =>
                        handleChangeDataServicesList(e.target.value, v.trim())
                      }
                      autoWidth
                      label="Age"
                    >
                      <MenuItem key="none" value={"None"} className="p-2">
                        None
                      </MenuItem>
                      {servicesData[v.trim()]?.map((x) => (
                        <MenuItem className="p-2" key={x} value={x}>
                          {x}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </div>
            ))}
          </Modal.Body>
        )}
        <Modal.Footer>
          <Button
            onClick={() => {
              updateCompanyDataServiceDB();
            }}
            style={{ backgroundColor: "#4F3493", borderColor: "#4F3493" }}
          >
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>

      {/* End Modal for  Notification Settings Update*/}
    </div>
  );
}
