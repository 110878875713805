import React from 'react'
import Auth from "@aws-amplify/auth";

export class TestAuth extends React.PureComponent {
    constructor(props){
        super(props)
        this.state = {
            valid : ""
        }
    }
    
    render() {
        return (
            <div>
               { 
                        this.state.valid !==""  && (
                            <p> Hello {this.state.valid}</p>
                        )
                }
            </div>
        )
    }
}

export default TestAuth

