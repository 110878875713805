import React from "react";
import { useState, useEffect } from "react";
import ApiClient from "../../apis/apiClient";
import MUIDataTable from "mui-datatables";
import Backdrop from "@mui/material/Backdrop";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import RefreshIcon from "@material-ui/icons/Refresh";

import checkPageAccess from "../others/getPageAccess";
import { Container } from "@material-ui/core";
import Cookies from "js-cookie";
import {
  unstable_createMuiStrictModeTheme as createMuiTheme,
  MuiThemeProvider,
} from "@material-ui/core/styles";
import CircularProgress from "@mui/material/CircularProgress";

import SweetAlert from "react-bootstrap-sweetalert";
import logout from "../validations/logoutUser";
const theme = createMuiTheme({
  palette: {
    primary: {
      light: "#757CE8",
      main: "#3F50B5",
      dark: "#002884",
      contrastText: "#fff",
    },
    secondary: {
      light: "#FF7961",
      main: "#F44336",
      dark: "#BA000D",
      contrastText: "#000",
    },
  },
});
function ErrorLogs() {
  const [data, setdata] = useState([]);
  const [loading, setloading] = useState(true);
  const [alertMessage, setAlertMessage] = useState("");
  const [alert, setAlert] = useState(false);
  const [severity, setSeverity] = useState("default");
  const [alertTitle, setAlertTitle] = useState("");
  const [confirmAction, setConfirmAction] = useState("");
  const [hrefLink, setHrefLink] = useState("");
  const columns = [
    {
      label: "Message ID",
      name: "recordID",
    },
    {
      label: "User",
      name: "user",
    },
    {
      label: "Scheduled Time",
      name: "scheduleTime",
    },
    {
      label: "Station",
      name: "destinationStation",
    },
    {
      label: "Station IP",
      name: "stationIP",
    },
    {
      label: "Updated At",
      name: "updatedAt",
      type: "date",
    },
    {
      label: "Error Message",
      name: "errorLog",
    },
  ];
  const options = {
    filter: true,
    filterType: "dropdown",
    selectableRows: "none",
    sortOrder: {
      name: "scheduleTime",
      direction: "desc",
    },
    customToolbar: () => {
      return (
        <Tooltip title={"Refresh"}>
          <IconButton style={{ order: -1 }} onClick={() => getErrorMessages()}>
            <RefreshIcon />
          </IconButton>
        </Tooltip>
      );
    },
  };

  const getErrorMessages = async () => {
    setloading(true);
    try {
      const response = await ApiClient("get", "/admin/getMSACErrorLogs", "", {
        "Content-Type": "application/json",
        authorization: Cookies.get("auth"),
      });
      //console.log(response);
      let out = [];
      if (response.data.status === 200) {
        const responseData = response.data.result;
        out = responseData.map((values) => {
          return {
            recordID: values.recordID,
            user: values.user,
            scheduleTime: new Date(values.scheduleTime).toISOString(),
            destinationStation: values.destinationStation,
            stationIP: values.stationIP,
            updatedAt: new Date(values.updatedAt).toISOString(),
            errorLog: values.errorLog
          };
        });
        console.log(out);
        setdata(out);
        setloading(false);
      } else if (response.code === 400) throw Error("Cant load invalid url");
      else throw Error("Some Error Occured")
    } catch (err) {
      //console.log(err.message);

      //alert("Cant fetch Data Error Message: " + err.message);
      setAlert(true);
      setSeverity("error");
      setAlertMessage("Cant fetch Data Error Message: " + err.message);
      setAlertTitle("Something Went Wrong");
      setConfirmAction("");
      setHrefLink("");
    }
  };

  useEffect(async () => {
    document.title = "Message History | Xperi HD Radio IoT";
    const isValid = await checkPageAccess();
    if (!isValid) {

      // if (!alert("access denied for the url"))
      //   window.location.href = "/unauthorized";
      setAlert(true);
      setSeverity("error");
      setAlertMessage("access denied for the url");
      setAlertTitle("Something Went Wrong");
      setConfirmAction("href");
      setHrefLink("/unauthorized");
    } else {
      //console.log("You have access");
      getErrorMessages();
    }
  }, []);
  const confirmAlert = () => {
    setAlert(false);
    setSeverity("default");
    setAlertTitle("");
    if (confirmAction == "load") {
      window.location.reload();
    } else if (confirmAction == "href") {
      window.location.href = hrefLink;
    }else if (confirmAction === "logout") {
      logout();
    }
  }
  return (
    <div>
      {alert && (
        <SweetAlert type={severity} title={alertTitle} onConfirm={confirmAlert}>
          {alertMessage}
        </SweetAlert>
      )}
      <Container
        maxWidth="lg"
        className="d-flex flex-column min-vh-100"
        style={{ marginTop: "100px" }}
      >
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <React.Fragment>
          <div>
            <MuiThemeProvider theme={theme}>
              <MUIDataTable
                title={"Error Logs MSAC"}
                data={data}
                columns={columns}
                options={options}
                elevation={1}
              />
            </MuiThemeProvider>
            <br />
            <br />
          </div>
          <br />
          <br />
          <br />
        </React.Fragment>
      </Container>
    </div>
  );
}

export default ErrorLogs;
