import React from "react";
import { useEffect, useState } from "react";
import WorkHistoryIcon from "@mui/icons-material/WorkHistory";
import AvTimerIcon from "@mui/icons-material/AvTimer";
import DraftsIcon from "@mui/icons-material/Drafts";
import LanguageIcon from "@mui/icons-material/Language";
import ApiClient from "../../apis/apiClient";
import Cookies from "js-cookie";
import Loader from "./loader";
import axios from "axios";
import encryptHash from "../validations/general";
import logout from "../validations/logoutUser";
import SweetAlert from "react-bootstrap-sweetalert";
import { Button, Modal } from "react-bootstrap";
import { TextField, Input } from "@material-ui/core";


function DashboardV1(props) {
  const [dashboardData, setdashboardData] = useState(null);
  const [is_loading, setis_loading] = useState(true);
  const [messagePath, setMessagePath] = useState("/user/home");
  const [alertMessage, setAlertMessage] = useState("");
  const [alert, setAlert] = useState(false);
  const [severity, setSeverity] = useState("default");
  const [alertTitle, setAlertTitle] = useState("");
  const [confirmAction, setConfirmAction] = useState("");
  const [hrefLink, setHrefLink] = useState("");
  const [showStationStatus, setshowStationStatus] = useState(false);
  const handleShowStationStatusModal = () => setshowStationStatus(true);
  const handleHideStationStatusModal = () =>  {
    setstationStatusResponse("Enter Required Fields and  Click Check to see Ping Status");
    setshowStationStatus(false);
  }
  const [stationStatusIP, setstationStatusIP] = useState();
  const [stationStatusPort, setstationStatusPort] = useState();
  const [stationStatusResponse, setstationStatusResponse] = useState(
    "Enter Required Fields and  Click Check to see Ping Status"
  );

  const [loading, setloading] = useState(true);
  const {showSideBar} = props
  const [customDivStyle, setcustomDivStyle] = useState({ marginTop: "100px",padding: '0px', marginStart: "2%", width: "100%"});


  const checkLiveStationStatus = async () => {
    let regEx = /^((25[0-5]|(2[0-4]|1\d|[1-9]|)\d)(\.(?!$)|$)){4}$/;
    if (!stationStatusIP.match(regEx)) {
      alert("Invalid Station IP");
      return;
    }
    setloading(true);
    // let authToken = await (await Auth.currentSession())
    //   .getAccessToken()
    //   .getJwtToken();
    //console.log(authToken);
    try {
      
      let resp = await ApiClient("get","/admin/getIPandPortStatus", "env=prod&ip="+stationStatusIP+"&port="+stationStatusPort,{
        "Content-Type": "application/json",
        authorization: Cookies.get("auth"),
      });
      
      if(resp.data.status === 200){
        setstationStatusResponse(resp.data.msg);
        setloading(false);
      }else{
        setstationStatusResponse(resp.data.msg);
        setloading(false);
      }
    } catch (error) {
      setstationStatusResponse("Some Error Occured");
        setloading(false);
    }
    // setTimeout(() => {
    //   setstationStatusResponse("Please try after sometime");
    //   setloading(false);
    // }, 2000);
  };

  useEffect(async () => {
    let resp = await ApiClient("get", "/app/dashboard", "", {
      "Content-Type": "application/json",
      authorization: Cookies.get("auth"),
    });
    if (resp.data.status === 200) {
      setdashboardData(resp.data.result);
      setis_loading(false);
      let access_levels = Cookies.get("t").split(",");
      if (
        access_levels.indexOf(encryptHash("502")) !== -1 &&
        access_levels.indexOf(encryptHash("777")) === -1
      )
        setMessagePath("/user/xperi-payload");
    } else if (resp.data.status === 403) {
      setAlert(true);
      setSeverity("error");
      setAlertMessage("Session Expired Please Relogin ");
      setAlertTitle("Relogin");
      setConfirmAction("logout");
    } else {
      //logout();
      //window.location.href = "/";
      setAlert(true);
      setSeverity("error");
      setAlertMessage("Some Error Occured");
      setAlertTitle("Something Went Wrong");
      setConfirmAction("logout");
      //setHrefLink("/");

    }
  }, []);
  const confirmAlert = () => {
    setAlert(false);
    setSeverity("default");
    setAlertTitle("");
    if (confirmAction == "load") {
      window.location.reload();
    } else if (confirmAction == "href") {
      window.location.href = hrefLink;
    } else if (confirmAction === "logout") {
      logout();
    }
  };

  useEffect(() => {
    if(showSideBar){
      setcustomDivStyle({marginTop: "100px",padding: '0px', marginLeft: "15%", width:"80%", height:"100%"})
    }else{
      setcustomDivStyle({marginTop: "100px",padding: '0px'})
    }
  }, [showSideBar])
  
  return (
    <div>
      {alert && (
        <SweetAlert type={severity} title={alertTitle} onConfirm={confirmAlert}>
          {alertMessage}
        </SweetAlert>
      )}
      <Loader open={is_loading} />
      {!is_loading && (
        <div>
          <div
            className="container jumbotron bg-light rounded"
            style={customDivStyle}
          >
            <div className="row" style={{padding: '0px'}}>
              <div className="col-md-6 col-sm-12">
                <img
                  src={window.location.origin + "/img/banner.jpg"}
                  className="image"
                  alt=""
                  style={{ width: "100%", height: "625px" }}
                />
              </div>
              <div className="col-md-6 col-sm-12">
                <div className="container">
                  <div className="row mt-2">
                    <div className="col-md-12 d-flex justify-content-center">
                      <div
                        className="card bg-secondary text-white"
                        style={{ width: "38rem", height: "10rem" }}
                      >
                        <div className="card-body">
                          <h5 className="card-header h-150">
                            <WorkHistoryIcon /> Pending Messages Scheduled Count
                          </h5>
                          <h4 className="card-title d-flex justify-content-center">
                            {dashboardData
                              ? dashboardData.count.toLocaleString()
                              : 0}
                          </h4>
                          <div className="d-flex justify-content-around">
                            <a href={messagePath} className="btn btn-primary" style={{borderColor: "#4F3493", backgroundColor: "#4F3493"}}>
                              New Message
                            </a>
                            <a
                              href="/user/message-history"
                              className="btn btn-warning"
                              style={{backgroundColor: "#4F3493",color: "white", borderColor: "#4F3493"}}
                            >
                              Message History
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-md-12 d-flex justify-content-center">
                      <div
                        className="card bg-secondary text-white"
                        style={{ width: "38rem", height: "8rem" }}
                      >
                        <div className="card-body">
                          <h5 className="card-header h-150">
                            <AvTimerIcon /> Next Message Scheduled For
                          </h5>
                          <h4 className="card-title d-flex justify-content-center">
                            {dashboardData.last_time !== null
                              ? new Date(
                                  dashboardData.last_time
                                ).toLocaleString()
                              : `-`}
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-md-12 d-flex justify-content-center">
                      <div
                        className="card bg-secondary text-white"
                        style={{ width: "38rem", wordBreak: "break-word" }} //// height: "8rem",
                      >
                        <div className="card-body">
                          <h5 className="card-header">
                            <DraftsIcon /> Company Admin Email
                          </h5>
                          <h4 className="card-title d-flex justify-content-center">
                            {dashboardData ? dashboardData.admin : ""}
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-md-12 d-flex justify-content-center">
                      <div
                        className="card bg-secondary text-white"
                        style={{ width: "38rem", wordBreak: "break-word" }} // height: "8rem",
                      >
                        <div className="card-body">
                          <h5 className="card-header h-150">
                            <LanguageIcon /> Last login IP
                          </h5>
                          <h4 className="card-title d-flex justify-content-center">
                            {dashboardData ? dashboardData.last_ip : ""}
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                  { Cookies.get("t") &&
                  (Cookies.get("t").split(",").indexOf(encryptHash(777)) !== -1 ||
                    Cookies.get("t").split(",").indexOf(encryptHash(715)) !== -1) && (<div className="row m-2">
                  <div className="col-md-12 mt-3 d-flex justify-content-center">
                    <Button style={{backgroundColor: "#4F3493", borderColor: "#4F3493", color: "white", width:"38rem"}} onClick={() => handleShowStationStatusModal()}>
                      Check Station Status
                    </Button>
                    </div>
                  </div> )
                  }
                </div>
              </div>
            </div>
          </div>
          <br />
          <br />
          <br />


          {/* Check Station Availability Modal */}
      <Modal
      show={showStationStatus}
      onHide={handleHideStationStatusModal}
      backdrop="static"
      keyboard={false}
      id="oldModal"
      style={{ marginTop: "5%" }}
    >
      <Modal.Header closeButton>
        <Modal.Title>Check Live Station Status </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <table style={{ width: "100%" }}>
            <tbody>
              <tr>
                <td>Station IP<sup style={{color:'red'}}>*</sup></td>
                <td>
                  <TextField
                    required
                    className="ml-3"
                    /*error={newRegionErr && show_error2 ? true : false}*/
                    id="stationIP"
                    placeholder="Enter Station IP"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e) => {
                      setstationStatusIP(e.target.value);
                    }}
                    /*helperText={
        newRegionErr && show_error2
          ? newRegionErrText
          : ""
      }*/
                  />
                </td>
              </tr>
              <tr>
                <td>Port<sup style={{color:'red'}}>*</sup></td>
                <td>
                  <TextField
                    required
                    className="ml-3"
                    /*error={newRegionErr && show_error2 ? true : false}*/
                    id="stationIP"
                    placeholder="Station Port"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e) => {
                      setstationStatusPort(e.target.value);
                    }}
                    /*helperText={
        newRegionErr && show_error2
          ? newRegionErrText
          : ""
      }*/
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <div className="mt-4 alert alert-primary">
            {stationStatusResponse}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="danger"
          onClick={() => {
            checkLiveStationStatus();
          }}
        >
          Check
        </Button>{" "}
      </Modal.Footer>
    </Modal>

    {/* END Check Station Availability Modal */}
        <br/><br/><br/>
    </div>
      )}
    </div>
  );
}

export default DashboardV1;
