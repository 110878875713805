import { React, useState, useEffect } from "react";
import WifiIcon from "@mui/icons-material/Wifi";
import WifiOffIcon from "@mui/icons-material/WifiOff";
import EditIcon from "@mui/icons-material/Edit";
import ErrorIcon from "@mui/icons-material/Error";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import NetworkPingIcon from "@mui/icons-material/NetworkPing";
import DangerousIcon from "@mui/icons-material/Dangerous";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import { Button, Modal } from "react-bootstrap";
import DataTable from "../../DataTable/dataTable";
import { Tooltip } from "@mui/material";
/*import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";*/

function StationTableData(props) {
  const {
    stationArr,
    setselectedRow,
    handleShowDelete,
    setBitRateEdit,
    setstationnameEdit,
    setIpEdit,
    setportEdit,
    setsshportEdit,
    setadminEmailEdit,
    setadminNameEdit,
    setFreqencyEdit,
    setdestinationEdit,
    handleShow,
    setshowPortStatus,
    updatePingImmediate,
    setnotifRow,
    setadminEmail
  } = props;
  
  

  const active_status = {
    1: (
      <>
        <CheckCircleIcon color="success" />
      </>
    ),
    2: (
      <>
        <ErrorIcon color="warning" />{" "}
      </>
    ),
    3: (
      <>
        <DangerousIcon style={{ fill: "red" }} />
      </>
    ),
  };

  const tableColumns = [
    {
      name: "id",
      label: "ID",
      options: {
        display: true,
        filter: false,
        sort: false,
      },
    },
    { name: "name", label: "Name" },
    { name: "call_sign", label: "Call Sign" },
    { name: "frequency", label: "Frequency" },
    { name: "station_version", label:"MSAC Version" },
    {
      name: "Active/\nInactive",
      options: {
        filter: false,
        //hint: "Shows the current state of the station (use this to log off the station)",
        customBodyRender: (value, tableMeta, updateValue) => (
          <Tooltip title="Click to activate or inactivate" arrow>
            {stationArr[tableMeta.rowIndex].is_active ? (
              <Button
                variant="light"
                onClick={() => {
                  ////console.log(tableMeta.rowIndex);
                  setselectedRow(tableMeta.rowIndex);
                  handleShowDelete();
                }}
              >
                <WifiIcon color="success" />
              </Button>
            ) : (
              <Button
                variant="light"
                onClick={() => {
                  ////console.log(tableMeta.rowIndex);
                  setselectedRow(tableMeta.rowIndex);
                  handleShowDelete();
                }}
              >
                <WifiOffIcon color="error" />
              </Button>
            )}
          </Tooltip>
        ),
      },
    },
    {
      name: "Status",
      options: {
        filter: false,
        //hint: "Shows the current state of the station (use this to log off the station)",
        customBodyRender: (value, tableMeta, updateValue) => (
          <Tooltip title="Click to Know More" arrow>
            <Button
              variant="light"
              style={{ borderRadius: "25%" }}
              onClick={() => {
                ////console.log(tableMeta.rowIndex);
                setselectedRow(tableMeta.rowIndex);
                setshowPortStatus(true);
              }}
            >
              {active_status[stationArr[tableMeta.rowIndex].overall_status]}
            </Button>
          </Tooltip>
        ),
      },
    },
    { name: "region", label: "Region" },
    { name: "bit_rate", label: "Bitrate (Kbps)" },
    { name: "admin_name", label: "Station Admin" },
    { name: "admin_email", label: "Admin Email" },
    {
      name: "Ping Station",
      label: "Ping Station",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => (
          <Button variant="outline-light">
            <NetworkPingIcon
              style={{ fill: "green" }}
              onClick={() => {
                updatePingImmediate(tableMeta.rowIndex);
              }}
            />
          </Button>
        ),
      },
    },
    {
      name: "Notification Settings",
      options: {
        filter: false,
        //hint: "Shows the current state of the station (use this to log off the station)",
        customBodyRender: (value, tableMeta, updateValue) => (
          <center>
            <Tooltip title="Click to update notification settings" arrow>
              <NotificationsActiveIcon
                onClick={() => {
                  setnotifRow(stationArr[tableMeta.rowIndex].call_sign);
                  setadminEmail(stationArr[tableMeta.rowIndex].admin_email);
                }
                }
              />
            </Tooltip>
          </center>
        ),
      },
    },
    {
      name: "Modify",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => (
          <Button variant="outline-light">
            <EditIcon
              style={{ fill: "black" }}
              onClick={() => {
                ////console.log(tableMeta.rowIndex);
                setselectedRow(tableMeta.rowIndex);
                setBitRateEdit(stationArr[tableMeta.rowIndex].bit_rate);
                setstationnameEdit(stationArr[tableMeta.rowIndex].name);
                setIpEdit(stationArr[tableMeta.rowIndex].ip);
                setportEdit(stationArr[tableMeta.rowIndex].port);
                setsshportEdit(stationArr[tableMeta.rowIndex].ssh_port);
                setadminEmailEdit(stationArr[tableMeta.rowIndex].admin_email);
                setadminNameEdit(stationArr[tableMeta.rowIndex].admin_name);
                setFreqencyEdit(stationArr[tableMeta.rowIndex].frequency);
                setdestinationEdit(stationArr[tableMeta.rowIndex].destination);
                handleShow();
              }}
            />
          </Button>
        ),
      },
    },
  ];

  const tableOptions = {
    filter: true,
    filterType: "dropdown",
    responsive: "standard",
    tableBodyHeight: "600px",
    tableBodyMaxHeight: "",
    selectableRows: "none",
  };

  return (
    <>
      <DataTable
        tableData={stationArr}
        tableColumns={tableColumns}
        tableOptions={tableOptions}
      />
    </>
  );
}

export default StationTableData;
