import { React, useState, useRef } from "react";
import { Formik, Form, Field } from "formik";
import { security_questions } from "../../strings/strings";
import QRCode from "qrcode.react";
import Auth from "@aws-amplify/auth";
import { Select, MenuItem, Button, TextField } from "@material-ui/core";
import ApiClient from "../../apis/apiClient";
import CryptoJS from "crypto-js";
import SweetAlert from "react-bootstrap-sweetalert";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { red } from "@mui/material/colors";
import Alerts from "../alerts/display-alert";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

function RegisterMFA(props) {
  const [randomCode, setrandomCode] = useState(
    (Math.random() + 1).toString(36).substring(4).toUpperCase()
  );
  const [loading, setLoading] = useState(false);
  const [mfaCodeError, setmfaCodeError] = useState(false);
  const [mfaCodeMessage, setmfaCodeMessage] = useState("");
  //const alert = useAlert()
  const {
    MFACode,
    setDoLogin,
    doLogin,
    email,
    setMessage,
    setAlertType,
    setStatus,
    setGetMFA,
  } = props;
  const [securityQuestion, setSecurityQuestion] = useState("");
  const [securityAnswer, setSecurityAnswer] = useState("");
  const [close, setClose] = useState(true);
  const [validateCodeMFA, setvalidateCodeMFA] = useState();
  const [enableValidateCMFA, setEnableValidateCodeMFA] = useState(true);
  const [authChallengeSuccess, setauthChallengeSuccess] = useState(false);
  const [showPass, setshowPass] = useState(false);
  const nodeRef = useRef(null);
  const [severity, setSeverity] = useState("");
  const [alert, setAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [cognitoUser, setcognitoUser] = useState("")
  const [confirmed, setconfirmed] = useState(false);
  const toggleShowPass = () => {
    setshowPass(!showPass);
  };

  const verifyMFAValidationCode = async () => {
    //console.log(validateCodeMFA);
    setLoading(true);
    try {
      let user = await Auth.currentAuthenticatedUser();
      setcognitoUser(user.username);
      let tokenVerified = await Auth.verifyTotpToken(user, validateCodeMFA);
      if (tokenVerified) {
        //console.log(tokenVerified);
        //let resp = await Auth.setPreferredMFA(user, 'TOTP');
        //console.log(resp);
        //if(resp)
        setauthChallengeSuccess(true);
        setLoading(false);
        setmfaCodeError(false);
        setmfaCodeMessage("success");
      } else {
        setLoading(false);
        setmfaCodeError(true);
        setauthChallengeSuccess(false);
        setmfaCodeMessage("Error in validating mfa");
      }
      setEnableValidateCodeMFA(false);
    } catch (error) {
      console.log(error.message);
      setmfaCodeError(true);
      setauthChallengeSuccess(false);
      setLoading(false);
      setmfaCodeMessage("Error in validating mfa");
      //setEnableValidateCodeMFA(false);
    }
  };

  const confirm = async () => {
    setconfirmed(true);
    if (!authChallengeSuccess) {
      //  alert("Please ADD MFA code to authenticator app and verify first in step 2");
      setAlert(true);
      setSeverity("info");
      setAlertMessage(
        "Please ADD MFA code to authenticator app and verify first in step 2"
      );
      const timer = setTimeout(() => {
        setAlert(false);
        setSeverity("");
      }, 1000);

      return;
    }
    if (securityQuestion.length > 0 && securityAnswer.length > 0) {
      // //console.log('Security Q',securityQuestion)
      // //console.log('Security A',securityAnswer)
      // //console.log('randomCode',randomCode)
      let paraphrase = randomCode + "@" + securityAnswer;
      //console.log(paraphrase);
      let cipher = CryptoJS.AES.encrypt(MFACode, paraphrase).toString();
      // //console.log('Cipher ',cipher);
      const response = await ApiClient("post", "/setMFAAuthCode", {
        email: email,
        code: cipher,
        question: securityQuestion,
        cognitoUser: cognitoUser
      });
      //console.log(response);
      if (response.code === 200) {
        //console.log("MFA Added");
        setStatus(0);
        setGetMFA(false);
        setDoLogin(true);
      } else {
        //console.log("MFA Addition Failed");
        setconfirmed(false);
        setAlertType("error");
        setMessage("Cant Process Registration");
        setStatus(1);
      }
    } else {
      //console.log("Invalid Selection of Question or Answer");
    }
    //const response =

    //setDoLogin(true);
  };
  const validateSecurityQuestion = (value) => {
    let error;
    if (!value) {
      error = "Please enter a valid security answer";
    } else {
      setSecurityAnswer(value);
    }
    return error;
  };

  const closeAlert = () => {
    setClose(false);
    window.location.href = "#";
  };
  return (
    <div>
      {alert && (
        <Alerts
          variant="filled"
          severity={severity}
          alertMessage={alertMessage}
          setAlert={setAlert}
          setSeverity={setSeverity}
        ></Alerts>
      )}
      {close && (
        <SweetAlert
          info
          title="Important"
          style={{ color: "black" }}
          onConfirm={closeAlert}
        >
          In case of device loss please write down the <em>Recovery Code</em>{" "}
          and <em>Security Answer</em> for recovery of MFA
        </SweetAlert>
      )}

      <div className="row" style={{ height: "575px", overflow: "auto" }}>
        <span>
          <h4>Secure Your Account</h4>
          <h6>
            Secure your account with multi-factor authentication (MFA) using the
            following steps.
          </h6>
        </span>
        <h5>Step 1: </h5>{" "}
        <p>
          Download one of the following MFA applications to your mobile device.
        </p>
        <div>
          <table className="table">
            <tbody>
              <tr>
                <td style={{ textAlign: "center" }}>
                  <img
                    src={window.location.origin + "/img/ga.png"}
                    alt=""
                    style={{ width: "60px" }}
                  />
                </td>
                <td style={{ textAlign: "center" }}>
                  <img
                    src={window.location.origin + "/img/ma.png"}
                    alt=""
                    style={{ width: "60px" }}
                  />
                </td>
                <td style={{ textAlign: "center" }}>
                  <img
                    src={window.location.origin + "/img/okta.png"}
                    alt=""
                    style={{ width: "60px" }}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <h6>&nbsp;Google Authenticator</h6>
                </td>
                <td>
                  <h6>&nbsp;Microsoft Authenticator</h6>
                </td>
                <td>
                  <h6>&nbsp;Okta Authenticator</h6>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <h5>Step 2: </h5>{" "}
        <p>
          Scan the QR code below with the multi-factor authentication app
          installed in{" "}
          <em>
            <u>Step 1.</u> Then enter the <strong>6 digit</strong> code
            displayed on the app.
          </em>
        </p>
        <center>
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <QRCode value={MFACode} />
              </div>
              <div className="col-md-6">
                <TextField
                  variant="standard"
                  label="MFA Validation Code"
                  required
                  type="number"
                  name="validateCodeMFA"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  disabled={!enableValidateCMFA}
                  onChange={(e) => setvalidateCodeMFA(e.target.value)}
                />
                <br />
                {mfaCodeError ? (
                  <b style={{ color: "red" }}>{mfaCodeMessage}</b>
                ) : (
                  <b style={{ color: "green" }}>{mfaCodeMessage}</b>
                )}
                <br />
                <Box sx={{ m: 1, position: "relative" }}>
                  <button
                    className="btn btn-primary"
                    onClick={verifyMFAValidationCode}
                    disabled={loading || !enableValidateCMFA}
                  >
                    Validate
                  </button>
                  {loading && (
                    <CircularProgress
                      size={24}
                      sx={{
                        color: red[500],
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginTop: "-12px",
                        marginLeft: "-12px",
                      }}
                    />
                  )}
                </Box>
              </div>
            </div>
          </div>
        </center>
        <h5>Step 3:</h5>{" "}
        <p>Select a security question and fill in the answer.</p>
        <Formik
          initialValues={{
            securityAnswer: "",
          }}
        >
          {({ errors, touched }) => (
            <Form style={{ paddingLeft: "5%", paddingRight: "5%" }}>
              <div className="row px-3">
                <label className="mb-1">
                  <h6 className="mb-0 text-sm">Security Question</h6>
                </label>
                <Select
                  labelId="demo-simple-select-label"
                  id="security-select"
                  value={securityQuestion}
                  label="Security Question"
                  onChange={(event) => setSecurityQuestion(event.target.value)}
                  nodeRef={nodeRef}
                >
                  {security_questions.map((val, idx) => (
                    <MenuItem value={val} key={idx}>
                      {val}
                    </MenuItem>
                  ))}
                </Select>
              </div>
              <br />
              <div className="row px-3">
                <label className="mb-1">
                  <h6 className="mb-0 text-sm">Answer</h6>
                </label>
                <div style={{ padding: 0, position: "relative" }}>
                  <div>
                    <Field
                      className="mb-4"
                      type={showPass ? "text" : "password"}
                      name="securityAnswer"
                      placeholder="Enter an answer to the security question"
                      validate={validateSecurityQuestion}
                    />
                    {errors.securityAnswer && touched.securityAnswer && (
                      <div className="invalid-feedback d-block">
                        {errors.securityAnswer}
                      </div>
                    )}
                  </div>
                  <div
                    style={{
                      position: "absolute",
                      bottom: "25px",
                      right: "8px",
                    }}
                  >
                    {showPass && (
                      <VisibilityIcon onClick={() => toggleShowPass()} />
                    )}
                    {!showPass && (
                      <VisibilityOffIcon onClick={() => toggleShowPass()} />
                    )}
                  </div>
                </div>
              </div>
              <br />
              <br />
            </Form>
          )}
        </Formik>
        <h5>Step 4:</h5>{" "}
        <p style={{ color: "red" }}>
          Record the following <b>recovery code</b> and the{" "}
          <b>security answer</b> from <strong>step 3</strong> in a <br />
          safe and secure location. This will be used to recover your MFA in
          case your mobile device is lost or damaged.
        </p>
        <strong>Recovery Code: </strong> <h4>{randomCode}</h4>
        <br />
        <br />
        <div className="d-flex justify-content-center">
          <Button
            variant="contained"
            color="primary"
            style={{ width: "50%" }}
            onClick={confirm}
            disabled={confirmed}
          >
            Confirm Scan
          </Button>
        </div>
      </div>
    </div>
  );
}

export default RegisterMFA;
