import { React, useState } from "react";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { Formik, Form, Field } from "formik";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { SHA256 } from "crypto-js";
import {
  validateUsernameMain,
  validateEmailMain,
  validatePhonenumberMain,
  validatePasswordMain,
  validateCompanyMain,
  validateConfirmPasswordMain,
} from "../validations/basicRegFormValidations";

function BaseRegisterForm(props) {
  const {
    setName,
    setEmail,
    phoneNumber,
    setPhoneNumber,
    setPassword,
    setPhoneError,
    phoneError,
    setSelectedCompany,
    company,
    regClicked,
    regFailed,
    setRegClicked,
    setRegister,
    setConfirmPassword,
    password,
  } = props;
  const [showPassState, setShowPassState] = useState(false);
  const [showCPassState, setShowCPassState] = useState(false);

  const encryptHash = (value) => {
    let ciphertext = SHA256(value, process.env.REACT_APP_GENERAL_HASH);
    return ciphertext.toString();
  };
  const validateUsername = (value) => {
    return validateUsernameMain(value, setName);
  };
  const validateEmail = (value) => {
    return validateEmailMain(value, setEmail);
  };
  const validatePhonenumber = (value) => {
    return validatePhonenumberMain(value, setPhoneNumber, setPhoneError);
  };
  const validatePassword = (value) => {
    return validatePasswordMain(value, setPassword);
  };
  const validateConfirmPassword = (value) => {
    return validateConfirmPasswordMain(value, password, setConfirmPassword);
  };
  const validateCompany = (value) => {
    let dvalue = encryptHash(value);
    return validateCompanyMain(dvalue, setSelectedCompany, company);
  };

  const showPassword = () => {
    setShowPassState(!showPassState);
  };
  const showCPassword = () => {
    setShowCPassState(!showCPassState);
  };

  const signUp = () => {
    setRegClicked(1);
  };

  return (
    <div>
      <Formik
        initialValues={{
          email: "",
          password: "",
          phoneNumber: "",
          name: "",
          company: "",
          confirmPassword: "",
        }}
        onSubmit={signUp}
      >
        {({ errors, touched }) => (
          <Form>
            <div className="row px-3">
              <label className="mb-1">
                <h6 className="mb-0 text-sm">Name</h6>
              </label>
              <Field
                className="mb-4"
                type="text"
                name="name"
                placeholder="Enter Name"
                validate={validateUsername}
                required
              />
              {errors.name && touched.name && (
                <div className="invalid-feedback d-block">{errors.name}</div>
              )}
            </div>
            <div className="row px-3">
              <label className="mb-1">
                <h6 className="mb-0 text-sm">Email Address</h6>
              </label>
              <Field
                className="mb-4"
                type="text"
                name="email"
                placeholder="Enter email address"
                validate={validateEmail}
                required
              />
              {errors.email && touched.email && (
                <div className="invalid-feedback d-block">{errors.email}</div>
              )}
            </div>
            <div className="row px-3">
              <label className="mb-1">
                <h6 className="mb-0 text-sm">Phone Number</h6>
              </label>
              <PhoneInput
                international
                className="mb-4"
                placeholder="Enter phone number with Country Code"
                name="phone"
                value={phoneNumber}
                onChange={validatePhonenumber}
                required
              />
              {phoneError && (
                <div className="invalid-feedback d-block">{phoneError}</div>
              )}
            </div>
            <div className="row px-3">
              <label className="mb-1">
                <h6 className="mb-0 text-sm">Company Code</h6>
              </label>
              <Field
                className="mb-4"
                type="text"
                name="company"
                validate={validateCompany}
                autoComplete="off"
                style={{ height: "40px" }}
                required
              ></Field>
              {errors.company && touched.company && (
                <div className="invalid-feedback d-block">{errors.company}</div>
              )}
            </div>
            <div className="row px-3">
              {" "}
              <label className="mb-1">
                <h6 className="mb-0 text-sm">Password</h6>
              </label>{" "}
              <div style={{ padding: 0, position: "relative" }}>
                <div>
                  <Field
                    type={showPassState ? "text" : "password"}
                    name="password"
                    id="password"
                    placeholder="Enter password"
                    validate={validatePassword}
                    required
                  />
                </div>
                <div
                  style={{
                    position: "absolute",
                    bottom: "17px",
                    right: "8px",
                  }}
                >
                  {showPassState && (
                    <VisibilityIcon onClick={() => showPassword()} />
                  )}
                  {!showPassState && (
                    <VisibilityOffIcon onClick={() => showPassword()} />
                  )}
                </div>
              </div>
              {errors.password && touched.password && (
                <div className="invalid-feedback d-block">
                  {errors.password}
                </div>
              )}{" "}
            </div>

            <div className="row px-3">
              {" "}
              <label className="mb-1">
                <h6 className="mb-0 text-sm">Confirm Password</h6>
              </label>{" "}
              <div style={{ padding: 0, position: "relative" }}>
                <Field
                  type={showCPassState ? "text" : "password"}
                  name="confirmPassword"
                  id="confirmPassword"
                  placeholder="Enter password"
                  validate={validateConfirmPassword}
                  required
                />
                <div
                  style={{
                    position: "absolute",
                    bottom: "17px",
                    right: "8px",
                  }}
                >
                  {showCPassState && (
                    <VisibilityIcon onClick={() => showCPassword()} />
                  )}
                  {!showCPassState && (
                    <VisibilityOffIcon onClick={() => showCPassword()} />
                  )}
                </div>
              </div>
              {errors.confirmPassword && touched.confirmPassword && (
                <div className="invalid-feedback d-block">
                  {errors.confirmPassword}
                </div>
              )}{" "}
            </div>
            <div className="mb-3"></div>
            <div className="row  mb-3 px-3">
              {(regClicked === 0 || regFailed) && (
                <button
                  type="submit"
                  className="btn btn-primary text-center"
                  onClick={() => setRegister(true)}
                >
                  Register
                </button>
              )}
              {regClicked === 1 && !regFailed && (
                <button
                  type="submit"
                  className="btn btn-primary text-center"
                  disabled
                >
                  Please wait
                </button>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default BaseRegisterForm;
