import React from "react";
import { useState, useEffect } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Datatable from "../../DataTable/dataTable";
import { Checkbox } from "@mui/material";

function ExpandableUserList(props) {
    const columns = [
        {
            name: "company_name",
            label: "Company name",
        }
    ];
    const { data } = props
    
    const [expandRows, setExpandRows] = useState([]);
    const [rows, setrows] = useState([]);
    const [checked, setChecked] = useState([])
    useEffect(async () => {
        let tableData = []
        const expandData = []
        let checkVal = []
        for (let i = 0; i < data.length; i++) {
            // if (tableData.indexOf(data[i]["company_name"]) == -1) {
            let nArray = tableData.filter(function (el) {
                return el.company_name == data[i]["company_name"]
            });
            if (nArray.length == 0) {
                const temp1 = { "company_name": data[i]["company_name"] }
                tableData.push(temp1)
                expandData[data[i]["company_name"]] = []
            }
            const temp2 = {
                approved: data[i]["approved"],
                email: data[i]["email"],
                stations: data[i]["stations"],
                is_admin: data[i]["is_admin"],
                name: data[i]["name"],
                phone: data[i]["phone"],
                index: i
            }
            checkVal.push(false)
            expandData[data[i]["company_name"]].push(temp2)

        }
        setExpandRows(expandData)
        setrows(tableData)
        setChecked(checkVal)
    }, [])
    // useEffect(() => {
    //     console.log(checked)
    // }, [checked])


    const clickHandler = (email, index) => {
        // Here, we invoke the callback with the new value
        props.expandClickHandler(email, index);
    }
    const handleChange = (val) => {
        let checkArr = [...checked];
        checkArr = checkArr.map(x => false);
        checkArr[val] = !checked[val]
        setChecked(checkArr)
        let c = [{ index: val, dataIndex: val }]
        let a = []
        if (checkArr[val])
            a = [{ index: val, dataIndex: val }]
        props.expandHandleChange(c, a);
    }
    const options = {
        filter: true,
        filterType: "dropdown",
        responsive: "standard",
        tableBodyHeight: "500px",
        tableBodyMaxHeight: "",
        customToolbarSelect: () => { },
        selectableRows: false,
        //onRowsSelect: handleChange,
        //selectableRows: "none",
        download: false,
        print: false,
        sortOrder: {
            name: "company_name",
            direction: 'desc'
        },
        expandableRows: true,
        renderExpandableRow: (rowData, rowMeta) => {
            const colSpan = rowData.length + 1;
            let cmp = rowData[0];
           // console.log(cmp)
            return (
                <>
                    <tr>
                        <td colSpan={6}>
                            <TableContainer component={Paper} style={{overflowX: 'initial'}}>
                                <Table style={{ minWidth: "650" }} aria-label="simple table" stickyHeader>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell></TableCell>
                                            <TableCell>Name</TableCell>
                                            <TableCell >Email</TableCell>
                                            <TableCell >Company <br />Admin</TableCell>
                                            <TableCell>Stations</TableCell>
                                            <TableCell >Phone</TableCell>
                                            <TableCell>Approved</TableCell>

                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {expandRows[cmp]?.map((row) => (
                                            <TableRow >
                                                <TableCell component="th" scope="row">
                                                    <Checkbox checked={checked[row.index]} onClick={(e) => handleChange(row.index)} />
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    {row.name}
                                                </TableCell>
                                                <TableCell><a href="#" style={{ color: "blue", whiteSpace: "nowrap" }} onClick={(event) => {
                                                    clickHandler(row.email, row.index);
                                                }}>
                                                    {row.email}</a>
                                                </TableCell>
                                                <TableCell>{row.is_admin}</TableCell>
                                                <TableCell>{row.stations}</TableCell>
                                                <TableCell>{row.phone}</TableCell>
                                                <TableCell>{row.approved}</TableCell>

                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </td>
                    </tr>
                </>
            );
        },
        page: 1
    };
    return (
        <Datatable
            tableData={rows}
            tableColumns={columns}
            tableOptions={options}
        />
    );
}

export default ExpandableUserList;