import { React } from "react";
import { useHistory } from "react-router-dom";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import { Button } from "@material-ui/core";

function FinalPage() {
  const history = useHistory();
  return (
    <div>
      <div className="row">
        <center>
          <div style={{ width: "50%", marginTop: "20%" }}>
            <Alert severity="success">
              <AlertTitle>Success</AlertTitle>
              User Validated — <strong>Email Verified!</strong>
            </Alert>
            <br />
            <Button
              onClick={() => {
                history.push({ pathname: "/" });
              }}
              variant="contained"
              color="primary"
            >
              Go to Login
            </Button>
          </div>
        </center>
      </div>
    </div>
  );
}

export default FinalPage;
