import React from "react";

function Footer() {
  return (
    <div>
      <div className="bg-grey py-4 mt-1 fixed-bottom">
        <div className="row px-3">
          {" "}
          <center>
            <small>
              © DTS, Inc. and its subsidiaries, including iBiquity Digital Corporation. Proprietary and Confidential. All Rights Reserved.
            </small>
          </center>
        </div>
      </div>
    </div>
  );
}

export default Footer;
