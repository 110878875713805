/* eslint-disable new-cap */
import { React, useState, useEffect } from "react";
import Alerts from "./components/alerts/alert";
import BaseRegisterForm from "./components/forms/baseRegisterForm";
import ApiClient from "./apis/apiClient";
import VerifyEmail from "./components/register/verifyEmail";
import Auth from "@aws-amplify/auth";
import RegisterMFA from "./components/register/registerMFA";
import FinalPage from "./components/register/finalPage";
import Footer from "./components/footer/footer";
import Backdrop from "@mui/material/Backdrop";
import SweetAlert from "react-bootstrap-sweetalert";

import registerCognitoUser from "./components/register/registerCognito";

// eslint-disable-next-line require-jsdoc
export default function Register() {
  const [status, setStatus] = useState(0);
  const [alertType, setAlertType] = useState("success");
  const [message, setMessage] = useState("no message");
  const [stage, setStage] = useState(0);
  const [doLogin, setDoLogin] = useState(false);
  const [getMFA, setGetMFA] = useState(false);
  const [name, setName] = useState(null);
  const [email, setEmail] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [password, setPassword] = useState(null);
  const [company, setCompany] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [register, setRegister] = useState(false);
  const [swtAlertTitle, setswtAlertTitle] = useState("");
  const [swtAlertType, setswtAlertType] = useState(true);
  const [regClicked, setRegClicked] = useState(0);
  const [regFailed, setRegFailed] = useState(false);
  const [showAlert, setshowAlert] = useState(false);
  const [phoneError, setPhoneError] = useState("");
  const [MFACode, setMFACode] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState(null);
  useEffect(() => {
    getCompanyData();
  }, []);

  useEffect(() => {
    if (company != null) addNewUserToDB();
  }, [regClicked]);

  const getCompanyData = async () => {
    //console.log("Fetching Company");
    const result = await ApiClient("get", "/getCompanyIdswithName", "");
    if (result.code === 200) {
      setCompany(result.data.result);
    } else {
      //console.log(result);
    }
  };

  const addNewUserToDB = async () => {
    //console.log("Adding User to Database");
    //console.log(phoneNumber);
    const result = await ApiClient("post", "/register", {
      name: name,
      email: email,
      phone: phoneNumber,
      companyname: selectedCompany,
      approved: false,
    });
    //console.log(result);
    if (result.data.status === 203) {
      setMessage(result.data.response);
      setshowAlert(true);
      setswtAlertTitle("Oh No!!!");
      setswtAlertType(false);
    } else if (result.data.status === 201) {
      setRegFailed(true);
      setStatus(1);
      setMessage(result.data.response);
      setshowAlert(true);
      setswtAlertTitle("Good Job!!!");
      setswtAlertType(true);
    } else if (result.data.status === 202) {
      //console.log("Cognito Registration started");
      registerCognitoUser(
        name,
        email,
        phoneNumber,
        password,
        selectedCompany,
        setStage,
        setStatus,
        setMessage,
        setAlertType,
        setRegFailed,
        Auth
      );
    } else if (result.data.status === 404) {
      setRegFailed(true);
      setStatus(1);
      setMessage(result.data.response + " Please Contact Admin");
    }else{
      setRegFailed(true);
      setStatus(1);
      setMessage( " Some error occured");
    }
  };

  return (
    <div>
      <div className="container-fluid px-1 px-md-5 px-lg-1 px-xl-5 py-5 mx-auto">
        <div className="card card0 border-0">
          {showAlert && (
            <SweetAlert
              success
              showCancel
              title={swtAlertTitle}
              cancelBtnText="Reload"
              confirmBtnText="Go to Login"
              onCancel={() => window.location.reload()}
              onConfirm={() => (window.location.href = "/")}
              style={{ color: "black" }}
              focusCancelBtn
            >
              {message} <br />
            </SweetAlert>
          )}
          {status === 1 && (
            <Alerts
              setStatus={setStatus}
              alertType={alertType}
              message={message}
            ></Alerts>
          )}
          <div className="row d-flex">
            <div className="col-lg-6">
              <div className="card1">
                {/* <div className="row">
                  {" "}
                  <img
                    src={window.location.origin + "/img/black.png"}
                    className="logo"
                    alt=""
                  />{" "}
                </div>
          */}
                <div className="row justify-content-center">
                  {" "}
                  <img
                    src={window.location.origin + "/img/banner.jpg"}
                    className="image"
                    alt=""
                    style={{width: "100%", minHeight: "675px" ,height:"auto" }}
                  />{" "}
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="card border-0 px-4 py-5" style={{overflow: 'auto', maxHeight: "650px"}}>
                {stage === 0 && !doLogin && (
                  <BaseRegisterForm
                    setName={setName}
                    setEmail={setEmail}
                    phoneNumber={phoneNumber}
                    setPhoneNumber={setPhoneNumber}
                    setPassword={setPassword}
                    setPhoneError={setPhoneError}
                    phoneError={phoneError}
                    setSelectedCompany={setSelectedCompany}
                    company={company}
                    regClicked={regClicked}
                    regFailed={regFailed}
                    setRegClicked={setRegClicked}
                    setRegister={setRegister}
                    setConfirmPassword={setConfirmPassword}
                    password={password}
                  />
                )}
                {stage === 1 && !doLogin && !getMFA && (
                  <VerifyEmail
                    email={email}
                    password={password}
                    setGetMFA={setGetMFA}
                    setAlertType={setAlertType}
                    setMFACode={setMFACode}
                    setMessage={setMessage}
                  />
                )}
                {getMFA && (
                  <RegisterMFA
                    MFACode={MFACode}
                    setDoLogin={setDoLogin}
                    doLogin={doLogin}
                    email={email}
                    setGetMFA={setGetMFA}
                    setStatus={setStatus}
                    setMessage={setMessage}
                    setAlertType={setAlertType}
                  />
                )}
                {doLogin && <FinalPage />}
              </div>
            </div>
          </div>
        </div>
        <br/>
        <br/>
      </div>
      <Footer />
    </div>
  );
}
