const validateUsernameMain = (value, setName) => {
  let error;
  if (!value || value.length < 2) {
    error = "Please enter a valid name";
  } else {
    setName(value);
  }
  return error;
};

const validateEmailMain = (value, setEmail) => {
  let error;
  if (!value) {
    error = "Please enter your email address";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
    error = "Invalid email address";
  } else {
    setEmail(value);
  }
  return error;
};

const validatePhonenumberMain = (value, setPhoneNumber, setPhoneError) => {
  var pattern = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;
  if (!value) {
    setPhoneError("Please enter phone number");
    return;
  } else if (!pattern.test(value.substring(1))) {
    setPhoneError("Please Enter a valid Phone Number");
    return;
  } else {
    //console.log("Valid Phone Number");
    setPhoneError(null);
    setPhoneNumber(value);
  }
};

const validateCompanyMain = (value, setSelectedCompany, company) => {
  let error;
  if (!value) {
    error = "Please Choose a company";
  } else if (company && company.indexOf(value) === -1) {
    error = "Company Id is not registered with us";
  } else {
    setSelectedCompany(
      value
    );
  }
  return error;
};

const validatePasswordMain = (value, setPassword) => {
  let error;
  let re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;

  if (!value) {
    error = "Please enter your password";
  } else if (value.length < 8) {
    error = "Value must be longer than 8 characters";
  } else if (!re.test(value)) {
    //console.log("Re", re.test(value));
    error = "Password should contain Capital, Small, Number & Special Charater";
  } else {
    setPassword(value);
  }
  return error;
};
const validateConfirmPasswordMain = (value, password, setConfirmPassword) => {
  let error;
  if (!value) {
    error = "Please enter your password to confirm ";
  } else if (value != password) {
    error = "Your password and confirmation password do not match.";
  } else {
    setConfirmPassword(value);
  }
  return error;
};

const validateAuthMain = (value, setAuthenticationCode) => {
  let error;
    if (!value || value.length < 6) {
      error = "Please enter a valid auth code";
    } else {
      setAuthenticationCode(value);
    }
  return error;
};

module.exports = {
  validateUsernameMain,
  validateEmailMain,
  validatePhonenumberMain,
  validateCompanyMain,
  validatePasswordMain,
  validateConfirmPasswordMain,
  validateAuthMain
};
