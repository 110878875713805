import { React, useState, useEffect } from "react";
import validateUser from "../../validations/userSessionValidation";
import RegionSelect from "./regionSelect";
import Cookies from "js-cookie";
import Auth from "@aws-amplify/auth";
import ApiClient from "../../../apis/apiClient";
import logout from "../../validations/logoutUser";
import DataTable from "../../DataTable/dataTable";
import { Button } from "react-bootstrap";
import SendIcon from "@material-ui/icons/Send";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DangerousIcon from "@mui/icons-material/Dangerous";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { Tooltip } from "@mui/material";
import "react-circular-progressbar/dist/styles.css";
import createXml from "../../others/createXML";
import uploadXmlToS3 from "../../others/uploadXMLS3";
import checkPageAccess from "../../others/getPageAccess";

import path from "path";
import axios from "axios";
import MessageSettingsIoTPayload from "./messageSettingsIoT";
import SweetAlert from "react-bootstrap-sweetalert";
import Loader from "../../loader/loader";
function CreateMessageIoTPayload() {
  const [selectOptions, setselectOptions] = useState([]);
  const [selectValuesList, setselectValuesList] = useState([]);
  const [tableData, settableData] = useState([]);
  const [selectedStationIds, setselectedStationIds] = useState([]);
  const [selectedStationCallsign, setselectedStationCallsign] = useState([]);
  const [isAdding, setisAdding] = useState(false);
  const [broadcast, setbroadcast] = useState(true);
  const [date, setDate] = useState(null);
  const [utcdate, setutcDate] = useState(null);
  const [timezone, settimezone] = useState("America/Toronto");
  const [transferVia, settransferVia] = useState("http");
  const [uploadState, setuploadState] = useState(false);
  const [percentage, setpercentage] = useState(0);
  const [uploadId, setuploadId] = useState(null);
  const [xmlUploadState, setxmlUploadState] = useState(false);
  const [xmlLocation, setxmlLocation] = useState([]);
  const [alertMessage, setAlertMessage] = useState("");
  const [alert, setAlert] = useState(false);
  const [severity, setSeverity] = useState("default");
  const [alertTitle, setAlertTitle] = useState("");
  const [confirmAction, setConfirmAction] = useState("");
  const [hrefLink, setHrefLink] = useState("");
  const [loaderType, setLoaderType] = useState("CircularProgress");
  const [redirect, setRedirect] = useState(false);
  const [customerId, setcustomerId] = useState(0);
  const [payloadData, setpayloadData] = useState("");
  const hours = {
    "America/Toronto": 4,
    "America/Chicago": 5,
    "America/Denver": 6,
    "America/Los_Angeles": 7,
    GMT: 0,
  };

  let userAuthToken = null;

  const formatDate = (inp) => {
    return ("0" + inp).slice(-2);
  };

  const dateNow = (val) => {
    let date = new Date(val.toLocaleString("en-US", { timeZone: timezone }));

    return (
      date.getFullYear() +
      "-" +
      formatDate(date.getMonth() + 1) +
      "-" +
      formatDate(date.getDate()) +
      "T" +
      formatDate(date.getHours()) +
      ":" +
      formatDate(date.getMinutes())
    );
  };

  const tableColumns = [
    {
      name: "status",
      label: "Status",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <Tooltip
            title={
              tableData[tableMeta.rowIndex][0]
                ? "Station Available"
                : "Station Down"
            }
            arrow
          >
            <Button variant="outline-light">
              {tableData[tableMeta.rowIndex][0] ? (
                <CheckCircleIcon style={{ fill: "green" }} />
              ) : (
                <DangerousIcon style={{ fill: "red" }} />
              )}
            </Button>
          </Tooltip>
        ),
      },
    },
    {
      name: "Call Sign",
    },
    {
      name: "Frequency",
    },
    {
      name: "Region",
    },
    {
      name: "Bit Rate",
    },
    {
      name: "id",
      options: {
        display: false,
      },
    },
    {
      name: "Remove",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <Button variant="outline-light">
            <DeleteForeverIcon
              style={{ fill: "red" }}
              onClick={() => {
                ////console.log(tableMeta.rowIndex);
                let tableDataTemp = [...tableData];
                let selectedStationIdsTemp = [...selectedStationIds];
                let selectedStationcallsignTemp = [...selectedStationCallsign];
                //let selectedStationIPsTemp = [...selectedStationIPs];
                //let portListTemp = [...portList];
                tableDataTemp.splice(tableMeta.rowIndex, 1);
                selectedStationIdsTemp.splice(tableMeta.rowIndex, 1);
                selectedStationcallsignTemp.splice(tableMeta.rowIndex, 1);
                //selectedStationIPsTemp.splice(tableMeta.rowIndex, 1);
                //portListTemp.splice(tableMeta.rowIndex, 1);
                settableData(tableDataTemp);
                //setselectedStationIPs(selectedStationIPsTemp);
                setselectedStationIds(selectedStationIdsTemp);
                setselectedStationCallsign(selectedStationcallsignTemp);
                //setportList(portListTemp);
              }}
            />
          </Button>
        ),
      },
    },
  ];

  const tableOptions = {
    filter: false,
    filterType: "dropdown",
    print: false,
    search: true,
    selectableRows: "none",
    download: false,
    viewColumns: false,
    responsive: "standard",
    fixedHeader: false,
  };

  useEffect(async () => {
    document.title = "Xperi Payload | Xperi HD Radio IoT";
    setDate(dateNow(new Date()));
    let isValid = await checkPageAccess();
    if (!isValid) {
      // if (!alert("access denied for the url"))
      //   window.location.href = "/unauthorized";
      setAlert(true);
      setSeverity("error");
      setAlertMessage("access denied for the url");
      setAlertTitle("Something Went Wrong");
      setConfirmAction("href");
      setHrefLink("/unauthorized");
      return;
    } else {
      //console.log("You have access");
    }
    if (validateUser()) {
      userAuthToken = Cookies.get("auth");
      //console.log("User Logged in ");
      let getauth = async () => {
        return (await Auth.currentSession()).getAccessToken().getJwtToken();
      };
      getauth().then(async (res) => {
        setRedirect(true);
        const response = await ApiClient("get", "/app/regions", "", {
          "Content-Type": "application/json",
          authorization: Cookies.get("auth"),
        });
        if (response.data.status === 200) {
          setRedirect(false);
          setselectOptions(response.data.result);
        } else if (response.data.status === 403) {
          setAlert(true);
          setSeverity("error");
          setAlertMessage("Session Expired Please Relogin ");
          setAlertTitle("Relogin");
          setConfirmAction("logout");
        } else {
          setAlert(true);
          setSeverity("error");
          setAlertMessage("Some Error Occured");
          setAlertTitle("Something Went Wrong");
          setConfirmAction("logout");
          setHrefLink("");
          setRedirect(false);
        }
      });
    } else {
      //console.log("User Not Logged in");
    }
  }, []);

  useEffect(() => {
    try {
      let input_dt = new Date(date);
      setutcDate(
        new Date(input_dt.setHours(input_dt.getHours() + hours[timezone]))
      );
    } catch (err) {}
  }, [date]);

  useEffect(async () => {
    if (uploadId !== null && !uploadState) {
      let code = 2;
      if (xmlUploadState) code = 5;
      const responseStat = await ApiClient(
        "post",
        "/user/updateMessageStatus",
        {
          id: uploadId,
          code: code,
        }
      );

      if (responseStat.data.status === 200) {
        window.location.replace("/user/message-history");
      } else if (responseStat.data.status === 403) {
        setAlert(true);
        setSeverity("error");
        setAlertMessage("Session Expired Please Relogin ");
        setAlertTitle("Relogin");
        setConfirmAction("logout");
      } else if (responseStat.data.status === 422) {
        setAlert(true);
        setSeverity("error");
        setAlertMessage("Missing required param");
        setAlertTitle("Something Went Wrong");
        setConfirmAction("");
        setHrefLink("");
      } else {
        setAlert(true);
        setSeverity("error");
        setAlertMessage("Some Error Occured");
        setAlertTitle("Something Went Wrong");
        setConfirmAction("");
        setHrefLink("");
      }
    }
  }, [uploadState]);

  useEffect(() => {
    setDate(dateNow(new Date()));
  }, [timezone]);

  useEffect(() => {
    console.log(selectedStationIds);
  }, [selectedStationIds]);

  const handleClick = async () => {
    if (selectValuesList.length === 0) {
      setAlert(true);
      setSeverity("error");
      setAlertMessage("Select a Region from Dropdown");
      setAlertTitle("Something Went Wrong");
      setisAdding(false);
      return;
    }
    if (selectValuesList.length !== 0) {
      setisAdding(true);
      setLoaderType("CircularProgress");
      setpercentage(0);
      setRedirect(true);
      let regions = [];
      selectValuesList.forEach((val) => {
        regions.push(val.name);
      });
      //console.log(regions);

      const response = await ApiClient(
        "post",
        "/app/regions/stations",
        {
          region: regions,
        },
        {
          "Content-Type": "application/json",
          authorization: Cookies.get("auth"),
        }
      );

      if (response.data.status === 200) {
        var joined = tableData;
        let y = selectedStationIds;
        let z = selectedStationCallsign;
        for (let i = 0; i < response.data.result.length; i++) {
          if (
            selectedStationIds.indexOf(response.data.result[i]["id"]) === -1
          ) {
            joined.push([
              response.data.result[i]["status"],
              response.data.result[i]["call_sign"],
              response.data.result[i]["frequency"],
              response.data.result[i]["region"],
              response.data.result[i]["bit_rate"],
              response.data.result[i]["id"],
            ]);
            y.push(response.data.result[i]["id"]);
            z.push(response.data.result[i]["call_sign"]);
          }
        }
        setselectedStationIds([...y]);
        setselectedStationCallsign([...z]);

        //setselectedStationIPs(z);
        // setportList(pl);
        //console.log("Current Station List", joined);
        settableData(joined);
        setisAdding(false);
        setRedirect(false);
      } else if (response.data.status === 403) {
        setAlert(true);
        setSeverity("error");
        setAlertMessage("Session Expired Please Relogin ");
        setAlertTitle("Relogin");
        setConfirmAction("logout");
        setRedirect(false);
      } else if (response.data.status === 422) {
        setAlert(true);
        setSeverity("error");
        setAlertMessage("Missing required param");
        setAlertTitle("Something Went Wrong");
        setConfirmAction("");
        setHrefLink("");
        setRedirect(false);
      } else if (response.data.status === 402) {
        setAlert(true);
        setSeverity("error");
        setAlertMessage("No Records");
        setAlertTitle("Something Went Wrong");
        setConfirmAction("");
        setHrefLink("");
        setRedirect(false);
      } else {
        setAlert(true);
        setSeverity("error");
        setAlertMessage("Some Error Occured");
        setAlertTitle("Something Went Wrong");
        setConfirmAction("");
        setHrefLink("");
        setRedirect(false);
      }
    }
  };

  const submit = async () => {
    if (selectedStationIds.length === 0) {
      setAlert(true);
      setSeverity("error");
      setAlertMessage("Please select an active station from a region");
      setAlertTitle("Something Went Wrong");
      return;
    }
    let today = new Date(
      new Date().toLocaleString("en-US", { timeZone: timezone })
    );
    today = new Date(today.getTime() + 1 * 60000);
    if (!broadcast && new Date(date) < today) {
      setAlert(true);
      setSeverity("error");
      setAlertMessage("Date & Time should be in future");
      setAlertTitle("Something Went Wrong");
      return;
    }
    if(!payloadData){
      setAlert(true);
      setSeverity("error");
      setAlertMessage("No data in payload");
      setAlertTitle("Something Went Wrong");
      return;
    }
    setuploadState(true);
    setLoaderType("CircularProgressbar");
    let schDate = broadcast ? new Date() : new Date(utcdate);
    let year = schDate.getFullYear();
    let month = schDate.getMonth() + 1;
    let _date = schDate.getDate();
    let hour = schDate.getHours();
    let minutes = schDate.getMinutes();

    
    const response = await ApiClient(
      "post",
      "/user/createMessageCustomIoT",
      {
        year: year,
        month: month,
        _date: _date,
        hour: hour,
        minutes: minutes,
        user: Cookies.get("user"),
        destinationstations: selectedStationCallsign,
        destinationstationsIds: selectedStationIds,
        broadcastimmediate: broadcast,
        transferVia: transferVia,
        timezone: timezone,
        version: process.env.REACT_APP_VERSION,
        customerId: customerId,
        payloadData: payloadData,
        transferType: "iot-payload",
      },
      { "Content-Type": "application/json", authorization: Cookies.get("auth") }
    );

    if (response.data.status === 200) {
      //console.log("Upload URL", response.data.data.upload_url);
      let ids = response.data._id;
      setuploadId(ids);
      setpercentage(100);
      const response2 = await ApiClient(
        "post",
        "/user/uploadMetadata",
        {
          recordIds: response.data._id,
          totalRecords: selectedStationIds.length,
        },
        {
          "Content-Type": "application/json",
          authorization: Cookies.get("auth"),
        }
      );

      if (response2.data.status === 200) {
        setuploadState(false);
        setLoaderType("CircularProgress");
        setpercentage(0);
      } else if (response2.data.status === 403) {
        setuploadState(false);
        // setLoaderType("CircularProgress");
        // setpercentage(0)
        setAlert(true);
        setSeverity("error");
        setAlertMessage("Session Expired Please Relogin ");
        setAlertTitle("Relogin");
        setConfirmAction("logout");
      } else if (response2.data.status === 422) {
        setuploadState(false);
        // setLoaderType("CircularProgress");
        // setpercentage(0)
        setAlert(true);
        setSeverity("error");
        setAlertMessage("Missing required param");
        setAlertTitle("Something Went Wrong");
        setConfirmAction("");
        setHrefLink("");
      } else {
        setuploadState(false);
        // setLoaderType("CircularProgress");
        // setpercentage(0)
        setAlert(true);
        setSeverity("error");
        setAlertMessage("Some Error Occured");
        setAlertTitle("Something Went Wrong");
        setConfirmAction("");
        setHrefLink("");
      }
    } else if (response.data.status === 403) {
      setuploadState(false);
      setAlert(true);
      setSeverity("error");
      setAlertMessage("Session Expired Please Relogin ");
      setAlertTitle("Relogin");
      setConfirmAction("logout");
    } else if (response.data.status === 422) {
      setuploadState(false);
      setAlert(true);
      setSeverity("error");
      setAlertMessage("Missing required param");
      setAlertTitle("Something Went Wrong");
      setConfirmAction("");
      setHrefLink("");
    } else {
      setuploadState(false);
      setAlert(true);
      setSeverity("error");
      setAlertMessage("Some Error Occured");
      setAlertTitle("Something Went Wrong");
      setConfirmAction("");
      setHrefLink("");
    }
  };

  const confirmAlert = () => {
    setAlert(false);
    setSeverity("default");
    setAlertTitle("");
    if (confirmAction == "load") {
      window.location.reload();
    } else if (confirmAction == "href") {
      window.location.href = hrefLink;
    } else if (confirmAction === "logout") {
      logout();
    }
  };
  return (
    <div>
      {alert && (
        <SweetAlert type={severity} title={alertTitle} onConfirm={confirmAlert}>
          {alertMessage}
        </SweetAlert>
      )}
      <Loader
        loading={loaderType === "CircularProgressbar" ? uploadState : redirect}
        loaderType={loaderType}
        percentage={percentage}
      ></Loader>
      <div
        className="row d-flex justify-content-center mt-100"
        style={{ marginTop: "100px" }}
      >
        <div className="col-md-10">
          <div className="card" style={{ padding: "10px" }}>
            <h4>Regions</h4>
            <RegionSelect
              selectOptions={selectOptions}
              setselectValuesList={setselectValuesList}
              isAdding={isAdding}
              handleClick={handleClick}
            />
            <div style={{ marginTop: "40px", marginBottom: "40px" }}>
              <DataTable
                tableData={tableData}
                tableColumns={tableColumns}
                tableOptions={tableOptions}
              />
            </div>
            <br />
            <div className="container">
              {date !== null && (
                <div className="row">
                  <MessageSettingsIoTPayload
                    setbroadcast={setbroadcast}
                    date={date}
                    setDate={setDate}
                    utcdate={utcdate}
                    setutcDate={setutcDate}
                    timezone={timezone}
                    settimezone={settimezone}
                    setcustomerId={setcustomerId}
                    setpayloadData={setpayloadData}
                    setAlertMessage={setAlertMessage}
                    setAlert={setAlert}
                    setSeverity={setSeverity}
                    setAlertTitle={setAlertTitle}
                    setConfirmAction={setConfirmAction}
                    setHrefLink={setHrefLink}
                  />
                </div>
              )}
              <div className="row">
                <center>
                  <div style={{ width: "100%", marginTop: "-3%" }}>
                    <Button
                      color="primary"
                      size="medium"
                      fullwidth="false"
                      onClick={() => submit()}
                      disabled={uploadState ? true : false}
                      style={{
                        borderColor: "#4F3493",
                        backgroundColor: "#4F3493",
                      }}
                    >
                      {!uploadState && (
                        <span>
                          Schedule Message <SendIcon />{" "}
                        </span>
                      )}
                      {uploadState && <span>Please Wait.... </span>}
                    </Button>
                  </div>
                </center>
              </div>
            </div>
          </div>
          <br />
          <br />
          <br />
        </div>
      </div>
    </div>
  );
}

export default CreateMessageIoTPayload;
