import Auth from "@aws-amplify/auth";
import Cookies from "js-cookie";

function logout(){
    Auth.signOut().then((res) => {
    }).catch((err)=> {
    });
    Cookies.remove('auth');
    Cookies.remove('t');
        //console.log("Logged out");
        window.location.href = '/'
}

export default logout;