import React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

const bull = (
  <Box
    component="span"
    sx={{ display: "inline-block", mx: "2px", transform: "scale(0.8)" }}
  >
    •
  </Box>
);

const card = (
  <React.Fragment>
    <CardContent>
      <Typography sx={{ fontSize: 30 }} color="text.secondary" gutterBottom>
        About
      </Typography>
      
      <Typography variant="h5" component="div">
        Radio Datacast Application
      </Typography>
      <Typography color="text.secondary">
        <b>Version {process.env.REACT_APP_VERSION}</b>
      </Typography>
      <Typography variant="body2" color="text.secondary">
        Updated on: April 21, 2023
        <br />
        <br />
        &copy; 2023 Xperi Corporation. 
        <br />
        All rights reserved.
        <br />
      </Typography>
    </CardContent>
    <CardActions>
     {/*<Button size="small">Learn More</Button>*/}
    </CardActions>
  </React.Fragment>
);
function About() {
  return (
    <div className="d-lg-flex justify-content-left align-self-center m-5" >
      <div className="container" style={{ marginTop: "70px"}}>
        <div className="row">
          <div className="col-md-6" style={{padding: 0}}>
            <img
              src={window.location.origin + "/img/banner.jpg"}
              className="image"
              alt=""
              style={{ width: "100%", height: "540px" }}
            />
          </div>
          <div className="col-md-6" style={{padding: 0}}>
            <Card variant="outlined" style={{height: "540px"}}>{card}</Card>
          </div>
        </div>
        <br/>
      <br/>
      <br/>
      </div>
    </div>
  );
}

export default About;
