const registerCognitoUser = async (
  name,
  email,
  phone,
  password,
  company,
  setStage,
  setStatus,
  setMessage,
  setAlertType,
  setRegFailed,
  Auth
) => {
  //console.log("Registering User to cognito");
  try {
    await Auth.signUp({
      username: email,
      password: password,
      attributes: {
        name: name,
        email: email,
        phone_number: phone,
        "custom:company": company,
      },
    })
      .then((result) => {
        //console.log(result);
        setStage(1);
        setStatus(1);
        setAlertType("success");
        setMessage(
          "User Registered!  Please check your email and enter the email verification code provided."
        );
      })
      .catch((err) => {
        //console.log(err.message);
        setStatus(1);
        if (String(err.message).includes("email already exists")) {
          Auth.resendSignUp(email);
          setStage(1);
          setRegFailed(true);
          setAlertType("error");
          setMessage(
            err.message + " please validate with the new code sent to email"
          );
        } else {
          setRegFailed(true);
          setAlertType("error");
          setMessage(err.message);
        }
      });
  } catch (err) {
    //console.log(err);
  }
};

export default registerCognitoUser;
