//const awsmobile = require("../../aws-exports");
import AWS from "aws-sdk";
import Auth from "@aws-amplify/auth";
import createXml from "./createXML";

const awsmobile = {
  "aws_project_region": process.env.REACT_APP_AWS_PROJECT_REGION,
  "aws_cognito_identity_pool_id": process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,
  "aws_cognito_region": process.env.REACT_APP_AWS_COGNITO_REGION,
  "aws_user_pools_id": process.env.REACT_APP_AWS_USER_POOLS_ID,
  "aws_user_pools_web_client_id": process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
  "oauth": {},
  "aws_bucket":process.env.REACT_APP_AWS_BUCKET,
}

const region = awsmobile.aws_cognito_region;
const user_pool = awsmobile.aws_user_pools_id;
const identity_pool = awsmobile.aws_cognito_identity_pool_id;

const uploadXmlToS3 = (
  bucket,
  ids,
  xmldata,
  selectedStationIPs,
  portList,
  setuploadState,
  setpercentage
) => {
  //console.log("Upload xml called");
  /*let getauth = async () => {
    return (await Auth.currentSession()).getIdToken().getJwtToken();
  };
  getauth().then(async (res) => {
    let params = {
      Bucket: bucket,
      CreateBucketConfiguration: {
        LocationConstraint: awsmobile.aws_cognito_region,
      },
      ACL: "private",
    };
    // let logins = {};
    // logins["cognito-idp." + region + ".amazonaws.com/" + user_pool] = res;
    // AWS.config.update({
    //   region: region,
    //   credentials: new AWS.CognitoIdentityCredentials({
    //     IdentityPoolId: identity_pool,
    //     Logins: logins,
    //   }),
    // });

   /* let s3 = new AWS.S3({
      apiVersion: "2010-12-01",
      region: "us-west-2",
      accessKeyId: process.env.REACT_APP_ACCESS_KEY,
      secretAccessKey:process.env.REACT_APP_SECRET_KEY
    });
    let temp = [];
    let perc = 0;
    xmldata.recordCount = selectedStationIPs.length;
    xmldata.reschedule = 'N';
    for (let i = 0; i < selectedStationIPs.length; i++) {
      xmldata.hostname = selectedStationIPs[i];
      xmldata.recordIds = ids[i];
      xmldata.port = portList[i];
      let xml_data = createXml(xmldata);
      params.Key = ids[i] + ".xml";
      params.Body = xml_data;
      let resp = await s3.upload(params).promise();
      temp.push(resp.Location);
      perc = perc + 100/selectedStationIPs.length;
      setpercentage(perc);
    }
    setuploadState(false);
  });*/
};

export default uploadXmlToS3;
