import React from "react";
import { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import Switch from "@mui/material/Switch";
import AWS from "aws-sdk";
import encryptHash from "../../validations/general";
import ApiClient from "../../../apis/apiClient";
import {
  Table,
  TableBody,
  TableContainer,
  TableRow,
  TableCell,
  Paper,
  FormControlLabel,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  Input,
  FormHelperText,
} from "@material-ui/core";
import Cookies from "js-cookie";

function MessageSettingsPayload(props) {
  const {
    bitRateTransmitMessage,
    setbitRateTransmitMessage,
    setbroadcast,
    date,
    setDate,
    utcdate,
    minimumBitRate,
    setutcDate,
    timezone,
    setenableEV,
    transferVia,
    settransferVia,
    settimezone,
    setdeviceType,
    setsequenceNo,
    sequenceNo,
  } = props;
  const [yourtimeZone, setyourTimeZone] = useState(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );
  const hours = {
    "America/Toronto": 5,
    "America/Chicago": 6,
    "America/Denver": 7,
    "America/Los_Angeles": 8,
    "GMT": 0
  };
  const [changed, setchanged] = useState(false);
  const [showError, setshowError] = useState(false);
  const [dateTimeError, setdateTimeError] = useState(false);
  const [deviceTypeError, setdeviceTypeError] = useState(false);
  const [seqError, setseqError] = useState(false);
  const [fileError, setfileError] = useState(false);
  const [showBI, setshowBI] = useState(true);
  const [otherDevType, setotherDevType] = useState(false);
  const [allDevices, setallDevices] = useState([]);

  const formatDate = (inp) => {
    return ("0" + inp).slice(-2);
  };
  const getUtcDate = () => {
    return (
      utcdate.getFullYear() +
      "-" +
      formatDate(utcdate.getMonth() + 1) +
      "-" +
      formatDate(utcdate.getDate()) +
      " " +
      utcdate.toLocaleString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      })
    );
  };
  useEffect(() => {
    const tz = document.getElementsByName("timezone")[0].value;
    settimezone(tz);
    const d = document.getElementById("datetime-local").value;
    //console.log("Use Effect", tz, d);
    let x = new Date(d);

    //x.setHours(x.getHours() - parseInt(tz));
    try {
      //setutcDate(x.toISOString());
      //setDate(x.toUTCString());
    } catch (err) {}
  }, [changed]);

  useEffect(async () => {

    let response = await ApiClient(
      "post",
      "/admin/getTableDataDynamoDB",
      {
        tableName: "IotDevice_info",
      },
      {
        "Content-Type": "application/json",
        Authorization: Cookies.get("auth"),
      }
    );
    if (response.data.status === 200) {
      let data = response.data.result;
      let x =[];
      data.map((v) => {
            x.push(v.DeviceID);
          });
          setallDevices(x);
    }
  }, []);

  const uploadPayload = () => {};

  const setTransfer = (e, val) => {
    settransferVia(val);
  };

  const handleChange = (e) => {
    if (e.target.type === "datetime-local") {
      let input_dt = e.target.value;
      input_dt = new Date(input_dt);
      var today = new Date(
        new Date().toLocaleString("en-US", { timeZone: timezone })
      );
      today = new Date(today.getTime() + 1 * 60000);
      if (input_dt < today) {
        setdateTimeError(true);
        setshowError(true);
        return;
      } else {
        if (e.target.id === "datetime-local") {
          setDate(e.target.value);
          setutcDate(
            new Date(input_dt.setHours(input_dt.getHours() + hours[timezone]))
          );
          setdateTimeError(false);
        }
      }
    } else if (e.target.name === "timezone") {
      setchanged(!changed);
    } else if (e.target.name === "deviceType") {
      if (e.target.value.length === 0) {
        setdeviceTypeError(true);
        setshowError(true);
      }
      setdeviceType(e.target.value);
      if (e.target.value === "Others") {
        setotherDevType(true);
      } else {
        setotherDevType(false);
      }
    } else if (e.target.name === "checkedB") {
      setbroadcast(e.target.checked);
      setshowBI(e.target.checked);
    } else if (e.target.name === "evCharge") {
      //console.log(e.target.checked);
      setenableEV(e.target.checked);
    } else if (e.target.name === "customTargetDevice") {
      if (e.target.value.length > 16 || e.target.value.length === 0) {
        setdeviceTypeError(true);
        setshowError(true);
        return;
      } else {
        setdeviceTypeError(false);
        setshowError(false);
        setdeviceType(e.target.value);
      }
    } else if (e.target.name === "seq-no") {
      let data = parseInt(e.target.value);
      if (data < 0) {
        e.target.value = 1;
        data = 1;
      } else if (data > 255) {
        e.target.value = 255;
        data = 255;
      }
      setsequenceNo(data);
    }
  };

  return (
    <div>
      <div className="container shadow-lg p-3 mb-5 bg-white rounded">
        <div className="row">
          <div className="col-md-6" style={{ marginBottom: "15px" }}>
            <h5>Schedule Transmission</h5>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <TableContainer
              component={Paper}
              style={{
                minHeight: "250px",
                borderTop: "1px solid #0000000d",
              }}
            >
              <Table
                size="small"
                aria-label="a dense table"
                style={{ minHeight: "250px" }}
              >
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={2}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="checkedB"
                            color="primary"
                            onChange={handleChange}
                            defaultChecked
                          />
                        }
                        label="Broadcast Immediately"
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow
                    style={
                      showBI ? { pointerEvents: "none", opacity: "0.4" } : {}
                    }
                  >
                    <TableCell style={{ fontSize: 16 }}>Timezone</TableCell>
                    <TableCell>
                      <InputLabel id="tmout">Timezone</InputLabel>
                      <Select
                        labelId="timezone"
                        id="timezone"
                        name="timezone"
                        label="time-zone"
                        fullwidth="true"
                        labelwidth="100"
                        onChange={handleChange}
                        defaultValue="America/Toronto"
                      >
                        <MenuItem value={"America/Toronto"}>ET</MenuItem>
                        <MenuItem value={"America/Chicago"}>CT</MenuItem>
                        <MenuItem value={"America/Denver"}>MT</MenuItem>
                        <MenuItem value={"America/Los_Angeles"}>PT</MenuItem>
                        <MenuItem value={"GMT"}>GMT</MenuItem>
                      </Select>
                      {/*yourtimeZone*/}
                    </TableCell>
                  </TableRow>
                  <TableRow
                    style={
                      showBI ? { pointerEvents: "none", opacity: "0.4" } : {}
                    }
                  >
                    <TableCell style={{ fontSize: 16 }}>
                      Date And Time <br />
                      <p style={{ marginTop: "28px" }}>Broadcast Time in GMT</p>
                    </TableCell>
                    <TableCell>
                      <TextField
                        error={dateTimeError && showError ? true : false}
                        id="datetime-local"
                        name="datetime-local"
                        label="Date Time"
                        type="datetime-local"
                        fullwidth="true"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        value={date}
                        helperText={
                          dateTimeError && showError
                            ? "Date should be in future"
                            : ""
                        }
                        onChange={handleChange}
                      />
                      <p
                        style={{
                          marginTop: "20px",
                          fontSize: "16px",
                        }}
                      >
                        {timezone ? getUtcDate() : ""}
                      </p>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <div className="col-md-6">
            <TableContainer
              component={Paper}
              style={{
                minHeight: "250px",
                borderTop: "1px solid #0000000d",
              }}
            >
              <Table
                size="small"
                aria-label="a dense table"
                style={{ minHeight: "250px" }}
              >
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <InputLabel id="tmout">Target Device</InputLabel>
                      <Select
                        error={deviceTypeError && showError ? true : false}
                        labelId="deviceType"
                        id="deviceType"
                        name="deviceType"
                        label="device-type"
                        fullwidth="true"
                        labelwidth="100"
                        onChange={handleChange}
                        defaultValue="HDIoTDev000"
                        helperText={
                          deviceTypeError && showError
                            ? "Please select device type"
                            : ""
                        }
                      >
                        {allDevices.map((v) => (
                          <MenuItem value={v}>{v}</MenuItem>
                        ))}
                        <MenuItem value={"Others"}>Others</MenuItem>
                      </Select>
                    </TableCell>
                  </TableRow>

                  {otherDevType && (
                    <TableRow>
                      <TableCell>
                        <InputLabel id="tmout">Device Name</InputLabel>
                        <TextField
                          error={deviceTypeError && showError ? true : false}
                          type="text"
                          fullwidth="true"
                          name="customTargetDevice"
                          placeholder="Enter custom target device"
                          onChange={handleChange}
                          helperText={
                            deviceTypeError && showError
                              ? "Input Value should not be more than 16 characters"
                              : ""
                          }
                        />
                      </TableCell>
                    </TableRow>
                  )}
                  <TableRow>
                    <TableCell>
                      <FormControlLabel
                        value="end"
                        control={
                          <Switch
                            id="evCharge"
                            className=""
                            name="evCharge"
                            onChange={handleChange}
                          />
                        }
                        label="Enable EV Charging"
                        labelPlacement="end"
                      />
                    </TableCell>
                  </TableRow>
                  {Cookies.get("t").split(",").indexOf(encryptHash(777)) !==
                    -1 && (
                    <TableRow>
                      <TableCell
                        style={{ fontSize: 16 }}
                        colSpan={2}
                        rowSpan={1}
                      >
                        <InputLabel>Transfer Via</InputLabel>
                        <RadioGroup
                          row
                          aria-label="position"
                          color="secondary  "
                          name="transferVia"
                          fullwidth="true"
                          onChange={setTransfer}
                          defaultValue={"http"}
                        >
                          <FormControlLabel
                            value="http"
                            control={<Radio color="primary" />}
                            label="HTTP"
                            labelPlacement="end"
                          />
                          {/* <FormControlLabel
                          value="ssh"
                          control={<Radio color="primary" />}
                          label="SSH"
                          labelPlacement="end"
                    /> */}
                        </RadioGroup>
                      </TableCell>
                    </TableRow>
                  )}
                  {/*<TableRow>
                    <TableCell>
                      <InputLabel id="seqN">Sequence Number</InputLabel>
                      <TextField
                        error={seqError && showError ? true : false}
                        id="seq-no"
                        name="seq-no"
                        type="number"
                        min="1"
                        fullwidth="true"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        InputProps={{ inputProps: { min: 1, max: 255 } }}
                        defaultValue={sequenceNo}
                        value={sequenceNo}
                        helperText={
                          seqError && showError ? "Sequence number" : ""
                        }
                        onChange={handleChange}
                      />
                    </TableCell>
                      </TableRow>*/}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
        {/* {bitRateTransmitMessage !== "" && (
          <p style={{ color: "red", marginLeft: "51%" }}>
            {bitRateTransmitMessage}{" "}
          </p>
        )} */}
      </div>
    </div>
  );
}

export default MessageSettingsPayload;
