import React from "react";
import { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import Switch from "@mui/material/Switch";
import AWS from "aws-sdk";
import encryptHash from "../../validations/general";
import ApiClient from "../../../apis/apiClient";
import {
  Table,
  TableBody,
  TableContainer,
  TableRow,
  TableCell,
  Paper,
  FormControlLabel,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  Input,
  FormHelperText,
} from "@material-ui/core";
import Cookies from "js-cookie";
import { TextareaAutosize } from "@mui/material";

function MessageSettingsIoTPayload(props) {
  const {
    setbroadcast,
    date,
    setDate,
    utcdate,
    setutcDate,
    timezone,
    settimezone,
    setcustomerId,
    setpayloadData,
    setAlert,
    setAlertTitle,
    setAlertMessage,
    setSeverity,
    setConfirmAction,
    setHrefLink
  } = props;
  const [yourtimeZone, setyourTimeZone] = useState(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );
  const hours = {
    "America/Toronto": 5,
    "America/Chicago": 6,
    "America/Denver": 7,
    "America/Los_Angeles": 8,
    GMT: 0,
  };
  const [changed, setchanged] = useState(false);
  const [showError, setshowError] = useState(false);
  const [dateTimeError, setdateTimeError] = useState(false);
  const [payloadDataError, setpayloadDataError] = useState(false);
  const [fileError, setfileError] = useState(false);
  const [custIdError, setcustIdError] = useState(false);
  const [showBI, setshowBI] = useState(true);
  const [hasuploadPayload, sethasuploadPayload] = useState(false);
  const [allDevices, setallDevices] = useState([]);
  const [fileData, setfileData] = useState(null)
  const formatDate = (inp) => {
    return ("0" + inp).slice(-2);
  };
  const getUtcDate = () => {
    return (
      utcdate.getFullYear() +
      "-" +
      formatDate(utcdate.getMonth() + 1) +
      "-" +
      formatDate(utcdate.getDate()) +
      " " +
      utcdate.toLocaleString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      })
    );
  };
  useEffect(() => {
    const tz = document.getElementsByName("timezone")[0].value;
    settimezone(tz);
    const d = document.getElementById("datetime-local").value;
    //console.log("Use Effect", tz, d);
    let x = new Date(d);
  }, [changed]);

  useEffect(async () => {}, []);


  const handleChange = (e) => {
    if (e.target.type === "datetime-local") {
      let input_dt = e.target.value;
      input_dt = new Date(input_dt);
      var today = new Date(
        new Date().toLocaleString("en-US", { timeZone: timezone })
      );
      today = new Date(today.getTime() + 1 * 60000);
      if (input_dt < today) {
        setdateTimeError(true);
        setshowError(true);
        return;
      } else {
        if (e.target.id === "datetime-local") {
          setDate(e.target.value);
          setutcDate(
            new Date(input_dt.setHours(input_dt.getHours() + hours[timezone]))
          );
          setdateTimeError(false);
        }
      }
    } else if (e.target.name === "timezone") {
      setchanged(!changed);
    } else if (e.target.name === "checkedB") {
      setbroadcast(e.target.checked);
      setshowBI(e.target.checked);
    } else if (e.target.name === "uploadPayload") {
      sethasuploadPayload(e.target.checked);
      if(e.target.checked){
        setpayloadData(fileData);
      }
    } else if (e.target.name === "customerId") {
      //console.log(e.target.checked);
      setcustomerId(e.target.value);
      if (e.target.value === "00"){
        setcustIdError(true);
        setshowError(true);
        return;
      }else{
        setcustIdError(false);
        setshowError(false);
      }
      
    } else if (e.target.name === "payload-data") {
      setpayloadData(e.target.value)
    }else if (e.target.type === "file") {
      setfileData(null);
      let types = ["application/octet-stream", "text/plain"];
      if (
        !types.includes(e.target.files[0].type) &&
        !(
          e.target.files[0].name.endsWith(".txt") ||
          e.target.files[0].name.endsWith(".bin")
        )
      ) {
        //alert("Only Binary and text files allowed to upload");
        setAlert(true);
        setSeverity("error");
        setAlertMessage("Only Binary and text files allowed to upload");
        setAlertTitle("Something Went Wrong");
        setConfirmAction("");
        setHrefLink("");
        e.target.value = "";
        setfileData(null);
        return;
      }
      if (!e.target.files[0]) {
        setfileError(true);
        setfileData(null);
      } else {
        setfileError(false);
      }
      let fSize = 0;
      try {
        fSize = e.target.files[0].size / 1024;
      } catch (err) {
        console.log("File Size error ", err.message);
        setfileData(null);
      }
      if (fSize > 1000000) {
        // setbitRateTransmitMessage(
        //   "File should be less than or equal (1 GB) in Size"
        // );
        setfileData(null);
        return;
      } else if (fSize === 0) {
        //setbitRateTransmitMessage("File Size should not be Zero");
        setfileData(null);
        return;
      } else {
        console.log("Setting new file");
        let read = new FileReader();
        read.readAsText(e.target.files[0],'UTF-8');
        read.onload = function (evt) {
          let data = evt.target.result;
          setfileData(data);
      }
      }
    }
  };
useEffect(() => {
  if(fileData) setpayloadData(fileData);
}, [fileData])

  return (
    <div>
      <div className="container shadow-lg p-3 mb-5 bg-white rounded">
        <div className="row">
          <div className="col-md-6" style={{ marginBottom: "15px" }}>
            <h5>Schedule Transmission</h5>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <TableContainer
              component={Paper}
              style={{
                minHeight: "250px",
                borderTop: "1px solid #0000000d",
              }}
            >
              <Table
                size="small"
                aria-label="a dense table"
                style={{ minHeight: "250px" }}
              >
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={2}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="checkedB"
                            color="primary"
                            onChange={handleChange}
                            defaultChecked
                          />
                        }
                        label="Broadcast Immediately"
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow
                    style={
                      showBI ? { pointerEvents: "none", opacity: "0.4" } : {}
                    }
                  >
                    <TableCell style={{ fontSize: 16 }}>Timezone</TableCell>
                    <TableCell>
                      <InputLabel id="tmout">Timezone</InputLabel>
                      <Select
                        labelId="timezone"
                        id="timezone"
                        name="timezone"
                        label="time-zone"
                        fullWidth={true}
                        labelWidth={100}
                        onChange={handleChange}
                        defaultValue="America/Toronto"
                        sx={{ minWidth: 130 }}
                      >
                        <MenuItem value={"America/Toronto"}>
                          Eastern Time (ET)
                        </MenuItem>
                        <MenuItem value={"America/Chicago"}>
                          Central Time (CT)
                        </MenuItem>
                        <MenuItem value={"America/Denver"}>
                          Mountain Time (MT)
                        </MenuItem>
                        <MenuItem value={"America/Los_Angeles"}>
                          Pacific Time (PT)
                        </MenuItem>
                        <MenuItem value={"GMT"}>
                          Greenwich Mean Time (GMT)
                        </MenuItem>
                      </Select>
                      {/*yourtimeZone*/}
                    </TableCell>
                  </TableRow>
                  <TableRow
                    style={
                      showBI ? { pointerEvents: "none", opacity: "0.4" } : {}
                    }
                  >
                    <TableCell style={{ fontSize: 16 }}>
                      Date And Time <br />
                      <p style={{ marginTop: "28px" }}>Broadcast Time in GMT</p>
                    </TableCell>
                    <TableCell>
                      <TextField
                        error={dateTimeError && showError ? true : false}
                        id="datetime-local"
                        name="datetime-local"
                        label="Date Time"
                        type="datetime-local"
                        fullWidth={true}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        value={date}
                        helpertext={
                          dateTimeError && showError
                            ? "Date should be in future"
                            : ""
                        }
                        onChange={handleChange}
                      />
                      <p
                        style={{
                          marginTop: "20px",
                          fontSize: "16px",
                        }}
                      >
                        {timezone ? getUtcDate() : ""}
                      </p>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <div className="col-md-6">
            <TableContainer
              component={Paper}
              style={{
                minHeight: "250px",
                borderTop: "1px solid #0000000d",
              }}
            >
              <Table
                size="small"
                aria-label="a dense table"
                style={{ minHeight: "250px" }}
              >
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <InputLabel id="tmout">Customer ID</InputLabel>
                      <Select
                        error={custIdError && showError ? true : false}
                        labelId="customerId"
                        id="customerId"
                        name="customerId"
                        label="customerid"
                        fullWidth={true}
                        labelWidth={100}
                        onChange={handleChange}
                        defaultValue="00"
                        helpertext={
                         custIdError && showError
                            ? "Please select device type"
                            : ""
                        }
                      >
                        <MenuItem value={"00"}>
                          Please Select
                        </MenuItem>
                        <MenuItem value={"1"}>
                          <img
                            src={
                              window.location.origin +
                              "/img/tesla_logo_icon.png"
                            }
                            style={{ width: 25, height: 25 }}
                          />
                          &nbsp;&nbsp;Tesla
                        </MenuItem>
                        <MenuItem value={"2"}>
                          <img
                            src={
                              window.location.origin + "/img/ford_logo_icon.png"
                            }
                            style={{ width: 35, height: 45 }}
                          />
                          &nbsp;Ford
                        </MenuItem>
                      </Select>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <FormControlLabel
                        value="end"
                        control={
                          <Checkbox
                            id="uploadPayload"
                            className=""
                            name="uploadPayload"
                            onChange={handleChange}
                          />
                        }
                        label="Check to Upload Payload"
                        labelPlacement="end"
                      />
                    </TableCell>
                  </TableRow>
                  {hasuploadPayload && (
                    <TableRow>
                      <TableCell>
                        <InputLabel style={{ fontSize: 16 }}>
                          Upload Payload
                        </InputLabel>
                        <input
                          type="file"
                          id="payload_file_id"
                          onChange={handleChange}
                          accept=".txt, text/plain, .bin"
                        />
                      </TableCell>
                    </TableRow>
                  )}
                  {!hasuploadPayload && (
                    <TableRow>
                      <TableCell>
                        <InputLabel id="seqN">Payload Contents</InputLabel>
                        <TextareaAutosize
                          error={payloadDataError && showError ? true : false}
                          id="payload-data"
                          name="payload-data"
                          type="textarea"
                          fullWidth={true}
                          helpertext={
                            payloadDataError && showError
                              ? "Please Enter Payload Data"
                              : ""
                          }
                          onChange={handleChange}
                        />
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MessageSettingsIoTPayload;
