import React from "react";
import { useState, useEffect } from "react";
import ApiClient from "../../apis/apiClient";
import MUIDataTable from "mui-datatables";
import { Container } from "react-bootstrap";
import Cookies from "js-cookie";
import logout from "../validations/logoutUser";
import SweetAlert from "react-bootstrap-sweetalert";
import Loader from "../loader/loader";
import {
  unstable_createMuiStrictModeTheme as createMuiTheme,
  MuiThemeProvider,
} from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";
import { Button, Modal, Table } from "react-bootstrap";
import checkPageAccess from "../others/getPageAccess";

const theme = createMuiTheme({
  palette: {
    primary: {
      light: "#757CE8",
      main: "#3F50B5",
      dark: "#002884",
      contrastText: "#fff",
    },
    secondary: {
      light: "#FF7961",
      main: "#F44336",
      dark: "#BA000D",
      contrastText: "#000",
    },
  },
});

function UpdateStationLists() {
  const [tableData, settableData] = useState();
  const [alert, setAlert] = useState(false);
  const [severity, setSeverity] = useState("default");
  const [alertTitle, setAlertTitle] = useState("");
  const [confirmAction, setConfirmAction] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [hrefLink, setHrefLink] = useState("");
  const [loading, setloading] = useState(true);

  useEffect(async () => {
    document.title = "Update Station List | Xperi HD Radio IoT";
    const isValid = await checkPageAccess();
    const result = await ApiClient("get", "/admin/getAllStationData");
    if (result.data.status === 200) {
      settableData(result.data.result);
      console.log(result.data);
    }else {
      setAlert(true);
      setSeverity("error");
      setAlertMessage("Cant update station status");
      setAlertTitle("Something Went Wrong");
      setConfirmAction("");
      setHrefLink("");
      ////console.log("Error in updating station", result.data.data);
    }
  }, []);

  const updateStationList = async (data) => {
    setloading(true);
    const response = await ApiClient("post", "/admin/updateStationList", data, {
      "Content-Type": "application/json",
      authorization: Cookies.get("auth"),
    });
    if (response.data.status === 200) {
      setAlert(true);
      setSeverity("success");
      setAlertMessage("Updated Successfully");
      setAlertTitle("Success");
      setConfirmAction("");
      setHrefLink("");
    } else if (response.data.status === 403) {
      setAlert(true);
      setSeverity("error");
      setAlertMessage("Session Expired Please Relogin ");
      setAlertTitle("Relogin");
      setConfirmAction("logout");
    } else if (response.data.status  === 422) {
      setAlert(true);
      setSeverity("error");
      setAlertMessage("Missing required param");
      setAlertTitle("Something Went Wrong");
      setConfirmAction("");
      setHrefLink("");
    } else {
      setAlert(true);
      setSeverity("error");
      setAlertMessage("Cant update station status");
      setAlertTitle("Something Went Wrong");
      setConfirmAction("");
      setHrefLink("");
      ////console.log("Error in updating station", result.data.data);
    }
    setloading(false);
  };
  const columns = [
    { name: "id" },
    { name: "ip" },
    { name: "port" },
    { name: "call_sign" },
    {
      name: "status",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div>
              {value && <CheckIcon style={{ color: "green" }} />}
              {!value && <CloseIcon style={{ color: "red" }} />}
            </div>
          );
        },
      },
    },
    {
      name: "Update",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <Button
            onClick={() => {
              console.log(tableData[tableMeta.rowIndex]);
              updateStationList(tableData[tableMeta.rowIndex]);
            }}
          >
            Update
          </Button>
        ),
      },
    },
  ];
  const options = {
    filter: true,
    filterType: "dropdown",
    selectableRows: "none",
  };

  const confirmAlert = () => {
    setAlert(false);
    setSeverity("default");
    setAlertTitle("");
    if (confirmAction == "load") {
      window.location.reload();
    } else if (confirmAction == "href") {
      window.location.href = hrefLink;
    } else if (confirmAction === "logout") {
      logout();
    }
  };

  return (
    <div>
      {alert && (
        <SweetAlert type={severity} title={alertTitle} onConfirm={confirmAlert}>
          {alertMessage}
        </SweetAlert>
      )}
      <Container
        maxWidth="lg"
        className="d-flex flex-column min-vh-100"
        style={{ marginTop: "100px" }}
      >
        <Loader loading={loading}></Loader>
        <MuiThemeProvider theme={theme}>
          <MUIDataTable
            title={"Station List"}
            data={tableData}
            columns={columns}
            options={options}
            elevation={1}
          />
        </MuiThemeProvider>
      </Container>
      <br />
      <br />
      <br />
    </div>
  );
}

export default UpdateStationLists;
