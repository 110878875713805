import React from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Slide from "@mui/material/Slide";

function Loader(props) {
  let { open } = props;
  return (
    <Backdrop
    className="rounded"
      sx={{ color: "#000" }}
      open={open}
      style={{ backgroundColor: "rgba(255, 255, 255, 0.3)" }}
    >
      <CircularProgress color="warning" size={50} />
      <Box>
        <Slide direction="left" in={true} mountOnEnter unmountOnExit {...({ timeout: 2000 })}>
          <Typography
            variant="h3"
            color="text.danger"
          >{`Loading...`}</Typography>
        </Slide>
      </Box>
    </Backdrop>
  );
}

export default Loader;
