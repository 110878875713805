import axios from "axios";
import { API_ENDPOINT_URL } from "../constants/constants";

const ApiClient = async (method, endpoint, params, headers) => {
  var response;
  if (method.toLowerCase() === "get") {
    try {
      response = await axios
        .get(API_ENDPOINT_URL + endpoint + "?" + params, {
          headers: headers === null ? {"Content-Type": "application/json"}: headers,
        })
        .then((res) => ({ code: 200, data: res.data }))
        .catch((err) => ({ code: 400, data: err }));
    } catch (error) {
      response = { code: 500, data: error };
    }
  } else if (method.toLowerCase() === "post") {
    try {
      response = await axios
        .post(API_ENDPOINT_URL + endpoint, params, { headers: headers === null ? {"Content-Type": "application/json"}: headers })
        .then((res) => ({ code: 200, data: res.data }))
        .catch((err) => ({ code: 400, data: err }));
    } catch (error) {
      response = { code: 500, data: error };
    }
  }
  return response;
};


export default ApiClient;
