import React from "react";

import MUIDataTable from "mui-datatables";

import {
  unstable_createMuiStrictModeTheme as createMuiTheme,
  MuiThemeProvider,
} from "@material-ui/core/styles";

const theme = createMuiTheme({
  palette: {
    primary: {
      light: "#757CE8",
      main: "#3F50B5",
      dark: "#002884",
      contrastText: "#fff",
    },
    secondary: {
      light: "#FF7961",
      main: "#F44336",
      dark: "#BA000D",
      contrastText: "#000",
    },
  },
});

function DataTable(props) {
    const {tableData, tableColumns, tableOptions} = props;
  return (
    <div>
      <MuiThemeProvider theme={theme}>
        <MUIDataTable
          data={tableData}
          columns={tableColumns}
          options={tableOptions}
        />
      </MuiThemeProvider>
    </div>
  );
}

export default DataTable;
