import Cookies from "js-cookie";
import logout from "./logoutUser";

const validateUser = () => {
    if (!Cookies.get("auth")) {
        logout();
        return false;
    }else {
        return true;
    }
}

export default validateUser;