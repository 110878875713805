import { React, useEffect,useState } from "react";
import { useHistory } from "react-router-dom";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import { Button } from "@material-ui/core";
import ApiClient from "../../apis/apiClient";
import logout from "../validations/logoutUser";
import Cookies from "js-cookie";
import encryptHash from "../validations/general";
import Alerts from "../alerts/display-alert";
function FinalPage(props) {
  const { access_levels } = props;
  const history = useHistory();
  const [alertMessage, setAlertMessage] = useState("");
  const [alert, setAlert] = useState(false);
  const [severity, setSeverity] = useState("");

  //useEffect(() => //console.log(access_levels), [access_levels]);
  useEffect(async () => {
    const resp = await ApiClient(
      "post",
      "/",
      {},
      {
        "Content-Type": "application/json",
        Authorization: Cookies.get("auth"),
      }
    );
    if (resp.data.status !== 200) {
      //if (!alert("Session Expired Relogin ")) logout();
      setAlert(true)
      setSeverity("info")
      setAlertMessage("Session Expired Relogin");
      const timer = setTimeout(() => { setAlert(false); setSeverity(""); logout() }, 1000);

    }
    console.log(Cookies.get("t"), encryptHash(200));
  }, []);

  return (
    <div>
      {alert && (
        <Alerts
          variant="filled"
          severity={severity}
          alertMessage={alertMessage}
          setAlert={setAlert}
          setSeverity={setSeverity}
        ></Alerts>
      )}
      <div className="row">
        <center>
          <div style={{ width: "50%", marginTop: "20%" }}>
            <Alert severity="success">
              <AlertTitle>Success</AlertTitle>
              User Validated — <strong>MFA Verification Success!</strong>
            </Alert>
            <br />
            {/*
            {access_levels.indexOf(encryptHash(200)) != -1 && (
              <Button
                onClick={() => {
                  // this.props.history.push("/user/home");
                  window.open(window.location.href + "user/home", "_blank");
                }}
                variant="contained"
                color="primary"
              >
                Application Console
              </Button>
            )}
            <br />
            <br />
            {access_levels.indexOf(encryptHash(502)) != -1 && (
              <Button
                onClick={() => {
                  // this.props.history.push("/user/home");
                  window.open(window.location.href + "user/xperi-payload", "_blank");
                }}
                variant="contained"
                color="primary"
              >
                Xperi Payload
              </Button>
            )}
            <br />
            <br />
            {(access_levels.indexOf(encryptHash(777)) != -1 ||
              access_levels.indexOf(encryptHash(400)) != -1) && (
                <Button
                  onClick={() => {
                    // this.props.history.push("/admin/users");
                    window.open(window.location.href + "admin/users", "_blank");
                  }}
                  variant="contained"
                  color="primary"
                >
                  Admin Console
                </Button>
              )}
            <br />
            <br />
            {access_levels.indexOf(encryptHash(777)) != -1 && (
              <Button
                onClick={() => {
                  // this.props.history.push("/iot/home");
                  window.open(window.location.href + "iot/home", "_blank");
                }}
                variant="contained"
                color="primary"
              >
                Monitoring Dashboard
              </Button>
            )}
            <br />
              <br />*/}
          </div>
        </center>
      </div>
    </div>
  );
}

export default FinalPage;
