import { React, useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import ApiClient from "../../../apis/apiClient";
import SaveIcon from "@mui/icons-material/Save";
import AddIcon from "@mui/icons-material/Add";
import CancelIcon from "@mui/icons-material/Cancel";
import axios from "axios";
import Cookies from "js-cookie";
import { Button, Modal } from "react-bootstrap";
// import Backdrop from "@mui/material/Backdrop";
// import CircularProgress from "@mui/material/CircularProgress";
//import Loader from "../../loader/loader"
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  makeStyles,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  TableCell,
  Paper,
  FormControlLabel,
  Checkbox,
  TextField,
  Input,
  FormHelperText,
} from "@material-ui/core";
import {
  validateNameMain,
  validateIdMain,
  validateCallSignMain,
  validateFrequencyMain,
  validateBitRateMain,
  validateRegionMain,
  validateIpMain,
  validateRegionNameMain,
  validatePortMain,
  validateDestinationMain,
  validateEmailMain,
} from "../../validations/generalValidations";
import logout from "../../validations/logoutUser";
const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 200,
  },
}));

export default function AddStation(props) {
  const {
    regionArr,
    setRegionArr,
    setAlertMessage,
    setAlert,
    setSeverity,
    setAlertTitle,
    setConfirmAction,
    setHrefLink,
    loading,
    setloading,
  } = props;
  const classes = useStyles();
  const [id, addId] = useState(null);
  const [name, addName] = useState(null);
  const [newRegionName, addRegionName] = useState(null);
  const [region, addRegion] = useState("Select Region");
  const [call_sign, addCallSign] = useState(null);
  const [frequency, addFrequency] = useState(null);
  const [bit_rate, addbitRate] = useState(null);
  const [port, addport] = useState(null);
  const [sshport, addsshport] = useState(null);
  const [adminName, setAdminName] = useState("");
  const [adminEmail, setAdminEmail] = useState("");
  const [destination, addDestination] = useState("");
  const [ipAdd, addIp] = useState(null);
  const [show_error, setShow_error] = useState(false);
  const [show_error2, setShow_error2] = useState(false);
  const [idErr, errId] = useState(true);
  const [nameErr, errName] = useState(true);
  const [newRegionErr, errNewRegion] = useState(true);
  const [regionErr, errRegion] = useState(true);
  const [call_signErr, errCallSign] = useState(true);
  const [frequencyErr, errFrequency] = useState(true);
  const [bit_rateErr, errbitRate] = useState(true);
  const [destErr, setdestErr] = useState(true);
  const [port_err, errporterr] = useState(true);
  const [ssh_port_err, errsshporterr] = useState(true);
  const [admin_name_err, setadmin_name_err] = useState(true);
  const [admin_email_err, setadmin_email_err] = useState(true);
  const [ipErr, errIp] = useState(true);
  const [regionDropdown, showRegionDropdown] = useState(true);
  const [saveRegion, showSaveRegion] = useState(false);
  const [showRegionDelete, setShowRegionDelete] = useState(false);
  const [text, showText] = useState("Add Region");
  const handleCloseModal = () => setShowRegionDelete(false);
  const handleShowModal = () => setShowRegionDelete(true);
  //const [loading, setloading] = useState(false);
  const [regionErrText, setRegionErrText] = useState(
    "Please Select atleast One Region"
  );
  const [nameErrText, setNameErrText] = useState("Please enter a name");
  const [idErrText, setIdErrText] = useState("Please enter Id");
  const [callSignErrText, setCallSignErrText] = useState(
    "Please enter Call Sign"
  );
  const [frequencyErrText, setFrequencyErrText] = useState(
    "Please enter Frequency"
  );
  const [bitRateErrText, setbitRateErrText] = useState("Please Enter Bit Rate");
  const [ipErrText, setIpErrText] = useState("Please enter IP");
  const [portErrText, setPortErrText] = useState("Please enter Port"); //n
  const [sshPortErrText, setSshPortErrText] = useState(
    "Please enter valid SSH Port"
  ); //n
  const [adminNameErrText, setAdminNameErrText] = useState(
    "Please enter Admin Name"
  ); //n
  const [adminEmailErrText, setAdminEmailErrText] = useState(
    "Please enter your email address"
  ); //n

  const [destErrText, setdestErrText] = useState("Please Enter Destination"); //n
  const [newRegionErrText, setNewRegionErrText] = useState(
    "Please enter Region Name"
  );

 

  // const [alertMessage, setAlertMessage] = useState("");
  // const [alert, setAlert] = useState(false);
  // const [severity, setSeverity] = useState("");

  const validateName = (value) => {
    let errorMsg = validateNameMain(value, addName, errName);
    setNameErrText(errorMsg);
  };
  const validateRegionName = (value) => {
    let errorMsg = validateRegionNameMain(value, addRegionName, errNewRegion);
    setNewRegionErrText(errorMsg);
  };
  const validateId = (value) => {
    let errorMsg = validateIdMain(value, addId, errId);
    setIdErrText(errorMsg);
  };
  const validateCallSign = (value) => {
    let errorMsg = validateCallSignMain(value, addCallSign, errCallSign);
    setCallSignErrText(errorMsg);
  };
  const validateFrequency = (value) => {
    let errorMsg = validateFrequencyMain(value, addFrequency, errFrequency);
    setFrequencyErrText(errorMsg);
  };
  const validateBitRate = (value) => {
    let errorMsg = validateBitRateMain(value, addbitRate, errbitRate);
    setbitRateErrText(errorMsg);
  };
  const validateRegion = (value) => {
    let errorMsg = validateRegionMain(value, addRegion, errRegion);
    setRegionErrText(errorMsg);
  };
  const validateIp = (value) => {
    let errorMsg = validateIpMain(value, addIp, errIp);
    return errorMsg;
  };

  const validatePort = (value) => {
    let errorMsg = validatePortMain(value, addport, errporterr);
    setPortErrText(errorMsg);
  };

  const validateSSHPort = (value) => {
    let errorMsg = validatePortMain(value, addsshport, errsshporterr);
    setSshPortErrText(errorMsg);
  };

  const validateDestination = (value) => {
    let errorMsg = validateDestinationMain(value, addDestination, setdestErr);
    setdestErrText(errorMsg);
  };

  const validateAdminName = (value) => {
    let errorMsg = validateNameMain(value, setAdminName, setadmin_name_err);
    setAdminNameErrText(errorMsg);
  };
  const validateAdminEmail = (value) => {
    let errorMsg = validateEmailMain(value, setAdminEmail, setadmin_email_err);
    setAdminEmailErrText(errorMsg);
  };

  const addNewRegion = async () => {
    setloading(true);
    let result = await ApiClient(
      "post",
      "/admin/addRegion",
      {
        regionName: newRegionName,
      },
      {
        "Content-Type": "application/json",
        Authorization: Cookies.get("auth"),
      }
    );
    if (result.data.status === 200) {
      setloading(false);
      //alert("New Region Added");
      setAlert(true);
      setSeverity("success");
      setAlertMessage("New Region Added");
      setAlertTitle("Success");
      setConfirmAction("");
      setHrefLink("");
      let arr = [...regionArr];
      arr.push(newRegionName);
      setRegionArr(arr);
      showText("Add Region");
      showSaveRegion(false);
    } else if (result.data.status === 403) {
      setAlert(true);
      setSeverity("error");
      setAlertMessage("Session Expired Please Relogin ");
      setAlertTitle("Relogin");
      setConfirmAction("logout");
    } else if (result.data.status === 422) {
      setloading(false);
      setAlert(true);
      setSeverity("error");
      setAlertMessage("Missing required param");
      setAlertTitle("Something Went Wrong");
      setConfirmAction("");
      setHrefLink("");
    } else {
      setloading(false);
      setAlert(true);
      setSeverity("error");
      setAlertMessage("Some Error Occured");
      setAlertTitle("Something Went Wrong");
      setConfirmAction("");
      setHrefLink("");
      ////console.log("Error in updating station", result.data.data);
    }
  };
  const newRegion = () => {
    let save = saveRegion;
    if (!save) {
      showText("Save Region");
      showSaveRegion(!save);
    } else {
      setShow_error2(true);
      if (!newRegionErr) {
        handleShowModal();
        // showText("Add Region");
        //   showSaveRegion(!save);
      }
    }
  };
  const cancelRegion = () => {
    showSaveRegion(false);
    errNewRegion(true);
    setShow_error2(false);
    showText("Add Region");
    addRegionName(null);
  };
  const add = async () => {
    setShow_error(true);
    if (
      !(
        idErr ||
        nameErr ||
        regionErr ||
        call_signErr ||
        frequencyErr ||
        bit_rateErr ||
        ipErr ||
        port_err ||
        ssh_port_err ||
        admin_name_err ||
        admin_email_err ||
        destErr
      )
    ) {
      setloading(true);
      let result = await ApiClient(
        "post",
        "/admin/addStation",
        {
          id: id,
          region: region,
          name: name,
          call_sign: call_sign,
          frequency: frequency,
          ip: ipAdd,
          sshport: sshport,
          bit_rate: bit_rate,
          port: port,
          destination: destination,
          admin_name: adminName,
          admin_email: adminEmail,
        },
        {
          "Content-Type": "application/json",
          Authorization: Cookies.get("auth"),
        }
      );
      if (result.data.status === 200) {
        // if (!alert(result.data.result)) location.reload();
        setloading(false);
        setAlert(true);
        setSeverity("success");
        setAlertMessage(result.data.result);
        setAlertTitle("Success");
        setConfirmAction("load");
      } else if (result.data.status === 202) {
        // logout()
        // alert(result.data.result);
        setAlert(true);
        setSeverity("success");
        setAlertMessage(result.data.result);
        setAlertTitle("Success");
        setConfirmAction("logout");
        // const timer = setTimeout(() => { setAlert(false); setSeverity(""); logout(); }, 1000);
      } else if (result.data.status === 403) {
        setAlert(true);
        setSeverity("error");
        setAlertMessage("Session Expired Please Relogin ");
        setAlertTitle("Relogin");
        setConfirmAction("logout");
      } else {
        // logout();
        // alert(result.data.result);
        setloading(false);
        setAlert(true);
        setSeverity("error");
        setAlertMessage(result.data.result);
        setAlertTitle("Something Went Wrong");
        setConfirmAction("");
        setHrefLink("");
      }
    }
  };




  return (
    <div>
      <h5 className="mr-auto">Add New Region / Station</h5>
      <br />
      {saveRegion == false && (
        <b>
          <h5>Select Region</h5>
        </b>
      )}
      {saveRegion == true && (
        <b>
          <p>Add Region</p>
        </b>
      )}
      <div style={{ marginTop: "2px" }}>
        <div className="row">
          <div className="col-md-6 d-flex flex-row">
            {saveRegion == false && (
              <FormControl className={classes.formControl}>
                <InputLabel>Regions</InputLabel>

                <Select
                  className="mt-3 h-75"
                  defaultValue=""
                  onChange={(e) => {
                    addRegion(e.target.value);
                    validateRegion(e.target.value);
                  }}
                >
                  {regionArr.map((val) => (
                    <MenuItem value={val} key={val}>
                      {val}
                    </MenuItem>
                  ))}
                </Select>

                {regionErr && show_error && (
                  <p style={{ color: "#f44336" }}>{regionErrText}</p>
                )}
              </FormControl>
            )}
            {saveRegion === true && (
              <TextField
                required
                className="mt-3 h-75"
                error={newRegionErr && show_error2 ? true : false}
                id="regionName"
                placeholder="Enter Region Name"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => {
                  addRegionName(e.target.value);
                  validateRegionName(e.target.value);
                }}
                helperText={newRegionErr && show_error2 ? newRegionErrText : ""}
              />
            )}
            <Button
              variant={!saveRegion ? "primary" : "success"}
              className="btn btn-primary btn-sm mt-3 ml-3 h-75"
              disabled={false}
              style={{
                backgroundColor: "#4F3493",
                borderColor: "#4F3493",
                minWidth: "114px",
                height: "53px",
              }}
              onClick={() => newRegion()}
            >
              {saveRegion == true && <SaveIcon />}
              {saveRegion == false && <AddIcon />}
              &nbsp;
              {text}
            </Button>
            {saveRegion == true && (
              <Button
                variant="danger"
                className="btn btn-primary btn-sm mt-3 ml-3 h-75"
                disabled={false}
                style={{ minWidth: "114px", height: "53px" }}
                onClick={() => cancelRegion()}
              >
                <CancelIcon /> &nbsp; Cancel
              </Button>
            )}
          </div>
        </div>
        <br />
        <TableContainer
          component={Paper}
          style={{
            minHeight: "250px",
            borderTop: "1px solid #0000000d",
          }}
        >
          <Table
            size="small"
            aria-label="a dense table"
            style={{ minHeight: "250px" }}
          >
            <TableBody>
              <TableRow>
                <TableCell style={{ fontSize: 16 }}>Station Name</TableCell>
                <TableCell>
                  <TextField
                    required
                    error={nameErr && show_error ? true : false}
                    id="name"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e) => {
                      addName(e.target.value);
                      validateName(e.target.value);
                    }}
                    defaultValue={name}
                    helperText={nameErr && show_error ? nameErrText : ""}
                  />
                </TableCell>
                <TableCell style={{ fontSize: 16 }}>Facility ID</TableCell>
                <TableCell>
                  <TextField
                    required
                    error={idErr && show_error ? true : false}
                    id="id"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    type="number"
                    InputProps={{ inputProps: { min: 1 } }}
                    onChange={(e) => {
                      addId(e.target.value);
                      validateId(e.target.value);
                    }}
                    defaultValue={id}
                    helperText={idErr && show_error ? idErrText : ""}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ fontSize: 16 }}>Call Sign</TableCell>
                <TableCell>
                  <TextField
                    required
                    error={call_signErr && show_error ? true : false}
                    id="call_sign"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e) => {
                      addCallSign(e.target.value);
                      validateCallSign(e.target.value);
                    }}
                    defaultValue={call_sign}
                    helperText={
                      call_signErr && show_error ? callSignErrText : ""
                    }
                    // defaultValue="aaaaa"
                  />
                </TableCell>
                <TableCell style={{ fontSize: 16 }}>Frequency</TableCell>
                <TableCell>
                  <TextField
                    required
                    error={frequencyErr && show_error ? true : false}
                    id="frequency"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    type="number"
                    InputProps={{ inputProps: { min: 1 } }}
                    onChange={(e) => {
                      addFrequency(e.target.value);
                      validateFrequency(e.target.value);
                    }}
                    defaultValue={frequency}
                    helperText={
                      frequencyErr && show_error ? frequencyErrText : ""
                    }
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ fontSize: 16 }}>Bitrate (Kbps)</TableCell>
                <TableCell>
                  <TextField
                    required
                    error={bit_rateErr && show_error ? true : false}
                    id="bitRate"
                    type="number"
                    InputProps={{ inputProps: { min: 1 } }}
                    onChange={(e) => {
                      addbitRate(e.target.value);
                      validateBitRate(e.target.value);
                    }}
                    defaultValue={bit_rate}
                    helperText={bit_rateErr && show_error ? bitRateErrText : ""}
                  />
                </TableCell>
                <TableCell style={{ fontSize: 16 }}>
                  IP Address (x.x.x.x)
                </TableCell>
                <TableCell>
                  <TextField
                    required
                    error={ipErr && show_error ? true : false}
                    id="ip"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{ inputProps: { min: 1 } }}
                    onChange={(e) => {
                      addIp(e.target.value);
                      validateIp(e.target.value);
                    }}
                    defaultValue={ipAdd}
                    helperText={ipErr && show_error ? ipErrText : ""}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ fontSize: 16 }}>
                  HTTP Port <br />
                </TableCell>
                <TableCell>
                  <TextField
                    required
                    error={port_err && show_error ? true : false}
                    id="port"
                    type="number"
                    InputProps={{ inputProps: { min: 1 } }}
                    onChange={(e) => {
                      addport(e.target.value);
                      validatePort(e.target.value);
                    }}
                    defaultValue={port}
                    helperText={port_err && show_error ? portErrText : ""}
                  />
                </TableCell>
                <TableCell style={{ fontSize: 16 }}>
                  SSH Port <br />
                </TableCell>
                <TableCell>
                  <TextField
                    required
                    error={port_err && show_error ? true : false}
                    id="sshport"
                    type="number"
                    InputProps={{ inputProps: { min: 1 } }}
                    onChange={(e) => {
                      addsshport(e.target.value);
                      validateSSHPort(e.target.value);
                    }}
                    defaultValue={sshport}
                    helperText={
                      ssh_port_err && show_error ? sshPortErrText : ""
                    }
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ fontSize: 16 }}>
                  Station Administrator Name
                  <br />
                </TableCell>
                <TableCell>
                  <TextField
                    required
                    error={admin_name_err && show_error ? true : false}
                    id="stadmin_name"
                    type="text"
                    InputProps={{ inputProps: { min: 1 } }}
                    onChange={(e) => {
                      setAdminName(e.target.value);
                      validateAdminName(e.target.value);
                    }}
                    defaultValue={adminName}
                    helperText={
                      admin_name_err && show_error ? adminNameErrText : ""
                    }
                  />
                </TableCell>
                <TableCell style={{ fontSize: 16 }}>
                  Station Administrator Email <br />
                </TableCell>
                <TableCell>
                  <TextField
                    required
                    error={admin_email_err && show_error ? true : false}
                    id="destination"
                    type="email"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{ inputProps: { min: 1 } }}
                    onChange={(e) => {
                      setAdminEmail(e.target.value);
                      validateAdminEmail(e.target.value);
                    }}
                    defaultValue={adminEmail}
                    helperText={
                      admin_email_err && show_error ? adminEmailErrText : ""
                    }
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ fontSize: 16 }}>
                  Destination Path <br />
                </TableCell>
                <TableCell>
                  <TextField
                    required
                    error={destErr && show_error ? true : false}
                    id="destination"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{ inputProps: { min: 1 } }}
                    onChange={(e) => {
                      addDestination(e.target.value);
                      validateDestination(e.target.value);
                    }}
                    defaultValue={destination}
                    helperText={destErr && show_error ? destErrText : ""}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <center>
        <div style={{ margin: "15px" }}>
          <Button
            variant="success"
            className="btn btn-primary text-center"
            disabled={saveRegion ? true : false}
            style={{
              backgroundColor: "#4F3493",
              borderColor: "#4F3493",
              minWidth: "114px",
              height: "53px",
            }}
            onClick={() => add()}
          >
            <SaveIcon /> &nbsp; Save New Station
          </Button>
        </div>
      </center>


      <Modal
        show={showRegionDelete}
        onHide={handleCloseModal}
        backdrop="static"
        keyboard={false}
        id="stationStatusModal"
        style={{ marginTop: "5%" }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add New Region </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <p>
              Are you sure you want to add new region{" "}
              <em>
                <b>{newRegionName}</b>
              </em>
            </p>
            <Button
              variant="danger"
              onClick={() => {
                addNewRegion();
                handleCloseModal();
              }}
            >
              YES
            </Button>{" "}
            <Button variant="dark" onClick={() => handleCloseModal()}>
              NO
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      {/* END Save Region Confirmation Modal */}
    </div>
  );
}
