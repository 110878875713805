import React, { useState, useEffect } from "react";
import "./navbar.css";
import { NavbarData } from "./NavBarData";
import MenuIcon from "@mui/icons-material/Menu";
import Avatar from "react-avatar";
import Cookies from "js-cookie";
import encryptHash from "../validations/general";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import logout from "../validations/logoutUser";
import NavDropdown from "react-bootstrap/NavDropdown";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Stack from "@mui/material/Stack";

function NavbarNew(props) {
  const access_levels = Cookies.get("t") ? Cookies.get("t").split(",") : [];
  //const [showSideBar, setshowSideBar] = useState(false);
  //const showSideBarToggle = () => setshowSideBar(!showSideBar);
  const { showSideBar, setshowSideBar, showSideBarToggle } = props;
  const [hideAlert, sethideAlert] = useState(false);
  const logoutfromapp = () => {
    logout();
  };

  useEffect(() => {
    if (window.location.pathname === "/dashboard") setshowSideBar(true);
  }, []);

  return (
    <>
      <div style={{ background: "#F6F6F6" }} className="topNav">
        <div className="sidebartoggleicon togglemenu">
          <MenuIcon
            onClick={showSideBarToggle}
            className="m-4 menuIcon"
            height="100%"
            fontSize="large"
            color="warning"
            id="sideBarIcon"
          >
            {"sicon-" + showSideBar}
          </MenuIcon>
        </div>
        <div>
          <img
            alt=""
            src={window.location.origin + "/img/black.png"}
            width="195"
            height="65"
            className="d-inline-block align-top m-2"
          />
        </div>

{!hideAlert && process.env.REACT_APP_NOTICE_ENABLE === "true" && (

          <Stack className="w-100 h-10" spacing={2} style={{marginRight: '15%', zIndex:1}}>
            <Alert
              severity="warning"
              className="d-flex p-2 m-1 w-100 mh-25"
              onClose={() => {
                sethideAlert(true);
              }}
            >
              <AlertTitle>Scheduled Downtime</AlertTitle>
              Due to scheduled maintenance we will have downtime -{" "}
              <strong>
                Starting Mon 13th Feb 2023 12:00 AM GMT to Tue 14th Feb 2023
                12:00 AM GMT
              </strong>
            </Alert>
          </Stack>
        )}
        <div className="dropdown"></div>
        <div
          className="profile"
          onClick={() => (window.location.href = "/user/profile")}
        >
          <span style={{ float: "right", color: "black" }} className="m-2">
            Welcome, {Cookies.get("user")} &nbsp;&nbsp;
            <Avatar name={Cookies.get("user")} round="50%" size="50" />
          </span>
          <div className="dropdown-content">
            <a href="#">Link 1</a>
            <a href="#">Link 2</a>
            <a href="#">Link 3</a>
          </div>
        </div>
      </div>
      <div className={`sidebar${showSideBar ? " open" : ""}`}>
        <ul className="sidebarlist">
          {NavbarData.filter((val) => {
            return (
              access_levels.indexOf(encryptHash("777")) !== -1 ||
              access_levels.indexOf(encryptHash(val.access)) !== -1 ||
              val.access === "200"
            );
          }).map((val, key) => {
            return (
              <li
                className="row"
                key={key}
                i={window.location.pathname == val.path ? "active" : ""}
                onClick={() => (window.location.href = val.path)}
              >
                <div id="icon">{val.icon}</div>
                <div id="title">{val.title}</div>
              </li>
            );
          })}
          <li
            className="row"
            id="logoutKey"
            key="logout"
            onClick={logoutfromapp}
          >
            <div id="icon">
              <ExitToAppIcon />
            </div>
            <div id="title">Logout</div>
          </li>
          <li className="row" key="1">
            <div id="icon"> </div>
            <div id="title"></div>
          </li>
          <li className="row" key="2">
            <div id="icon"> </div>
            <div id="title"></div>
          </li>
          <li className="row" key="3">
            <div id="icon"> </div>
            <div id="title"></div>
          </li>
        </ul>
      </div>
    </>
  );
}

export default NavbarNew;
