const validateNameMain = (value, addName, errName) => {
  let error="";
  if (!value.trim()) {
    errName(true);
    error="Please enter Name";
  } else if(value.trim().length < 2){
    errName(true);
    error="Value must be longer than 2 characters";
  }
  else {
    addName(value);
    errName(false);
  }
  return error;
};
const validateRegionNameMain = (value, addRegionName, errNewRegion) => {
  let error="";
  if (!value.trim() ) {
    errNewRegion(true);
    error="Please enter Region Name";
  }else if(value.trim().length < 2){
    errNewRegion(true);
    error="Value must be longer than 2 characters";
  }
  else {
    addRegionName(value);
    errNewRegion(false);
  }
  return error;
};
const validateIdMain = (value, addId, errId) => {
  let error="";
  let regex = "^[1-9]d*(.d+)?$";
  if (!value ) {
    errId(true);
    error="Please enter Id";
  }else if(value < 1){
    errId(true);
    error="Please enter value greater than 0";
  }
  else {
    addId(value);
    errId(false);
  }
  return error;
};
const validateCallSignMain = (value, addCallSign, errCallSign) => {
  let error="";
  if (!value) {
    errCallSign(true);
    error="Please enter Call Sign";
  }else if(value.length < 2){
    errCallSign(true);
    error="Value must be longer than 2 characters";
  }
   else {
    addCallSign(value);
    errCallSign(false);
  }
  return error;
};
const validateFrequencyMain = (value, addFrequency, errFrequency) => {
  let error="";
  let regex = "^[1-9]d*(.d+)?$";
  if (!value) {
    errFrequency(true);
    error="Please enter Frequency";
  }else if(value < 1){
    errFrequency(true);
    error="Please enter value greater than 0";
  }
  else {
    addFrequency(value);
    errFrequency(false);
  }
  return error
};
const validateBitRateMain = (value, addbitRate, errbitRate) => {
  console.log(value)
  let error="";
  let regex = "^[1-9]d*(.d+)?$";
  if (!value) {
    console.log(value)
    errbitRate(true);
    error="Please Enter Bit Rate";
  }else if(value < 1){
    console.log(value)
    errbitRate(true);
    error="Please enter value greater than 0";
  }
  else {
    addbitRate(value);
    errbitRate(false);
  }
  return error
};

const validateRegionMain = (value, addRegion, errRegion) => {
  let error="";
  if (!value) {
    errRegion(true);
    error="Please Select atleast One Region";
  }else if(value.length < 2){
    errRegion(true);
    error="Value must be longer than 2 characters";
  }
  else {
    addRegion(value);
    errRegion(false);
  }
};
const validateIpMain = (value, addIp, errIp) => {
  let error="";
  var regEx =
    /^((25[0-5]|(2[0-4]|1\d|[1-9]|)\d)(\.(?!$)|$)){4}$/;
  if (!value ) {
    errIp(true);
    error="Please enter IP";
  }else if(!value.match(regEx)){
    errIp(true);
    error="Please enter valid IP";
  }
   else {
    addIp(value);
    errIp(false);
  }
  return error;
};

const validatePortMain =(value,addport,errporterr)=>{
  let error ="";
  const regEx = /^((6553[0-5])|(655[0-2][0-9])|(65[0-4][0-9]{2})|(6[0-4][0-9]{3})|([1-5][0-9]{4})|([0-5]{0,5})|([0-9]{1,4}))$/gi;
  if (!value ) {
    errporterr(true);
    error="Please enter Port";
  }else if(!value.match(regEx)){
    errporterr(true);
    error="Please enter a valid Port";
  }
   else {
    addport(value);
    errporterr(false);
  }
  return error;
}

const validateDestinationMain =(value,addDestination,setdestErr)=>{
  let error="";
  if (!value.trim()) {
    setdestErr(true);
    error="Please enter Name";
  } else if(value.trim().length < 2){
    setdestErr(true);
    error="Value must be longer than 2 characters";
  }
  else {
    addDestination(value);
    setdestErr(false);
  }
  return error;
}



const validateEmailMain =(value,setAdminEmail,setadmin_email_err)=>{
  let error="";
  if (!value) {
   setadmin_email_err(true);
    error = "Please enter your email address";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
    setadmin_email_err(true);
    error = "Please Enter Vaild email address";
  } else {
    setAdminEmail(value);
    setadmin_email_err(false);
  }
  return error;
}


module.exports = {
  validateNameMain,
  validateIdMain,
  validateCallSignMain,
  validateFrequencyMain,
  validateBitRateMain,
  validateRegionMain,
  validateIpMain,
  validateRegionNameMain,
  validatePortMain,
  validateDestinationMain,
  validateEmailMain
};
