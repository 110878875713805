import MailIcon from "@material-ui/icons/Mail";
import HomeIcon from '@mui/icons-material/Home';
import HistoryIcon from "@material-ui/icons/History";
import DashboardIcon from "@material-ui/icons/Dashboard";
import PersonIcon from "@material-ui/icons/Person";
import BusinessIcon from "@material-ui/icons/Business";
import CellTowerIcon from "@mui/icons-material/CellTower";
import InfoIcon from "@material-ui/icons/Info";
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import React from 'react';

export const NavbarData = [
    {
        title: "Home",
        icon: <HomeIcon/>,
        path:"/dashboard",
        access: "200"
    },
    {
        title: "Schedule Message",
        icon: <MailIcon/>,
        path:"/user/home",
        access: "200"
    },
    {
        title: "Schedule Xperi Payload",
        icon: <ForwardToInboxIcon/>,
        path:"/user/xperi-payload",
        access: "502"
    },
    {
        title: "Schedule Car / IoT Payload",
        icon: <ForwardToInboxIcon/>,
        path:"/user/xperi-iot-payload",
        access: "502"
    },
    {
        title: "Schedule Xperi Payload EVSE",
        icon: <ForwardToInboxIcon/>,
        path:"/user/xperi-payload-evse",
        access: "502"
    },
    {
        title: "Message History",
        icon: <HistoryIcon/>,
        path:"/user/message-history",
        access: "200"
    },
    /*{
        title: "Admin Dashboard",
        icon: <DashboardIcon/>,
        path:"/admin/home",
        access: "400"
    },*/
    {
        title: "Users",
        icon: <PersonIcon/>,
        path:"/admin/users",
        access: "400"
    },
    {
        title: "Companies",
        icon: <BusinessIcon/>,
        path:"/admin/company",
        access: "777"
    },
    {
        title: "Stations",
        icon: <CellTowerIcon/>,
        path:"/admin/region/stations",
        access: "715"
    },
    {
        title: "Systems Dashboard",
        icon: <DashboardIcon/>,
        path:"/admin/system-dashboard",
        access: "777"
    },
    {
        title: "IoT Device Dashboard",
        icon: <HomeIcon/>,
        path:"/iot/home",
        access: "777"
    },
    {
        title: "About Us",
        icon: <InfoIcon/>,
        path:"/user/about",
        access: "200"
    }
];