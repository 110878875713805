import ApiClient from "../../apis/apiClient";
import Cookies from "js-cookie";

const checkPageAccess = async () => {
    const urlpath = window.location.pathname;
    const response = await ApiClient(
      "post",
      "/validatePageAccess",
      { page_id: urlpath },
      { "Content-Type": "application/json", authorization: Cookies.get("auth") }
    );
    console.log("has access from api",response.data.status === 200);
    if (response.data.status === 200) return true;
    else return false;
  };

export default checkPageAccess;