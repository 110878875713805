import { React, useState, useEffect, useRef } from "react";
import { Formik, Form, Field } from "formik";
import Auth from "@aws-amplify/auth";
import ApiClient from "../../apis/apiClient";
import Cookies from "js-cookie";
import axios from "axios";
import { validateAuthMain } from "../validations/basicRegFormValidations";
function VerifyLogin(props) {
  const {
    setStage,
    stage,
    user,
    email,
    setAlertType,
    setMessage,
    setAccess_levels,
    access_levels,
  } = props;
  const [authenticationCode, setAuthenticationCode] = useState(null);
  const [authClicked, setAuthClicked] = useState(false);
  

  const validateAuth = (value) => {
    return validateAuthMain(value, setAuthenticationCode);
  };
  const confirmAuth = async () => {
    // const email  = email;
    setAuthClicked(true);
    let access_token = (await Auth.currentSession())
      .getAccessToken()
      .getJwtToken();
    await Auth.verifyTotpToken(user, authenticationCode)
      .then((res1) => {
        const x = {};
        axios.get("https://www.cloudflare.com/cdn-cgi/trace").then((res) => {
          res.data
            .trim()
            .split("\n")
            .map((e) => {
              if (e.split("=")[0] === "ip" || e.split("=")[0] === "ts") {
                x[e.split("=")[0]] = e.split("=")[1];
              }
              // return;
            });
          x["email"] = email;
          x["access_token"] = access_token;
          const result = ApiClient("post", "/set_login_data", {
            data: x,
          });
          result.then(function (res) {
            console.log(res);
            ////console.log(res.data["access_levels_list"]);
            if (res.code === 200) {
              // setAccess_levels((arr) => [
              //   ...arr,
              //   res.data["access_levels_list"],
              // ]);
              setAccess_levels(res.data["t"]);
              Cookies.set("t", res.data["t"]);
              Cookies.set("auth", res.data["token"]);
              Cookies.set("user", res.data["user"]);
              setAlertType("success");
              setMessage("User MFA Validated Continue Using App");
              setStage(3);
              window.location.href = "/dashboard";
              // if (res.data["t"] == 1)
              //     window.open(window.location.href + "user/home2");
              // else if (access_levels[0] === 777)
              //     window.open(window.location.href + "admin/users");
            } else {
              setAuthClicked(false);
              setAlertType("error");
              setMessage(res.message);
            }
          });
        });
      })
      .catch((err) => {
        setAuthClicked(false);
        setAlertType("error");
        setMessage("Invalid MFA Code");
      });
  };
  return (
    <Formik
      initialValues={{
        name: "",
        authenticationCode: "",
      }}
      onSubmit={confirmAuth}
    >
      {({ errors, touched }) => (
        <Form>
          <div className="row px-3">
            <label className="mb-1">
              <h6 className="mb-0 text-sm">Multifactor Authentication Code</h6>
            </label>
            <Field
              className="mb-4"
              type="text"
              name="authenticationCode"
              autoComplete="off"
              placeholder="Enter authentication code from MFA app"
              validate={validateAuth}
              autoFocus
            />
            {errors.authenticationCode && touched.authenticationCode && (
              <div className="invalid-feedback d-block">
                {errors.authenticationCode}
              </div>
            )}
          </div>

          <div className="row px-3 mb-4"> </div>
          <div className="row  mb-3 px-3">
            {" "}
            <button
              type="submit"
              className="btn btn-primary text-center"
              disabled={authClicked}
            >
              {authClicked ? "Authenticating" : "Authenticate"}
            </button>{" "}
          </div>
          <div className="float-right">
            Lost Device? <a href="/recover-mfa">Recover Here</a>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default VerifyLogin;
