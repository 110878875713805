import { React, useState, useEffect, useLayoutEffect } from "react";
import Alerts from "./components/alerts/alert";
import BaseAppForm from "./components/forms/baseAppForm";
import Footer from "./components/footer/footer";
import Cookies from "js-cookie";
import VerifyLogin from "./components/login/verifyLogin";
import FinalPage from "./components/login/finalPage";
export default function AppCopy() {
  const [alertType, setAlertType] = useState("success");
  const [message, setMessage] = useState(null);
  const [stage, setStage] = useState(0);
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [loginPressed, setLogginPressed] = useState(0);
  const [validateMessage, setValidateMessage] = useState(null);
  const [user, setUser] = useState(null);
  const [access_levels, setAccess_levels] = useState(0);
  useLayoutEffect(() => {
    if (Cookies.get("auth")) {
      window.location.href = "/dashboard";
      setStage(3);
      setAccess_levels(Cookies.get("t").split(','));
    }
  }, []);
  return (
    <div>
      <div className="container-fluid px-1 px-md-5 px-lg-1 px-xl-5 py-5 mx-auto">
        <div className="card card0 border-0">
          {validateMessage === 1 && (
            <Alerts
              setStatus={setValidateMessage}
              alertType={alertType}
              message={message}
            ></Alerts>
          )}
          <div className="row d-flex">
            <div className="col-lg-6">
              <div className="card1">
               { /* <div className="row">
                  {" "}
                  <img
                    src={window.location.origin + "/img/black.png"}
                    className="logo"
                    alt=""
                  />{" "}
                </div>
                 */ }
                <div className="row justify-content-center">
                  {" "}
                  <img
                    src={window.location.origin + "/img/banner.jpg"}
                    className="image"
                    alt=""
                    style={{width: "100%", height:"540px" }}
                  />{" "}
                </div>
              </div>
            </div>
            <div className="col-lg-6" style={{overflow: 'auto', maxHeight: "530px"}}>
              {stage === 0 && (
                <div className="card2 card border-0 px-4 py-5">
                  <BaseAppForm
                    setEmail={setEmail}
                    email={email}
                    setPassword={setPassword}
                    password={password}
                    setValidateMessage={setValidateMessage}
                    setLogginPressed={setLogginPressed}
                    setStage={setStage}
                    setUser={setUser}
                    setAlertType={setAlertType}
                    setMessage={setMessage}
                    loginPressed={loginPressed}
                    stage={stage}
                  />
                </div>
              )}
              {stage === 1 && (
                <div className="card2 card border-0 px-4 py-5">
                  <VerifyLogin
                    setStage={setStage}
                    stage={stage}
                    user={user}
                    email={email}
                    setAlertType={setAlertType}
                    setMessage={setMessage}
                    setAccess_levels={setAccess_levels}
                    access_levels={access_levels}
                  />
                </div>
              )}
              {stage === 3 && (
                <div className="card1 pb-5">
                  <FinalPage access_levels={access_levels} />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
