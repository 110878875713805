import React from 'react'
import { useState, useEffect } from 'react'
import checkPageAccess from '../others/getPageAccess';
import SweetAlert from "react-bootstrap-sweetalert";
export default function AdminHome() {
  const [alertMessage, setAlertMessage] = useState("");
  const [alert, setAlert] = useState(false);
  const [severity, setSeverity] = useState("default");
  const [alertTitle, setAlertTitle] = useState("");
  const [confirmAction, setConfirmAction] = useState("");
  const [hrefLink, setHrefLink] = useState("");
  useEffect(async () => {
    document.title = 'Home  | Xperi HD Radio IoT';
    let isValid = await checkPageAccess();
    if (!isValid) {
      // if(!alert('access denied for the url')) window.location.href = "/unauthorized";
      setAlert(true)
      setSeverity("error")
      setAlertMessage("access denied for the url");
      setAlertTitle("Something Went Wrong");
      setConfirmAction("href");
      setHrefLink("/unauthorized");
      return;
    } else {
      //console.log("You have access");
    }
  }, [])
  const confirmAlert = () => {
    setAlert(false);
    setSeverity("default");
    setAlertTitle("");
    if (confirmAction == "load") {
      window.location.reload();
    } else if (confirmAction == "href") {
      window.location.href = hrefLink;
    }
  }
  return (
    <div style={{marginTop:'300px'}}>
      {alert && (
       <SweetAlert type={severity} title={alertTitle} onConfirm={confirmAlert}>
       {alertMessage}
     </SweetAlert>
      )}
      <div className="alert alert-danger m-5">
        <h1>TODO:</h1>
        <ol>
          <li>Daily Activity</li>
          <li>Plots having all user summary</li>
          <li>Station updates</li>
          <li>Notifications</li>
        </ol>
      </div>
    </div>
  )
}
