import { React, useState, useEffect } from "react";
import { DateTime } from "luxon";
import validateUser from "../../validations/userSessionValidation";
import RegionSelect from "./regionSelect";
import Cookies from "js-cookie";
import Auth from "@aws-amplify/auth";
import ApiClient from "../../../apis/apiClient";
import logout from "../../validations/logoutUser";
import DataTable from "../../DataTable/dataTable";
import MessageSettings from "./messageSettings";
// import Backdrop from "@mui/material/Backdrop";
import { Button } from "react-bootstrap";
import SendIcon from "@material-ui/icons/Send";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DangerousIcon from "@mui/icons-material/Dangerous";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { Tooltip } from "@mui/material";
import uploadToS3 from "../../others/uploadS3";
// import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
// import CircularProgress from "@mui/material/CircularProgress";
import "react-circular-progressbar/dist/styles.css";
import encryptHash from "../../validations/general";
import checkPageAccess from "../../others/getPageAccess";
import axios from "axios";
import { FormControl, Select, MenuItem } from "@material-ui/core";
// import Alert from '@mui/material/Alert';
// import Stack from '@mui/material/Stack';

import SweetAlert from "react-bootstrap-sweetalert";
import Loader from "../../loader/loader";

function CreateMessageNew() {
  const [selectOptions, setselectOptions] = useState([]);
  const [selectValuesList, setselectValuesList] = useState([]);
  const [tableData, settableData] = useState([]);
  const [selectedStationIds, setselectedStationIds] = useState([]);
  const [selectedStationCallsign, setselectedStationCallsign] = useState([]);
  /*const [selectedStationIPs, setselectedStationIPs] = useState([]);
  const [portList, setportList] = useState([]);*/
  const [isAdding, setisAdding] = useState(false);
  const [broadcast, setbroadcast] = useState(false);
  const [bitRateTransmitMessage, setbitRateTransmitMessage] = useState("");
  const [minimumBitRate, setminimumBitRate] = useState(-1);
  const [date, setDate] = useState(null);
  const [utcdate, setutcDate] = useState(null);
  const [timezone, settimezone] = useState("America/Toronto");
  const [file, setFile] = useState(null);
  //const [fileObj, setFileObj] = useState(null);
  //const [fileBlob, setFileBlob] = useState(null);
  const [percentage, setpercentage] = useState(0);
  const [uploadState, setuploadState] = useState(false);
  const [uploadSuccess, setSuccess] = useState(false);
  const [uploadId, setuploadId] = useState([]);
  const [redirect, setRedirect] = useState(false);
  const [transferVia, settransferVia] = useState("http");
  const [alertMessage, setAlertMessage] = useState("");
  const [alert, setAlert] = useState(false);
  const [severity, setSeverity] = useState("default");
  const [alertTitle, setAlertTitle] = useState("");
  const [confirmAction, setConfirmAction] = useState("");
  const [hrefLink, setHrefLink] = useState("");
  const [loaderType, setLoaderType] = useState("CircularProgress");
  const [repeatCount, setrepeatCount] = useState(1);
  const [repeatInterval, setrepeatInterval] = useState(0);
  const [showRepeat, setshowRepeat] = useState(false);
  const [doFraming, setdoFraming] = useState(false);
  const [dataFolder, setdataFolder] = useState([]);

  const hours = {
    "America/Toronto": 4,
    "America/Chicago": 5,
    "America/Denver": 6,
    "America/Los_Angeles": 7,
    GMT: 0,
  };
  let userAuthToken = null;

  const access_levels = Cookies.get("t") ? Cookies.get("t").split(",") : [];

  const formatDate = (inp) => {
    return ("0" + inp).slice(-2);
  };

  const dateNow = (val) => {
    let date = new Date(val.toLocaleString("en-US", { timeZone: timezone }));
    return (
      date.getFullYear() +
      "-" +
      formatDate(date.getMonth() + 1) +
      "-" +
      formatDate(date.getDate()) +
      "T" +
      formatDate(date.getHours()) +
      ":" +
      formatDate(date.getMinutes())
    );
  };

  const tableColumns = [
    {
      name: "status",
      label: "Status",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <Tooltip
            title={
              tableData[tableMeta.rowIndex][0]
                ? "Station Available"
                : "Station Down"
            }
            arrow
          >
            <Button variant="outline-light">
              {tableData[tableMeta.rowIndex][0] ? (
                <CheckCircleIcon style={{ fill: "green" }} />
              ) : (
                <DangerousIcon style={{ fill: "red" }} />
              )}
            </Button>
          </Tooltip>
        ),
      },
    },
    {
      name: "Call Sign",
    },
    {
      name: "Frequency",
    },
    {
      name: "Region",
    },
    {
      name: "Bit Rate",
    },
    {
      name: "id",
      options: {
        display: false,
      },
    },
    {
      name: "Data Folder",
      options: {
        display: access_levels.indexOf(encryptHash("777")) !== -1 ,
        customBodyRender: (value, tableMeta, updateValue) => (
          <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              defaultValue={"DataB"}
              onChange={(e) => {
                let tempDataFolder = dataFolder;
                tempDataFolder[tableMeta.rowIndex] = e.target.value;
                setdataFolder([...tempDataFolder]);
              }}
              label="Folder"
            >
              <MenuItem value="DataB">
                <em>DataB</em>
              </MenuItem>
              <MenuItem value="DataE">DataE</MenuItem>
              <MenuItem value="DataH">DataH</MenuItem>
              <MenuItem value="RDC_SERVICE">RDC_SERVICE</MenuItem>
              <MenuItem value="RDC_INFO">RDC_INFO</MenuItem>
              <MenuItem value="RDC_DATA_A">RDC_DATA_A</MenuItem>
              <MenuItem value="RDC_DATA_B">RDC_DATA_B</MenuItem>
              <MenuItem value="RDC_AUTO_A">RDC_AUTO_A</MenuItem>
              <MenuItem value="RDC_AUTO_B">RDC_AUTO_B</MenuItem>
              <MenuItem value="RDC_UTIL_A">RDC_UTIL_A</MenuItem>
              <MenuItem value="RDC_UTIL_B">RDC_UTIL_B</MenuItem>
            </Select>
          </FormControl>
        ),
      },
    },
    {
      name: "Remove",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <Button variant="outline-light">
            <DeleteForeverIcon
              style={{ fill: "red" }}
              onClick={() => {
                ////console.log(tableMeta.rowIndex);
                let tableDataTemp = [...tableData];
                let selectedStationIdsTemp = [...selectedStationIds];
                let selectedStationcallsignTemp = [...selectedStationCallsign];
                let dataFolderTemp = [...dataFolder];
                //let selectedStationIPsTemp = [...selectedStationIPs];
                //let portListTemp = [...portList];
                tableDataTemp.splice(tableMeta.rowIndex, 1);
                selectedStationIdsTemp.splice(tableMeta.rowIndex, 1);
                selectedStationcallsignTemp.splice(tableMeta.rowIndex, 1);
                dataFolderTemp.splice(tableMeta.rowIndex, 1);
                //selectedStationIPsTemp.splice(tableMeta.rowIndex, 1);
                //portListTemp.splice(tableMeta.rowIndex, 1);
                settableData(tableDataTemp);
                //setselectedStationIPs(selectedStationIPsTemp);
                setselectedStationIds(selectedStationIdsTemp);
                setselectedStationCallsign(selectedStationcallsignTemp);
                setdataFolder(dataFolderTemp);
                //setportList(portListTemp);
              }}
            />
          </Button>
        ),
      },
    },
  ];

  const tableOptions = {
    filter: false,
    filterType: "dropdown",
    print: false,
    search: true,
    selectableRows: "none",
    download: false,
    viewColumns: false,
    responsive: "standard",
    fixedHeader: false,
    // onRowsDelete: (event, rowData) => {
    //   handleClickedDeleteButton(event, rowData);
    // },
    //selectableRows:'single',
  };

  useEffect(async () => {
    document.title = "Schedule Message | Xperi HD Radio IoT";
    setDate(dateNow(new Date()));
    let isValid = await checkPageAccess();
    if (!isValid) {
      // if (!alert("access denied for the url"))
      //  window.location.href = "/unauthorized";
      setAlert(true);
      setSeverity("error");
      setAlertMessage("Access denied for the url");
      setAlertTitle("Something Went Wrong");
      setConfirmAction("href");
      setHrefLink("/unauthorized");
      return;
    } else {
      //console.log("You have access");
    }
    if (validateUser()) {
      userAuthToken = Cookies.get("auth");
      let getauth = async () => {
        return (await Auth.currentSession()).getAccessToken().getJwtToken();
      };
      getauth()
        .then(async (res) => {
          setRedirect(true);
          const response = await ApiClient("get", "/app/regions", "", {
            "Content-Type": "application/json",
            authorization: Cookies.get("auth"),
          });
          //console.log("Regions Resp Status ", response.data);
          if (response.data.status === 200) {
            setRedirect(false);
            setselectOptions(response.data.result);
          } else if (response.data.status === 403) {
            setAlert(true);
            setSeverity("error");
            setAlertMessage("Session Expired Please Relogin ");
            setAlertTitle("Relogin");
            setConfirmAction("logout");
          } else {
            //logout();
            setRedirect(false);
            setAlert(true);
            setSeverity("error");
            setAlertMessage("Some Error Occured");
            setAlertTitle("Something Went Wrong");
            setConfirmAction("logout");
            setHrefLink("");
          }
        })
        .catch((err) => {
          logout();
          //console.log("Error in login",err.message);
        });
    } else {
      //console.log("User Not Logged in");
    }
  }, []);

  useEffect(() => {
    console.log(dataFolder);
  }, [dataFolder])
  

  useEffect(() => {
    console.log("Selected Ids", selectedStationIds);
  }, [selectedStationIds]);
  useEffect(() => {
    ////console.log("Table ", tableData);
  }, [tableData]);
  useEffect(() => {
    ////console.log("Table ", tableData);
    //console.log("Your time zone",timezone);
    setDate(dateNow(new Date()));
  }, [timezone]);

  useEffect(() => {
    try {
      let input_dt = new Date(date);
      setutcDate(
        new Date(input_dt.setHours(input_dt.getHours() + hours[timezone]))
      );
    } catch (err) {}
  }, [date]);

  useEffect(() => {
    if (file === null) return;
    //createImage(file[0]);
    //console.log("file changed called");
  }, [file]);

  useEffect(async () => {
    //console.log("Upload State ",percentage, uploadState);
    if (percentage === 100 && !uploadState) {
      let code;
      //console.log("Upload State ",percentage, uploadState);
      setLoaderType("CircularProgress");
      setRedirect(true);
      if (!uploadSuccess) code = 5;
      else code = 2;
      //console.log("Upload State Code", code);

      const responseStat = await ApiClient(
        "post",
        "/user/updateMessageStatus",
        {
          id: uploadId,
          code: code,
        },
        {
          "Content-Type": "application/json",
          authorization: Cookies.get("auth"),
        }
      );
      //console.log("Upload Status", responseStat);
      if (responseStat.code === 200) {
        //console.log("Upload Finished", uploadState);
        window.location.replace("/user/message-history");
      } else if (responseStat.data.status === 403) {
        setAlert(true);
        setSeverity("error");
        setAlertMessage("Session Expired Please Relogin ");
        setAlertTitle("Relogin");
        setConfirmAction("logout");
      } else if (responseStat.data.status === 422) {
        setAlert(true);
        setSeverity("error");
        setAlertMessage("Missing required param");
        setAlertTitle("Something Went Wrong");
        setConfirmAction("");
        setHrefLink("");
      } else {
        setAlert(true);
        setSeverity("error");
        setAlertMessage("Some Error Occured");
        setAlertTitle("Something Went Wrong");
        setConfirmAction("");
        setHrefLink("");
      }
    }
  }, [uploadState]);
  //add regions to datatable
  const handleClick = async () => {
    setbitRateTransmitMessage("");

    if (selectValuesList.length === 0) {
      setAlert(true);
      setSeverity("error");
      setAlertMessage("Select a Region from Dropdown");
      setAlertTitle("Something Went Wrong");
      setisAdding(false);
      return;
    }
    if (selectValuesList.length !== 0) {
      setisAdding(true);
      setLoaderType("CircularProgress");
      setpercentage(0);
      setRedirect(true);
      let regions = [];
      selectValuesList.forEach((val) => {
        regions.push(val.name);
      });
      //console.log(regions);

      const response = await ApiClient(
        "post",
        "/app/regions/stations",
        {
          region: regions,
        },
        {
          "Content-Type": "application/json",
          authorization: Cookies.get("auth"),
        }
      );

      if (response.data.status === 200) {
        var joined = tableData;
        let y = selectedStationIds;
        let z = selectedStationCallsign;
        let p = dataFolder;
        // let z = selecetdStationIPs;
        //let pl = portList;
        let minBitRate = 100;
        for (let i = 0; i < response.data.result.length; i++) {
          //console.log("ITR:", i, "Seleted Station Ids", y);
          if (
            selectedStationIds.indexOf(response.data.result[i]["id"]) === -1
          ) {
            joined.push([
              response.data.result[i]["status"],
              response.data.result[i]["call_sign"],
              response.data.result[i]["frequency"],
              response.data.result[i]["region"],
              response.data.result[i]["bit_rate"],
              response.data.result[i]["id"],
            ]);
            //if (response.data.result[i]["status"]) {
            y.push(response.data.result[i]["id"]);
            z.push(response.data.result[i]["call_sign"]);
            p.push(response.data.result[i]["data_service"]);
            //z.push(response.data.result[i]["ip"]);
            //pl.push(response.data.result[i]["port"]);
            //}
          }
          minBitRate =
            minBitRate > response.data.result[i]["bit_rate"]
              ? response.data.result[i]["bit_rate"]
              : minBitRate;
          ////console.log(this.state.selectedStaions)
        }
        setselectedStationIds([...y]);
        setselectedStationCallsign([...z]);
        setdataFolder([...p]);
        //setselectedStationIPs(z);
        //setportList(pl);
        //console.log("Current Station List", joined);
        settableData(joined);
        setminimumBitRate(minBitRate);
        setisAdding(false);
        setRedirect(false);
        setdoFraming(response.data.framing);
      } else if (response.data.status === 401) {
        //alert("No Stations assigned in this regions");
        setisAdding(false);
        setAlert(true);
        setSeverity("error");
        setAlertMessage("No Stations assigned in this regions");
        setAlertTitle("Something Went Wrong");
        setRedirect(false);
      } else if (response.data.status === 403) {
        setisAdding(false);
        setAlert(true);
        setSeverity("error");
        setAlertMessage("Session Expired Please Relogin ");
        setAlertTitle("Relogin");
        setConfirmAction("logout");
      } else if (response.data.status === 422) {
        setisAdding(false);
        setAlert(true);
        setSeverity("error");
        setAlertMessage("Missing required param");
        setAlertTitle("Something Went Wrong");
        setConfirmAction("");
        setHrefLink("");
        setRedirect(false);
      } else if (response.data.status === 402) {
        setisAdding(false);
        setAlert(true);
        setSeverity("error");
        setAlertMessage("No Records");
        setAlertTitle("Something Went Wrong");
        setConfirmAction("");
        setHrefLink("");
        setRedirect(false);
      } else {
        setisAdding(false);
        setAlert(true);
        setSeverity("error");
        setAlertMessage("Some Error Occured");
        setAlertTitle("Something Went Wrong");
        setConfirmAction("");
        setHrefLink("");
        setRedirect(false);
      }
    }
  };

  const submit = async () => {
    
    if (showRepeat && repeatInterval === 0) {
      setAlert(true);
      setSeverity("error");
      setAlertMessage(
        "Please deselect repeat sending messages or enter appropriate values for interval"
      );
      setAlertTitle("Something Went Wrong");
      return;
    }
    if (selectedStationIds.length === 0) {
      // alert("Please Select an active Station from a region");
      setAlert(true);
      setSeverity("error");
      setAlertMessage("Please select an active station from a region");
      setAlertTitle("Something Went Wrong");
      return;
    }
    if (!file) {
      // alert("Please Select a file for upload");
      setAlert(true);
      setSeverity("error");
      setAlertMessage("Please Select a file for upload");
      setAlertTitle("Something Went Wrong");
      return;
    }
    let types = ["application/octet-stream", "text/plain"];
    if (
      !types.includes(file[0].type) &&
      !(file[0].name.endsWith(".txt") || file[0].name.endsWith(".bin"))
    ) {
      //alert("Only Binary and text files allowed to upload");
      setAlert(true);
      setSeverity("error");
      setAlertMessage("Only Binary and text files allowed to upload");
      setAlertTitle("Something Went Wrong");
      return;
    }
    let today = new Date(
      new Date().toLocaleString("en-US", { timeZone: timezone })
    );
    today = new Date(today.getTime() + 1 * 60000);
    if (!broadcast && new Date(date) < today) {
      //alert("DateTime should be in future ");
      setAlert(true);
      setSeverity("error");
      setAlertMessage("Date & Time should be in future");
      setAlertTitle("Something Went Wrong");
      return;
    }
    setuploadState(true);
    setLoaderType("CircularProgressbar");
    //console.log("Submit Clicked", selectedStationIPs);
  
    const response = await ApiClient(
      "post",
      "/user/createMessage",
      {
        user: Cookies.get("user"),
        scheduletime: utcdate.toString().substring(0, 24),
        filename: file[0].name,
        filesize: file[0].size / 1024,
        destinationstations: selectedStationCallsign,
        destinationstationsIds: selectedStationIds,
        datafolders: dataFolder,
        broadcastimmediate: broadcast,
        timezone: timezone,
        transferVia: transferVia,
        version: process.env.REACT_APP_VERSION,
        contentType: file[0].type,
        repeatCount: repeatCount,
        repeatInterval: repeatInterval,
        doFraming: doFraming,
      },
      { "Content-Type": "application/json", authorization: Cookies.get("auth") }
    );

    //console.log(response.data);
    if (response.data.status === 403) {
      setAlert(true);
      setSeverity("error");
      setAlertMessage("Session Expired Please Relogin ");
      setAlertTitle("Relogin");
      setConfirmAction("logout");
    }
    if (response.data.status === 422) {
      setAlert(true);
      setSeverity("error");
      setAlertMessage("Missing required param");
      setAlertTitle("Something Went Wrong");
      setConfirmAction("");
      setHrefLink("");
      setuploadState(false);
      // setLoaderType("CircularProgress");
      // setpercentage(0);
      setuploadState(false);
      return;
    }
    if (
      response.data.status !== 200 &&
      response.data.status !== 403 &&
      response.data.status !== 422
    ) {
      // alert("Error while creating message : " + response.data.message);
      setAlert(true);
      setSeverity("error");
      setAlertMessage(
        "Error while creating message : " + response.data.message
      );

      setAlertTitle("Something Went Wrong");
      setuploadState(false);

      return;
    }
    setuploadId(response.data._id);
    try {
      await uploadToS3(file, response.data.signedRequest, setpercentage);

      const response2 = await ApiClient(
        "post",
        "/user/uploadMetadata",
        {
          recordIds: response.data._id,
          totalRecords: selectedStationIds.length * repeatCount,
        },
        {
          "Content-Type": "application/json",
          authorization: Cookies.get("auth"),
        }
      );

      if (response2.data.status === 200) {
        setSuccess(true);
        setuploadState(false);
        setLoaderType("CircularProgress");
        setpercentage(0);
      } else if (response2.data.status === 403) {
        setAlert(true);
        setSeverity("error");
        setAlertMessage("Session Expired Please Relogin ");
        setAlertTitle("Relogin");
        setConfirmAction("logout");
      } else if (response2.data.status === 422) {
        setuploadState(false);
        setAlert(true);
        setSeverity("error");
        setAlertMessage("Missing required param");
        setAlertTitle("Something Went Wrong");
        setConfirmAction("");
        setHrefLink("");
      } else {
        setuploadState(false);
        setAlert(true);
        setSeverity("error");
        setAlertMessage("Some Error Occured");
        setAlertTitle("Something Went Wrong");
        setConfirmAction("");
        setHrefLink("");
      }
    } catch (err) {
      //console.log("Error ", err);
    }
  };

  const goToXperiPayload = (e) => {
    e.preventDefault();
    window.location = "/user/xperi-payload";
  };
  const confirmAlert = () => {
    setAlert(false);
    setSeverity("default");
    setAlertTitle("");
    if (confirmAction == "load") {
      window.location.reload();
    } else if (confirmAction == "href") {
      window.location.href = hrefLink;
    } else if (confirmAction === "logout") {
      logout();
    }
  };
  return (
    <div>
      {alert && (
        <SweetAlert type={severity} title={alertTitle} onConfirm={confirmAlert}>
          {alertMessage}
        </SweetAlert>
      )}
      <Loader
        loading={loaderType === "CircularProgressbar" ? uploadState : redirect}
        loaderType={loaderType}
        percentage={percentage}
      ></Loader>
      <div className="container" style={{ marginTop: "100px" }}>
        <div
          className="row d-flex justify-content-center mt-100"
          style={{ marginTop: "25px" }}
        >
          <div className="col-md-10">
            <div className="card" style={{ padding: "10px" }}>
              <h4>Regions</h4>
              <RegionSelect
                selectOptions={selectOptions}
                setselectValuesList={setselectValuesList}
                isAdding={isAdding}
                handleClick={handleClick}
              />

              {/* <br />
            <br /> */}
              <div style={{ marginTop: "40px", marginBottom: "40px" }}>
                <DataTable
                  tableData={tableData}
                  tableColumns={tableColumns}
                  tableOptions={tableOptions}
                />
              </div>
              {/* <br />
            <br /> */}

              <div className="container">
                <div className="row">
                  {date !== null && (
                    <MessageSettings
                      setbroadcast={setbroadcast}
                      bitRateTransmitMessage={bitRateTransmitMessage}
                      setbitRateTransmitMessage={setbitRateTransmitMessage}
                      minimumBitRate={minimumBitRate}
                      date={date}
                      setDate={setDate}
                      utcdate={utcdate}
                      setutcDate={setutcDate}
                      timezone={timezone}
                      settimezone={settimezone}
                      setFile={setFile}
                      transferVia={transferVia}
                      settransferVia={settransferVia}
                      dateNow={dateNow}
                      setAlertMessage={setAlertMessage}
                      setAlert={setAlert}
                      setSeverity={setSeverity}
                      setAlertTitle={setAlertTitle}
                      setConfirmAction={setConfirmAction}
                      setHrefLink={setHrefLink}
                      setrepeatCount={setrepeatCount}
                      setrepeatInterval={setrepeatInterval}
                      showRepeat={showRepeat}
                      setshowRepeat={setshowRepeat}
                      setdoFraming={setdoFraming}
                    />
                  )}
                </div>
                <div className="row">
                  <center>
                    <div style={{ width: "100%", marginTop: "-3%" }}>
                      <Button
                        color="primary"
                        size="medium"
                        fullwidth="false"
                        onClick={() => submit()}
                        style={{
                          borderColor: "#4F3493",
                          backgroundColor: "#4F3493",
                        }}
                      >
                        Schedule Message <SendIcon />
                      </Button>
                    </div>
                  </center>
                </div>
              </div>
            </div>
            <br />
            <br />
            <br />
            <br />
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateMessageNew;
