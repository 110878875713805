import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import Box from "@mui/material/Box";
import React from 'react';
// import Typography from "@mui/material/Typography";
// import Slide from "@mui/material/Slide";

//import AlertTitle from "@material-ui/lab/AlertTitle";

export default function Loader(props) {
    const { loading, percentage, loaderType } = props
    return (
        <div>
            {loaderType === "CircularProgress" &&
                <Backdrop
                    sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loading}
                >
                    <CircularProgress color="inherit" />
                    {/* <Box>
                        <Slide direction="left" in={true} mountOnEnter unmountOnExit {...({ timeout: 2000 })}>
                            <Typography
                                variant="h3"
                                color="text.danger"
                            >{`Loading...`}</Typography>
                        </Slide>
                    </Box> */}
                </Backdrop>
            }
            {loaderType === "CircularProgressbar" &&
                <Backdrop
                    sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loading}
                >
                    <div
                        className="container d-flex flex-column min-vh-100"
                        style={{ width: "auto", height: 100 }}
                    >
                        <div className="row mb-3">
                            <CircularProgressbar
                                value={percentage}
                                text={
                                    percentage === 0
                                        ? "Starting...."
                                        : percentage === 100
                                            ? "Please wait..."
                                            : `${percentage}%`
                                }
                                styles={buildStyles({
                                    textSize: "14px",
                                })}
                            />
                        </div>
                    </div>
                </Backdrop>
            }
        </div>
    )
}