import { React, useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
  validateUsernameMain,
  validateEmailMain,
  validatePhonenumberMain,
  validatePasswordMain,
  validateCompanyMain,
} from "../validations/basicRegFormValidations";
function BaseConfirmRegisterForm(props) {
  const {
    userEmail,
    userCompany,
    userName,
    userPhone,
    confirmRegClicked,
    setConfirmClicked,
    setPassword,
    confirmRegFailed,
    setConfirm,
  } = props;
  const [showPassState, setShowPassState] = useState(false);
  const validatePassword = (value) => {
    return validatePasswordMain(value, setPassword);
  };
  const showPassword = () => {
    setShowPassState(!showPassState);
  };
  const signUp = () => {
    setConfirmClicked(1);
  };
  useEffect(() => {
    //console.log(props);
  }, [props])
  return (
    <div>
      <Formik
        initialValues={{
          email: "",
          password: "",
          phoneNumber: "",
          name: "",
          company: "",
        }}
        onSubmit={signUp}
      >
        {({ errors, touched }) => (
          <Form>
            <div className="row px-3">
              <label className="mb-1">
                <h6 className="mb-0 text-sm">Name</h6>
              </label>
              <Field
                className="mb-4"
                type="text"
                name="name"
                placeholder="Loading..."
                disabled="true"
                value={userName}
                style={{ backgroundColor: "#d1d1d1" }}
              />
            </div>
            <div className="row px-3">
              <label className="mb-1">
                <h6 className="mb-0 text-sm">Email Address</h6>
              </label>
              <Field
                className="mb-4"
                type="text"
                name="email"
                disabled="true"
                value={userEmail}
                placeholder="Enter email address"
                style={{ backgroundColor: "#d1d1d1" }}
              />
            </div>
            <div className="row px-3">
              <label className="mb-1">
                <h6 className="mb-0 text-sm">Phone Number</h6>
              </label>
              <Field
                className="mb-4"
                type="text"
                name="name"
                placeholder=""
                disabled="true"
                value={userPhone}
                style={{ backgroundColor: "#d1d1d1" }}
              />
            </div>
            <div className="row px-3">
              <label className="mb-1">
                <h6 className="mb-0 text-sm">Company</h6>
              </label>
              <Field
                className="mb-4"
                type="text"
                name="company"
                disabled="true"
                placeholder="Loading..."
                value={userCompany}
                style={{ backgroundColor: "#d1d1d1" }}
              ></Field>
            </div>
            <div className="row px-3">
              {" "}
              <label className="mb-1">
                <h6 className="mb-0 text-sm">Password</h6>
              </label>{" "}
              <div style={{ padding: 0, position: "relative" }}>
                <Field
                  type={showPassState ? "text" : "password"}
                  name="password"
                  id="password"
                  placeholder="Enter password"
                  validate={validatePassword}
                />
                <div
                  style={{
                    position: "absolute",
                    bottom: "17px",
                    right: "8px",
                  }}
                 >
                  {showPassState && (
                    <VisibilityIcon onClick={() => showPassword()} />
                  )}
                  {!showPassState && (
                    <VisibilityOffIcon onClick={() => showPassword()} />
                  )}
                </div>
              </div>
              {errors.password && touched.password && (
                <div className="invalid-feedback d-block">
                  {errors.password}
                </div>
              )}{" "}
            </div>
            <div className="mb-3"></div>
            <div className="row  mb-3 px-3">
              {(confirmRegClicked === 0 || confirmRegFailed) && (
                <button
                  type="submit"
                  className="btn btn-primary text-center"
                  onClick={() => setConfirm(true)}
                >
                  Confirm
                </button>
              )}
              {confirmRegClicked === 1 && !confirmRegFailed && (
                <button
                  type="submit"
                  className="btn btn-primary text-center"
                  disabled
                >
                  Please wait
                </button>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
export default BaseConfirmRegisterForm;
