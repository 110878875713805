import React from 'react'
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';

export default function displayAlert(props) {
    const { variant, severity, alertMessage, setAlert, setSeverity } = props
    return (
        <div>
            <Stack sx={{ width: '100%' }} spacing={2}>
                <Alert variant="filled" onClose={() => { setAlert(false); setSeverity("") }} severity={severity}>{alertMessage}</Alert>
            </Stack>
        </div>
    )
}
