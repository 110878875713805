import React from 'react'
import Alert from "@material-ui/lab/Alert";
//import AlertTitle from "@material-ui/lab/AlertTitle";

export default function Alerts(props) {
    const {message,alertType,setStatus} = props
    return (
        <div>
            <Alert
                  onClose={() => setStatus(0)}
                  variant="filled"
                  severity={alertType}
                >
                  {message}
            </Alert>
        </div>
    )
}
