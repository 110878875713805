import React from "react";
import { useState, useEffect } from "react";
import checkPageAccess from "../others/getPageAccess";
import SweetAlert from "react-bootstrap-sweetalert";
import ApiClient from "../../apis/apiClient";
import Cookies from "js-cookie";
import logout from "../validations/logoutUser";
import DataTable from "../DataTable/dataTable";
import Loader from "../loader/loader";
import moment, { max } from "moment";
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Label,
  Legend,
} from "recharts";
import { Typography } from "@mui/material";
import { Card } from "@mui/material";

export default function SystemDashboard() {
  const [stationStatusLog, setstationStatusLog] = useState();
  const [dashresp, setdashresp] = useState(null);
  const [tableData, settableData] = useState();
  const [loaderType, setLoaderType] = useState("CircularProgress");
  const [loading, setloading] = useState(true);
  const [cpu_usage_line_data, setcpu_usage_line_data] = useState(null);
  const [memory_usage_line_data, setmemory_usage_line_data] = useState(null);
  const [lastRecordTime, setlastRecordTime] = useState(null);
  const [recordsAvailableFrom, setrecordsAvailableFrom] = useState(null);
  const [maxUsageCPU, setmaxUsageCPU] = useState(null);
  const [minUsageCPU, setminUsageCPU] = useState(null);
  const [averageCPUUsage, setaverageCPUUsage] = useState(null);
  const [maxUsageMemory, setmaxUsageMemory] = useState(null);
  const [minUsageMemory, setminUsageMemory] = useState(null);
  const [averageMemoryUsage, setaverageMemoryUsage] = useState(null);

  const getDateFormatted = (data) => {
    let out =
      String(data.getMonth() + 1).padStart(2, "0") +
      "/" +
      String(data.getDate()).padStart(2, "0") +
      "/" +
      data.getFullYear();
    out +=
      " " +
      String(data.getHours()).padStart(2, "0") +
      ":" +
      String(data.getMinutes()).padStart(2, "0") +
      ":" +
      String(data.getSeconds()).padStart(2, "0");
    return out;
  };

  const tableColumns = [
    {
      name: "time",
      label: "Last Updated Time",
      options: {
        filter: false,
        //customBodyRender: value => moment(new Date(value)).format('ddd, DD MMM YYYY HH:MM:SS Z'),
        sortCompare: (order) => {
          return (obj1, obj2) => {
            let val1 = new Date(getDateFormatted(new Date(obj1.data)));
            let val2 = new Date(getDateFormatted(new Date(obj2.data)));
            return (val1 - val2) * (order === "asc" ? 1 : -1);
          };
        },
      },
    },
    {
      name: "call_sign",
      label: "Station Call Sign",
    },
    {
      name: "http_status",
      label: "Status (HTTP)",
    },
    {
      name: "ssh_status",
      label: "Status (SSH)",
    },
    {
      name: "msac2_status",
      label: "Status (MSAC2)",
    },
    {
      name: "gen4_status",
      label: "Status (Gen4)",
    },
  ];
  const tableOptions = {
    filter: true,
    filterType: "dropdown",
    responsive: "standard",
    tableBodyHeight: "600px",
    tableBodyMaxHeight: "",
    selectableRows: "none",
  };

  const setUsageData = (instance_data) => {
    let data_records = [];
    let data_records_memory = [];
    let max_cpu = -100;
    let min_cpu = 100;
    let max_memory = -100;
    let min_memory = 100;
    let cpu_usage;
    let mem_usage;
    let avg_cpu_usage = 0;
    let avg_memory_usage = 0;
    let lrtCPU = new Date(2000, 1, 1);
    let ratCPU = new Date(3000, 1, 1);
    for (let records of instance_data) {
      try {
        let d = new Date(records["createdAt"]);
        if (d > lrtCPU) lrtCPU = d;
        if (d < ratCPU) ratCPU = d;
        cpu_usage = parseFloat(
          100 - records["additionalInfo"][0]["cpu_usage_idle%"]
        ).toFixed(3);
        mem_usage = parseFloat(records["additionalInfo"][0]["memory_usage"]);

        if (max_cpu < cpu_usage) max_cpu = cpu_usage;
        if (min_cpu > cpu_usage) min_cpu = cpu_usage;
        if (max_memory < mem_usage) max_memory = mem_usage;
        if (min_memory > mem_usage) min_memory = mem_usage;

        data_records.push({
          DateHour: d.getUTCHours(),//d.toISOString().substring(5, 13),
          usage: cpu_usage,
          date: d.toGMTString()
        });
        
        data_records_memory.push({
          DateHour: d.getUTCHours(),//d.toISOString().substring(5, 13),
          usage: mem_usage,
          date: d.toGMTString()
        });

        avg_cpu_usage += parseFloat(
          100 - records["additionalInfo"][0]["cpu_usage_idle%"]
        );

        avg_memory_usage += parseFloat(records["additionalInfo"][0]["memory_usage"]);
      } catch (e) {}
    }

    setmaxUsageCPU(max_cpu);
    setminUsageCPU(min_cpu);
    setmaxUsageMemory(max_memory);
    setminUsageMemory(min_memory);
    setrecordsAvailableFrom(ratCPU);
    setlastRecordTime(lrtCPU);
    setaverageCPUUsage((avg_cpu_usage / instance_data.length).toFixed(3));
    setaverageMemoryUsage((avg_memory_usage / instance_data.length).toFixed(3));
    setcpu_usage_line_data(data_records);
    setmemory_usage_line_data(data_records_memory);
  };

  useEffect(async () => {
    let dashresptemp = await ApiClient("get", "/app/system-dashboard", "", {
      "Content-Type": "application/json",
      authorization: Cookies.get("auth"),
    });

    if (dashresptemp.data.status === 200) {
      setUsageData(dashresptemp.data.result["instance_health_check"]);
      setdashresp(dashresptemp.data.result["station_status_update"]);
    } else {
      logout();
    }
  }, []);

  useEffect(() => {
    if (dashresp !== null) {
      //console.log('x',dashresp);
      //let stationStatus = dashresp?.filter((v) => {return v.logFor === "Station Status Update"});
      setstationStatusLog(dashresp);
    }
  }, [dashresp]);

  useEffect(() => {
    if (stationStatusLog) {
      let res = [];
      for (let data of stationStatusLog) {
        for (let info of data.additionalInfo) {
          let x = {};
          x.time = new Date(data.updatedAt).toUTCString();
          x.call_sign = info.call_sign;
          x.http_status = info.http_status + "";
          x.ssh_status = info.ssh_status + "";
          x.gen4_status = info.gen4_status + "";
          x.msac2_status = info.msac2_status + "";
          res.push(x);
        }
      }
      settableData(res);
      setloading(false);
    }
  }, [stationStatusLog]);


  const CustomTooltipCPUUsage = ({ active, payload, label }) => {  
    if (active) {
      return (
        <div>
         <Card>
          <p>Time: {payload[0]["payload"]["date"]} </p>
          <p>Hour : {payload[0]["payload"]["DateHour"]}</p>
          <p>Usage % :{payload[0]["payload"]["usage"]} </p>
          </Card>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="mb-1">
      <Loader loading={loading} loaderType={loaderType} percentage={0}></Loader>
      <div style={{
            marginTop: "125px",
            marginRight: "10px",
            marginEnd: "0px",
            marginBottom: "5px",
            background: "white",
          }}>

          </div>
      {cpu_usage_line_data && (
        <div
          style={{
            marginTop: "125px",
            marginRight: "10px",
            marginLeft: "10px",
            marginBottom: "25px",
            background: "white",
          }}
        >
          <div className="p-1">
            <h5>CPU Usage Statistics</h5>
          </div>
          <div className="p-5 d-flex">
            <LineChart
              className="mr-auto"
              width={1240}
              height={400}
              data={cpu_usage_line_data}
              {...{
                overflow: "visible",
              }}
            >
              <Line type="monotone" dataKey="usage" stroke="#8884d8" dot={false} />
              <CartesianGrid stroke="#ccc" />
              <XAxis dataKey="DateHour" height={40}>
                <Label value="Date Hour" offset={0} position="insideBottom" />
              </XAxis>
              <YAxis
                domain={[minUsageCPU*0.8, maxUsageCPU*1.2]}
                label={{ value: "Usage %", angle: -90, position: "insideLeft" }}
              ></YAxis>
              <Legend verticalAlign="top" height={36} />
              <Tooltip content={<CustomTooltipCPUUsage/>}/>
            </LineChart>
            <div>
              <p className="blockquote p-2">
                Maximum CPU Usage: {maxUsageCPU} %
              </p>
              <p className="blockquote p-2">
                Minimum CPU Usage: {minUsageCPU} %
              </p>
              <p className="blockquote p-2">
                Average CPU Usage: {averageCPUUsage} %
              </p>
              <p className="blockquote p-2">
                Last Record Updated at: {lastRecordTime?.toGMTString()}
              </p>
              <p className="blockquote p-2">
                Records available from: {recordsAvailableFrom?.toGMTString()}
              </p>
            </div>
          </div>
        </div>
      )}

{memory_usage_line_data && (
        <div
          style={{
            marginTop: "25px",
            marginRight: "10px",
            marginLeft: "10px",
            marginBottom: "25px",
            background: "white",
          }}
        >
          <div className="p-1">
            <h5>Memory Usage Statistics</h5>
          </div>
          <div className="p-5 d-flex">
            <LineChart
              className="mr-auto"
              width={1240}
              height={400}
              data={memory_usage_line_data}
              {...{
                overflow: "visible",
              }}
            >
              <Line type="monotone" dataKey="usage" stroke="#8884d8" dot={false} />
              <CartesianGrid stroke="#ccc" />
              <XAxis dataKey="DateHour" height={40}>
                <Label value="Date Hour" offset={0} position="insideBottom" />
              </XAxis>
              <YAxis
                domain={[minUsageMemory*0.8, maxUsageMemory*1.2]}
                label={{ value: "Usage %", angle: -90, position: "insideLeft" }}
              ></YAxis>
              <Legend verticalAlign="top" height={36} />
              <Tooltip content={<CustomTooltipCPUUsage/>}/>
            </LineChart>
            <div>
              <p className="blockquote p-2">
                Maximum Memory Usage: {maxUsageMemory} %
              </p>
              <p className="blockquote p-2">
                Minimum Memory Usage: {minUsageMemory} %
              </p>
              <p className="blockquote p-2">
                Average Memory Usage: {averageMemoryUsage} %
              </p>
              <p className="blockquote p-2">
                Last Record Updated at: {lastRecordTime?.toGMTString()}
              </p>
              <p className="blockquote p-2">
                Records available from: {recordsAvailableFrom?.toGMTString()}
              </p>
            </div>
          </div>
        </div>
      )}

      <div
        className="row d-flex justify-content-center"
        style={{
          marginTop: "125px",
          marginRight: "10px",
          marginLeft: "10px",
          marginBottom: "25px",
        }}
      >
        <h3>Station Status Data</h3>
        <DataTable
          tableData={tableData}
          tableColumns={tableColumns}
          tableOptions={tableOptions}
        />
      </div>
      <br />
      <br />
      <br />
      <br />
    </div>
  );
}
