import React from "react";
import { useState, useEffect } from "react";
import Cookies from "js-cookie";
import encryptHash from "../../validations/general";
import { InputProps } from "@material-ui/core";
import {
  Table,
  TableBody,
  TableContainer,
  TableRow,
  TableCell,
  Paper,
  FormControlLabel,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  Input,
  FormHelperText,
} from "@material-ui/core";
function MessageSettings(props) {
  const {
    bitRateTransmitMessage,
    setbitRateTransmitMessage,
    setbroadcast,
    date,
    setDate,
    utcdate,
    setFile,
    minimumBitRate,
    setutcDate,
    timezone,
    settimezone,
    transferVia,
    settransferVia,
    dateNow,
    setAlertMessage,
    setAlert,
    setSeverity,
    setAlertTitle,
    setConfirmAction,
    setHrefLink,
    setrepeatCount,
    setrepeatInterval,
    showRepeat,
    setshowRepeat,
    setdoFraming,
  } = props;
  const [yourtimeZone, setyourTimeZone] = useState(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );
  const hours = {
    "America/Toronto": 5,
    "America/Chicago": 6,
    "America/Denver": 7,
    "America/Los_Angeles": 8,
    GMT: 0,
  };

  const access_levels = Cookies.get("t") ? Cookies.get("t").split(",") : [];

  const [changed, setchanged] = useState(false);
  const [showError, setshowError] = useState(false);
  const [dateTimeError, setdateTimeError] = useState(false);
  const [fileError, setfileError] = useState(false);
  const [showBI, setshowBI] = useState(false);
  const formatDate = (inp) => {
    return ("0" + inp).slice(-2);
  };
  const getUtcDate = () => {
    return (
      utcdate.getFullYear() +
      "-" +
      formatDate(utcdate.getMonth() + 1) +
      "-" +
      formatDate(utcdate.getDate()) +
      " " +
      utcdate.toLocaleString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      })
    );
  };
  useEffect(() => {
    const tz = document.getElementsByName("timezone")[0].value;
    settimezone(tz);
    const d = document.getElementById("datetime-local").value;
    //console.log("Use Effect", tz, d);
    let x = new Date(d);
    //x.setHours(x.getHours() - parseInt(tz));
    try {
      //setutcDate(x.toISOString());
      //setDate(dateNow(x));
    } catch (err) {}
  }, [changed]);

  const handleChange = async (e) => {
    if (e.target.type === "file") {
      console.log("Inside", e.target.files[0].type);
      let types = ["application/octet-stream", "text/plain"];
      if (
        !types.includes(e.target.files[0].type) &&
        !(
          e.target.files[0].name.endsWith(".txt") ||
          e.target.files[0].name.endsWith(".bin")
        )
      ) {
        //alert("Only Binary and text files allowed to upload");
        setAlert(true);
        setSeverity("error");
        setAlertMessage("Only Binary and text files allowed to upload");
        setAlertTitle("Something Went Wrong");
        setConfirmAction("");
        setHrefLink("");
        e.target.value = "";
        setFile(null);
        return;
      }
      if (!e.target.files[0]) {
        setfileError(true);
        setFile(null);
      } else {
        setfileError(false);
      }
      let fSize = 0;
      try {
        fSize = e.target.files[0].size / 1024;
      } catch (err) {
        console.log("File Size error ", err.message);
        setFile(null);
      }
      if (fSize > 1000000) {
        setbitRateTransmitMessage(
          "File should be less than or equal (1 GB) in Size"
        );
        setFile(null);
        return;
      } else if (fSize === 0) {
        setbitRateTransmitMessage("File Size should not be Zero");
        setFile(null);
        return;
      } else {
        console.log("Setting new file");
        setbitRateTransmitMessage("");
        setFile([...e.target.files]);
      }
      if (minimumBitRate !== -1) {
        let time = fSize / minimumBitRate;
        setbitRateTransmitMessage(
          "Transmission time from station would take " + time + " seconds"
        );
      }
    } else if (e.target.type === "datetime-local") {
      var input_dt = e.target.value;
      input_dt = new Date(input_dt);
      var today = new Date(
        new Date().toLocaleString("en-US", { timeZone: timezone })
      );
      today = new Date(today.getTime() + 1 * 60000);
      // if (input_dt < today) {
      //   setdateTimeError(true);
      //   console.log(
      //     "can't set that time ",
      //     input_dt.toUTCString(),
      //     today.toUTCString()
      //   );
      //   setAlert(true)
      //   setSeverity("error")
      //   setAlertMessage("Please enter future date");
      //   setAlertTitle("Something Went Wrong")
      //   setConfirmAction("")
      //   setHrefLink("")

      //} else {
      if (e.target.id === "datetime-local") {
        //console.log("date changed",e.target.value);
        setDate(e.target.value);
        setutcDate(
          new Date(input_dt.setHours(input_dt.getHours() + hours[timezone]))
        );
        setdateTimeError(false);
      }
      // }
    } else if (e.target.name === "timezone") {
      setchanged(!changed);
    } else if (e.target.name === "checkedB") {
      setbroadcast(e.target.checked);
      setshowBI(e.target.checked);
    } else if (e.target.name === "repeatM") {
      setshowRepeat(e.target.checked);
      if (!e.target.checked) {
        setrepeatCount(1);
        setrepeatInterval(0);
      }
    }
  };

  const setTransfer = (e, val) => {
    settransferVia(val);
  };

  return (
    <div>
      <div className="container shadow-lg p-3 mb-5 bg-white rounded">
        <div className="row">
          <div className="col-md-6" style={{ marginBottom: "15px" }}>
            <h5>Schedule Transmission</h5>
          </div>
          {access_levels.indexOf(encryptHash("777")) !== -1 && (
            <div
              className="col-md-6 d-flex justify-content-end"
              style={{ marginBottom: "15px", align: "right" }}
            >
              <FormControlLabel
                control={
                  <Checkbox onChange={(e) => setdoFraming(e.target.checked)} />
                }
                label="Use Message Framing"
              />
            </div>
          )}
        </div>
        <div className="row">
          <div className="col-md-6">
            <TableContainer
              component={Paper}
              style={{
                minHeight: "250px",
                borderTop: "1px solid #0000000d",
              }}
            >
              <Table
                size="small"
                aria-label="a dense table"
                style={{ minHeight: "250px" }}
              >
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={2}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="checkedB"
                            color="primary"
                            onChange={handleChange}
                          />
                        }
                        label="Broadcast Immediately"
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow
                    style={
                      showBI ? { pointerEvents: "none", opacity: "0.4" } : {}
                    }
                  >
                    <TableCell style={{ fontSize: 16 }}>Timezone</TableCell>
                    <TableCell>
                      <InputLabel id="tmout">Timezone</InputLabel>
                      <Select
                        labelId="timezone"
                        id="timezone"
                        name="timezone"
                        label="time-zone"
                        fullwidth="true"
                        labelwidth="100"
                        onChange={handleChange}
                        defaultValue="America/Toronto"
                        sx={{ minWidth: 130 }}
                      >
                        <MenuItem value={"America/Toronto"}>
                          Eastern Time (ET)
                        </MenuItem>
                        <MenuItem value={"America/Chicago"}>
                          Central Time (CT)
                        </MenuItem>
                        <MenuItem value={"America/Denver"}>
                          Mountain Time (MT)
                        </MenuItem>
                        <MenuItem value={"America/Los_Angeles"}>
                          Pacific Time (PT)
                        </MenuItem>
                        <MenuItem value={"GMT"}>
                          Greenwich Mean Time (GMT)
                        </MenuItem>
                      </Select>
                      {/*yourtimeZone*/}
                    </TableCell>
                  </TableRow>
                  <TableRow
                    style={
                      showBI ? { pointerEvents: "none", opacity: "0.4" } : {}
                    }
                  >
                    <TableCell style={{ fontSize: 16 }}>
                      Date And Time <br />
                      <p style={{ marginTop: "28px" }}>Broadcast Time in GMT</p>
                    </TableCell>
                    <TableCell>
                      <TextField
                        error={dateTimeError && showError ? true : false}
                        id="datetime-local"
                        label="Date Time"
                        type="datetime-local"
                        fullwidth="true"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        value={date}
                        helperText={
                          dateTimeError && showError
                            ? "Please Enter correct Date"
                            : ""
                        }
                        onChange={handleChange}
                      />
                      <p
                        style={{
                          marginTop: "20px",
                          fontSize: "16px",
                        }}
                      >
                        {timezone ? getUtcDate() : ""}
                      </p>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <div className="col-md-6">
            <TableContainer
              component={Paper}
              style={{
                minHeight: "250px",
                borderTop: "1px solid #0000000d",
              }}
            >
              <Table
                size="small"
                aria-label="a dense table"
                style={{ minHeight: "250px" }}
              >
                <TableBody>
                  {Cookies.get("t").split(",").indexOf(encryptHash(777)) !==
                    -1 && (
                    <></>
                    //   <TableRow>
                    //     <TableCell style={{ fontSize: 16 }} colSpan={2} rowSpan={1}>
                    //       <InputLabel style={{ fontSize: 16 }}>
                    //         Transfer Via
                    //       </InputLabel>
                    //       <RadioGroup
                    //         row
                    //         aria-label="position"
                    //         color="secondary  "
                    //         name="transferVia"
                    //         fullwidth="true"
                    //         onChange={setTransfer}
                    //         defaultValue={transferVia}
                    //       >
                    //         <FormControlLabel
                    //           value="http"
                    //           control={<Radio color="primary" />}
                    //           label="HTTP"
                    //           labelPlacement="end"
                    //         />
                    //         {/* <FormControlLabel
                    //           value="ssh"
                    //           control={<Radio color="primary" />}
                    //           label="SSH"
                    //           labelPlacement="end"
                    // /> */}
                    //       </RadioGroup>
                    //     </TableCell>
                    //   </TableRow>
                  )}
                  <TableRow>
                    <TableCell colSpan={2}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="repeatM"
                            color="primary"
                            onChange={handleChange}
                          />
                        }
                        label="Repeat Sending Messages"
                        fullWidth={true}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow
                    style={
                      !showRepeat
                        ? { pointerEvents: "none", opacity: "0.4" }
                        : {}
                    }
                  >
                    <TableCell>
                      Repeat Interval (in Minutes) <br />
                      <Select
                        name="repeatInterval"
                        defaultValue={0}
                        fullWidth={true}
                        onChange={(e) => setrepeatInterval(e.target.value)}
                      >
                      <MenuItem value={0}>Choose Time</MenuItem>
                        <MenuItem value={5}>5</MenuItem>
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={15}>15</MenuItem>
                        <MenuItem value={30}>30</MenuItem>
                        <MenuItem value={30}>60</MenuItem>
                      </Select>
                    </TableCell>
                    <TableCell>
                      Repeat Count
                      <TextField
                        name="repeatCount"
                        type="number"
                        defaultValue={1}
                        fullWidth={true}
                        InputProps={{ inputProps: { min: 1, max: 20 } }}
                        onChange={(e) => setrepeatCount(e.target.value)}
                      />
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell rowSpan={1} colSpan={2}>
                      <InputLabel style={{ fontSize: 16 }}>
                        Upload Payload
                      </InputLabel>
                      <input
                        type="file"
                        id="payload_file_id"
                        onChange={handleChange}
                        accept=".txt, text/plain, .bin"
                      />
                      <div style={{ color: "#f44336" }}>
                        {fileError && showError ? "please enter Image" : ""}
                      </div>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
        {/* {bitRateTransmitMessage !== "" && (
          <p style={{ color: "red", marginLeft: "51%" }}>
            {bitRateTransmitMessage}{" "}
          </p>
        )} */}
      </div>
    </div>
  );
}

export default MessageSettings;
