const API_ENDPOINT_URL =
  process.env.REACT_APP_ENV === "dev"
    ? process.env.REACT_APP_API_URL_DEV
    : process.env.REACT_APP_ENV === "prod"
    ? process.env.REACT_APP_API_URL_PROD
    : process.env.REACT_APP_ENV === "test"
    ? process.env.REACT_APP_API_URL_TEST
    : process.env.REACT_APP_ENV === "test2"
    ? process.env.REACT_APP_API_URL_TEST2
    : "http://localhost:9081";

const APP_URL =
  process.env.REACT_APP_ENV === "dev"
    ? process.env.REACT_APP_URL_DEV
    : process.env.REACT_APP_ENV === "prod"
    ? process.env.REACT_APP_URL_PROD
    : process.env.REACT_APP_ENV === "test" 
    ? process.env.REACT_APP_URL_TEST
    : process.env.REACT_APP_ENV === "test2" 
    ? process.env.REACT_APP_URL_TEST2
    : "http://localhost:3000";
module.exports = {
  API_ENDPOINT_URL,
  APP_URL
};
