import { React, useState } from "react";
import { Formik, Form, Field } from "formik";
import Auth from "@aws-amplify/auth";

function VerifyEmail(props) {
  const { email, password, setGetMFA, setAlertType, setMessage, setMFACode } =
    props;
  const [authenticationCode, setAuthenticationCode] = useState(null);
  const [authClicked, setAuthClicked] = useState(false);
  let env = process.env.REACT_APP_ENV;
  const mfa_tag = env === "dev" || env === "test" ? env+"_" : "";
  const confirmSignUp = async () => {
    setAuthClicked(true);
    try {
      await Auth.confirmSignUp(email, authenticationCode).then((user) => {
        //console.log(email,password);
        //console.log(user);
        try {
          Auth.signIn(email, password)
            .then((user) => {
              Auth.setupTOTP(user)
                .then((code) => {
                  //console.log("Mfa code success");
                  setMFACode(
                    "otpauth://totp/AWSCognito:" +
                      user.attributes.name +
                      "?secret=" +
                      code +
                      "&issuer="+mfa_tag+"xperi.radiodatacast.com"
                  );
                  setGetMFA(true);
                  setAlertType("error");
                  setMessage(
                    "Multi-factor verification is required for login."
                  );
                })
                .catch((err) => {
                  console.log(err);
                  setAlertType("error");
                  setMessage(err.message);
                  setAuthClicked(false);
                });
            })
            .catch((err) => {
              setAlertType("error");
              setMessage(err.message);
              setAuthClicked(false);
            });
        } catch (err) {
          //console.log(err);
          setAlertType("error");
          setMessage(err.message);
          setAuthClicked(false);
        }
      });
    } catch (error) {
      setAlertType("error");
      setMessage(error.message);
      setAuthClicked(false);
    }
  };
  const validateAuth = (value) => {
    let error;
    if (!value || value.length < 4) {
      error = "Please enter a valid auth code";
    } else {
      setAuthenticationCode(value);
    }
    return error;
  };
  return (
    <div>
      <Formik
        initialValues={{
          name: "",
          authenticationCode: "",
        }}
        onSubmit={confirmSignUp}
      >
        {({ errors, touched }) => (
          <Form>
            <div className="row px-3">
              <label className="mb-1">
                <h6 className="mb-0 text-sm">Email Verification Code</h6>
              </label>
              <Field
                className="mb-4"
                type="text"
                name="authenticationCode"
                autocomplete="off"
                placeholder="Enter a valid auth code from email"
                validate={validateAuth}
              />
              {errors.auth && touched.auth && (
                <div className="invalid-feedback d-block">{errors.auth}</div>
              )}
            </div>
            <div className="row px-3 mb-4"> </div>
            <div className="row  mb-3 px-3">
              {" "}
              <button
                type="submit"
                className="btn btn-primary text-center"
                disabled={authClicked}
              >
                {authClicked ? "Authenticating" : "Authenticate"}
              </button>{" "}
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default VerifyEmail;
